import { useState } from 'react'
import Button from 'src/components/Button'
import { ConditionalModal } from 'src/components/Modal'

type ErrorInfo = {
  title: string
  detail: string
  subtitle: string
  sentryEventId: string
}

export const useErrorModal = () => {
  const [error, setError] = useState<ErrorInfo | null>(null)

  const showError = (error: ErrorInfo) => {
    setError(error)
  }

  const clearError = () => {
    setError(null)
  }

  const errorModal = () => {
    if (!error) return null
    return <ConditionalModal
      overlayClassName='bg-black/80'
      outerClassName='relative z-50'
      className='border-t-4 border-red-600 p-6'
      onClose={() => clearError()}>
      <div className=''>
        <div className='text-lg'>{error.title}</div>
        <div className='text-sm text-gray-500'>{error.subtitle}</div>
        <div className='text-sm py-4 flex flex-col gap-2'>
          <div className='italic'>{error.detail}</div>
          <div>Contact support with error ID: {error.sentryEventId} to prioritize this issue.</div>
        </div>
        <div className='flex justify-end'>
          <Button variant='primary' onClick={() => clearError()}>Done</Button>
        </div>
      </div>
    </ConditionalModal>
  }

  return {
    errorModal,
    showError,
    clearError
  }
}
