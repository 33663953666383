// Takes the rightmost number or character from a . seperated
// string and increments it. For characters, only supports single
// chars less than "z", else it throws.
const calculateNextVersion = (version: string) => {
  const parts = version.split('.')
  const lastVersion = parts[parts.length - 1]
  const higherVersion = parts.slice(0, -1)
  if (isNaN(Number(lastVersion))) {
    if (lastVersion.length > 1 || lastVersion.toLowerCase() === 'z') {
      throw new Error('Cannot calculate next version for ' + version)
    }

    const nextLetter = String.fromCharCode(lastVersion.charCodeAt(0) + 1)
    return ([...higherVersion, nextLetter]).join('.')
  }

  return ([...higherVersion, Number(lastVersion) + 1]).join('.')
}

export default calculateNextVersion
