import { Uppy, UppyFile, UploadResult } from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import { useAuth } from 'src/auth'
import { useParams } from "@redwoodjs/router"
import { useRef, useMemo, useReducer, useState, ChangeEvent } from "react"
import { groupBy } from "src/lib/util"
import { reportGenericError } from "../reportError"

/**
 * This generator transforms a deep object into URL-encodable pairs
 * to work with `URLSearchParams` on the client and `body-parser` on the server.
 */
function* serializeSubPart(key: string | null, value: any): any {
  if (typeof value !== 'object') {
    yield [key, value]
    return
  }
  if (Array.isArray(value)) {
    for (const val of value) {
      yield* serializeSubPart(`${key}[]`, val)
    }
    return
  }
  for (const [subkey, val] of Object.entries(value)) {
    yield* serializeSubPart(key ? `${key}[${subkey}]` : subkey, val)
  }
}
function serialize(data: any) {
  // If you want to avoid preflight requests, use URL-encoded syntax:
  return new URLSearchParams(serializeSubPart(null, data))
  // If you don't care about additional preflight requests, you can also use:
  // return JSON.stringify(data)
  // You'd also have to add `Content-Type` header with value `application/json`.
}

const MiB = 0x10_00_00

export type FileRef = {
  id: number,
  path: string
}
export type ConfirmFail = {
  fileId: string,
  id: number,
}

export type OnUploadsComplete = (
  fileRefs: FileRef[] | undefined,
  failedUploads: UploadResult['failed'] | undefined,
  failedConfirms: ConfirmFail[] | undefined) => void
const createUppy = (org: string, getToken: ReturnType<typeof useAuth>['getToken'], onComplete: OnUploadsComplete) => {
  const getHeaders = async () => ({
    accept: 'application/json',
    authorization: `Bearer ${await getToken()}`,
    ['bomello-org-id']: org
  })
  const $uppy = new Uppy({
    autoProceed: true,
    id: 'uppyloader',
    allowMultipleUploads: false,
    //allow the same file multiple times
    onBeforeFileAdded: () => true
  })
  .use(AwsS3, {
    id: 'myAWSPlugin',

    // Files that are more than 100MiB should be uploaded in multiple parts.
    //shouldUseMultipart: (file) => file.size > 100 * MiB,
    //shouldUseMultipart: () => true,
    shouldUseMultipart: () => false,

    //@ts-expect-error unexpected param "options"
    async getUploadParameters(file: UppyFile, options) {
      const response = await fetch('/api/uppy-s3-singlepart', {
        method: 'POST',
        headers: await getHeaders(),
        body: serialize({
          filename: file.name,
          contentType: file.type,
          size: file.size
        }),
      })

      const data = await response.json()

      return {
        method: 'PUT',
        url: data.url,
        fields: {}, // For presigned PUT uploads, this should be left empty.
        // Provide content type header required by S3
        headers: {
          'Content-Type': file.type,
        },
      }
    },

    //Multipart uploads

    //@ts-expect-error signal not expected in signature
    async createMultipartUpload(file, signal) {
      signal?.throwIfAborted()

      const metadata: { [key: string]: string } = {}

      Object.keys(file.meta ?? {}).forEach((key) => {
        if (file.meta[key] != null) {
          metadata[key] = file.meta[key].toString()
        }
      })

      const response = await fetch('/api/uppy-s3-create-multipart-upload', {
        method: 'POST',
        // Send and receive JSON.
        headers: await getHeaders(),
        body: serialize({
          filename: file.name,
          contentType: file.type,
        }),
        signal,
      })

      if (!response.ok)
        throw new Error('Unsuccessful request', { cause: response })

      const data = await response.json()

      return data
    },

    //@ts-expect-error incorrect signature
    async abortMultipartUpload(file, { key, uploadId }, signal) {
      const filename = encodeURIComponent(key)
      const uploadIdEnc = encodeURIComponent(uploadId)
      const response = await fetch(
        `/api/uppy-s3-abort-multipart-upload?uploadIdEnc=${uploadIdEnc}&key=${filename}`,
        {
          method: 'DELETE',
          signal,
        },
      )

      if (!response.ok)
        throw new Error('Unsuccessful request', { cause: response })
    },

    async signPart(file, options) {
      const { uploadId, key, partNumber, signal } = options

      signal?.throwIfAborted()

      if (uploadId == null || key == null || partNumber == null) {
        throw new Error(
          'Cannot sign without a key, an uploadId, and a partNumber',
        )
      }

      const filename = encodeURIComponent(key)
      const response = await fetch(
        `/api/uppy-s3-upload-part?uploadId=${uploadId}&partNumber=${partNumber}&key=${filename}`,
        { signal },
      )

      if (!response.ok)
        throw new Error('Unsuccessful request', { cause: response })

      const data = await response.json()

      return data
    },

    //@ts-expect-error incorrect signature
    async listParts(file, { key, uploadId }, signal) {
      signal?.throwIfAborted()

      const filename = encodeURIComponent(key)
      const response = await fetch(
        `/api/uppy-s3-list-parts?uploadId=${uploadId}&key=${filename}`,
        { signal },
      )

      if (!response.ok)
        throw new Error('Unsuccessful request', { cause: response })

      const data = await response.json()

      return data
    },

    //@ts-expect-error incorrect signature
    async completeMultipartUpload(file, { key, uploadId, parts }, signal) {
      signal?.throwIfAborted()

      const filename = encodeURIComponent(key)
      const uploadIdEnc = encodeURIComponent(uploadId)
      const response = await fetch(
        `/api/uppy-s3-complete-multipart-upload?uploadIdEnc=${uploadIdEnc}&key=${filename}`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: serialize({ parts }),
          signal,
        },
      )

      if (!response.ok)
        throw new Error('Unsuccessful request', { cause: response })

      const data = await response.json()

      return data
    },

  })

  $uppy.on('complete', async (result) => {
    const fileRefsOps = await Promise.all(result.successful.map(async r => {
      const fileId = new URL(r.uploadURL).pathname.split('/')[2]
      try {
        const response = await fetch('/api/confirm-file-upload', {
          method: 'POST',
          headers: await getHeaders(),
          body: serialize({ fileId }),
        })
        const { id, path } = await response.json();
        return { type: 'success', id, path } as { type: 'success', id: number , path: string }
      }
      catch (e) {
        return {
          type: 'fail',
          fileId,
          id: parseInt(fileId)
        } as { type: 'fail', fileId: string, id: number }
      }
    }))

    const { success, fail } = groupBy(fileRefsOps, op  => op.type);

    onComplete(success, result.failed.length ? result.failed : undefined, fail)
  })

  /*
  $uppy.on('upload-progress', (file, progress) => {
    console.log("progress", progress.bytesTotal / progress.bytesUploaded);
    console.log(file.id, progress.bytesUploaded, progress.bytesTotal);
  })
   */

  return $uppy;
}

type DropState = {
  dropDepth: number,
  inDropZone: boolean,
}
type DropAction = {
  type: 'MODIFY_DROP_DEPTH'
  amount: number
} | {
  type: 'SET_IN_DROP_ZONE'
  inDropZone: boolean
}

const dropReducer = (state: DropState, action: DropAction) => {
  switch (action.type) {
    case 'MODIFY_DROP_DEPTH':
      return { ...state, dropDepth: state.dropDepth + action.amount }
    case 'SET_IN_DROP_ZONE':
      return { ...state, inDropZone: action.inDropZone };
    default:
      return state;
  }
};

let globalUpdating = false;
//this isn't named very well because the caller has to enforce this
//this is meant to only allow one upload to happen at a time, to prevent the callbacks from
//stepping on each other
const mutex = (uppy: Uppy) => {
  if (globalUpdating) return false
  const remove = () => {
    uppy.off('complete', remove);
    uppy.off('error', remove);
    globalUpdating = false;
  }
  uppy.on('complete', remove)
  uppy.on('error', remove)
  return true
}

function isDirectory(filename: string)
{
    return !/(\.[a-z0-9]{2,5})$/i.test(filename)
}

type OnBeforeFilesAdded = undefined | ((files: File[]) => Promise<boolean>)

//this is a rather brittle abstraction because it loosely ties together many actions
export default function useFileUploader(onBeforeFilesAdded: OnBeforeFilesAdded, onUploadsComplete: OnUploadsComplete) {
  const { orgId } = useParams()
  const { getToken } = useAuth()
  const [uploading, setUploading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)
  const orgedUppy = useMemo(() => {
    const newUppy = createUppy(orgId, getToken, onUploadsComplete);

    newUppy.on('complete', () => {
      if (inputRef.current) {
        //@ts-expect-error ackchyually can assign null
        inputRef.current.value = null;
      }
      setUploading(false);
    });
    newUppy.on('error', (e) => {
      if (inputRef.current) {
        //@ts-expect-error ackchyually can assign null
        inputRef.current.value = null;
      }
      reportGenericError(e);
      setError(e);
      setUploading(false);
    });

    return newUppy;
  }, [orgId, onUploadsComplete])

  const addFiles = async (files: File[], source: string) => {
    if (onBeforeFilesAdded) {
      const confirmed = await onBeforeFilesAdded(files);
      if (!confirmed) {
        return
      }
    }

    setUploading(true)
    try {
      orgedUppy.addFiles(files.map(file => ({
        source,
        name: file.name,
        type: file.type,
        data: file,
      })))
    } catch (err) {
      if (err instanceof Error) {
        reportGenericError(err);
        setError(err)
      }
      else {
        const e = new Error("Unknown error");
        reportGenericError(e);
        setError(e)
      }
      setUploading(false)
    }
  }

  const [dragState, dispatch] = useReducer(
    dropReducer, { dropDepth: 0, inDropZone: false }
  )

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'MODIFY_DROP_DEPTH', amount: -1 });
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'MODIFY_DROP_DEPTH', amount: 1 });
    if (dragState.dropDepth > 0) return
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>)  => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });

    let fileList = [...e.dataTransfer.files];

    if (fileList && fileList.length > 0) {
      if (fileList.some(f => isDirectory(f.name))) {
        setError(new Error("Cannot upload directory"))
        return
      }

      if (!mutex(orgedUppy)) {
        return
      }

      addFiles(fileList, 'drag drop');
    }
  };

  const dragBindings = {
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
    onDragOver: handleDragOver,
    onDrop: handleDrop
  } as const

  const onFilesChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    if (!mutex(orgedUppy)) {
      event.target.files = null;
      return;
    }

    addFiles(Array.from(event.target.files), 'file input')
  }

  const inputBindings = {
    ref: inputRef,
    type: 'file',
    onChange: onFilesChanged,
    multiple: false
  } as const

  return {
    uploading,
    inputBindings,
    dragBindings,
    dragState,
    uppy: orgedUppy,
    error,
    clearError: () => setError(undefined)
  }
}
