import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'

import type {
  FindPartsSearchQuery,
  FindPartsSearchQueryVariables,
  PartCategory
} from 'types/graphql'

import Input from '../Input'
import PartSummary from '../PartSummary'
import ViewModeSwitch from 'src/components/ViewModeSwitch'
import UserSelectCell, { SelectedUser } from 'src/components/UserSelectCell'
import ChangeOrderSelectCell, { SelectedChangeOrder } from 'src/components/ChangeOrderSelectCell'
import PartCategorySelectCell, { SelectedCategory } from 'src/components/PartCategorySelectCell'

export const QUERY = gql`
  query FindPartsSearchQuery {
    users {
      id
      name
      email
    }
    changeOrders {
      _id
      number
      name
    }
    partProtos {
      inChangeOrders {
        number
        name
        state
      }
      category {
        id
        name
      }
      partNumber
      protoUpdatedBy {
        name
      }
      currentVersion {
        name
        summary
        version
        lifeCycle
      }
      owner {
        id
        name
      }
    }
  }
`

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'

export const Loading = () => {
  return <LoadingSpinner className='flex p-10 items-center justify-center' />
}

import GenericFailure from '../Failure/Failure'
import { useAppContext } from 'src/lib/appContext';
export const Failure = GenericFailure

export const Success = ({
  partProtos,
  users,
  changeOrders,
}: CellSuccessProps<FindPartsSearchQuery, FindPartsSearchQueryVariables>) => {
  const appContext = useAppContext()
  if (!appContext) return <LoadingSpinner className='flex p-10 items-center justify-center' />
  return <PartSearch partProtos={partProtos} users={users} categories={appContext.partCategories} changeOrders={changeOrders} />
}


const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.3,
  // distance: 100,
  // useExtendedSearch: false,
  ignoreLocation: true,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: [
    "partNumber",
    "currentVersion.name",
    "currentVersion.summary",
  ],
};

type FilterPillProps = {
  label: string
  value: string
  onRemove: () => void
}
const FilterPill: React.FC<FilterPillProps> = ({ value, label, onRemove }) => {
  return <div className='rounded-md inline-flex border border-gray-200 text-sm items-center mr-4'>
    <div className='bg-gray-100 px-2 py-1'>{label}</div>
    <div className='pl-3 pr-1 py-1'>{value}</div>
    <div className='cursor-pointer pr-1' onClick={onRemove}>
      <XMarkIcon className='h-5 w-5' />
    </div>
  </div>
}

const useUrlState = (key: string) => {
  let url = new URL(window.location.href)
  let searchParams = url.searchParams
  const [value, setValue] = useState<null | string>(searchParams.get(key))

  useEffect(() => {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams
    if (!value) searchParams.delete(key)
    else searchParams.set(key, value)
    window.history.replaceState({}, '', url)
  }, [value])

  return [value, setValue] as const
}

const useUrlStateArray = (key: string) => {
  let url = new URL(window.location.href)
  let searchParams = url.searchParams
  const urlValues = searchParams.get(key)?.split(',') || []
  const [values, setValues] = useState<string[]>(urlValues)

  useEffect(() => {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams
    if (!values || values.length === 0) searchParams.delete(key)
    else searchParams.set(key, values.join(','))
    window.history.replaceState({}, '', url)
  }, [values])

  return [values, setValues] as const
}

type PartSearchProps = {
  categories: Pick<PartCategory, 'id' | 'name'>[]
}

const PartSearch = ({
  partProtos,
  users,
  categories,
  changeOrders
}: CellSuccessProps<FindPartsSearchQuery, FindPartsSearchQueryVariables> & PartSearchProps) => {
  const [search, setSearch] = useUrlState('query')
  const [ownersFilter, setOwnersFilter] = useUrlStateArray('owner')
  const [categoriesFilter, setCategoriesFilter] = useUrlStateArray('category')
  const [changeOrderFilter, setChangeOrderFilter] = useUrlStateArray('changeOrder')

  const handleAddOwner = (user: SelectedUser) => {
    if (ownersFilter.find(uid => uid === user.id)) return
    setOwnersFilter([...ownersFilter, user.id])
  }
  const handleAddCategory = (selectedCategory: SelectedCategory) => {
    if (categoriesFilter.find(cid => cid === selectedCategory.id)) return
    setCategoriesFilter([...categoriesFilter, selectedCategory.id])
  }
  const handleAddChangeOrderFilter = (selectedChangeOrder: SelectedChangeOrder) => {
    if (changeOrderFilter.find(n => n === String(selectedChangeOrder.number))) return
    setChangeOrderFilter([...changeOrderFilter, String(selectedChangeOrder.number)])
  }

  const ownersFiltered = ownersFilter.length ? partProtos.filter(p => {
    return ownersFilter.find(uid => p.owner.id === uid)
  }) : partProtos

  const ownersCategoriesFiltered = categoriesFilter.length ? ownersFiltered.filter(p => {
    return categoriesFilter.find(cid => p.category.id === cid)
  }) : ownersFiltered

  const ownersTypesChangeOrdersFiltered = changeOrderFilter.length ? ownersCategoriesFiltered.filter(p => {
    return changeOrderFilter.find(n => p.inChangeOrders.find(po => String(po.number) === n))
  }) : ownersCategoriesFiltered

  const fuse = new Fuse(ownersTypesChangeOrdersFiltered, fuseOptions);
  const displayList = !search ? ownersTypesChangeOrdersFiltered : fuse.search(search ?? '').map(sr => sr.item)

  const ownerFilterPills = ownersFilter.map(userId => {
    const user = users.find(u => u.id === userId)!
    const handleOwnerRemove = () => {
      setOwnersFilter(ownersFilter.filter(uid => uid !== userId))
    }
    return <FilterPill key={userId} onRemove={handleOwnerRemove} label='Owner' value={user.name} />
  })

  const categoryFilterPills = categoriesFilter.map(categoryId => {
    const category = categories.find(c => c.id === categoryId)!
    const handleTypeRemove = () => {
      setCategoriesFilter(categoriesFilter.filter(cid => cid !== categoryId))
    }
    return <FilterPill key={categoryId} onRemove={handleTypeRemove} label='Type' value={category.name} />
  })
  const changeOrderFilterPills = changeOrderFilter.map(orderNumber => {
    const co = changeOrders.find(c => String(c.number) === orderNumber)!
    const handleOwnerRemove = () => {
      setChangeOrderFilter(changeOrderFilter.filter(n => n !== orderNumber))
    }
    return <FilterPill key={co.number} onRemove={handleOwnerRemove} label='Change Order' value={`#${orderNumber} ${co.name}`} />
  })

  const list = () => {
    if (displayList.length === 0) return <div className='flex bg-gray-100 rounded-lg font-light text-xl h-20 text-gray-800 items-center justify-center italic'>
      No Parts Found
    </div>
    return displayList.map(p => <PartSummary key={p.partNumber} part={p} />)
  }

  const hasFilters = (ownerFilterPills.length + categoryFilterPills.length + changeOrderFilterPills.length) > 0
  return (
    <>
      <div id="partsTopRow" className='flex gap-4 flex-col py-6 sticky top-12 bg-white/60 backdrop-blur-lg z-[23] text-sm'>
        <div className='flex gap-4 items-center'>
          <ViewModeSwitch currentMode='pages' />
          <Input
            value={search || ''}
            onChange={value => setSearch(value)}
            placeholder="Search by part number, name, summary…"
            className="flex-grow mr-2 ml-2"
            icon={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
          <PartCategorySelectCell omit={categoriesFilter.map(c => c)} className='' displayValue='Category' onSelect={handleAddCategory} />
          <UserSelectCell omit={ownersFilter.map(u => u)} className='' displayValue='Owner' onSelect={handleAddOwner} />
          <ChangeOrderSelectCell omit={changeOrderFilter.map(o => Number(o))} className='' placeholder='Change Order' onSelect={handleAddChangeOrderFilter} />
        </div>
        {hasFilters && <div className=''>
          {ownerFilterPills}
          {categoryFilterPills}
          {changeOrderFilterPills}
        </div>}
      </div>
      <div id="partsList" className='flex flex-col gap-4'>
        {list()}
      </div>
    </>
  )
}
