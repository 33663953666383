import React from 'react'
import { FileType } from 'src/lib/mapping/mapperConfigs'

type Props = {
  fileTypes?: FileType[]
  onChange: (fileContents: File) => void
}

const DropZone: React.FC<Props> = (props) => {
  const readFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files?.length === 0) return;
    const file = e.target.files[0]!
    props.onChange(file)
  }
  const accept = (props.fileTypes || ['xlsx', 'numbers', 'csv']).map(ft => `.${ft}`).join(',')
  return <input
    onChange={readFile}
    type="file" id="file_input" accept={accept} />
}

export default DropZone
