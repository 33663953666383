import { useContext } from 'react'
import AppContext from 'src/lib/appContext';
import { LifeCycleStage } from 'types/graphql'

export type ResolvedLifeCycleStage = Omit<LifeCycleStage, 'color'> & {
  stageIndex: number,
  isDeprecated?: boolean,
  color: LifeCycleStage['color'] | 'Gray' | 'Hidden'
}
export const DEPRECATED_KEY = '__deprecated__'
export const UNDEFINED_KEY = '__undefined__'
export const HIDDEN_KEY = '__hidden__'
export const useLifeCycleStages = () => {
  const context = useContext(AppContext)
  return context?.currentOrg.lifeCycleStages.map((s, idx) => ({...s, stageIndex: idx}))
}

export type LifeCycleStages = ReturnType<typeof useLifeCycleStages>

export const resolveLifeCycle = (stages: LifeCycleStages, key: string | undefined | null): ResolvedLifeCycleStage => {
  if (!stages?.length) {
    return {
      key: HIDDEN_KEY,
      name: 'No Stages Defined',
      color: 'Hidden',
      stageIndex: -1
    }
  }

  const stageConfig = stages?.find(s => s.key === key) as Omit<NonNullable<typeof stages>[number], '__typename'> | undefined
  return stageConfig ?? {
    stageIndex: -1,
    isDeprecated: true,
    key: key ? DEPRECATED_KEY : UNDEFINED_KEY,
    name: key ? `Deprecated Stage: ${key}` : 'Undefined',
    color: 'Gray'
  }
}

export const useResolvedLifeCycle = (key: string | undefined | null): ResolvedLifeCycleStage =>
  resolveLifeCycle(useLifeCycleStages(), key)
