import type { Prisma } from '@prisma/client'
import pickBy from 'lodash.pickby'
import type { BomRow } from './1-fileToRows'
import { castBomMetadataToMetadata, MetadataValue, MetadataSchema } from 'src/lib/metadata'
import { MapperConfig, MapperSpec, ConditionsAndExection, Condition, MapExecutionConfig } from './mapperConfigs'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import {
  MetadataType,
  PartNumberByKeyMutation,
  PartNumberByKeyMutationVariables,
  CategoryIdByPartNumberQuery,
  CategoryIdByPartNumberQueryVariables,
  Maybe,
  PartNumberGenerateMutation,
  PartNumberGenerateMutationVariables
} from 'types/graphql'
import { useMutation } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'
import { renderTemplate, UnchangedField, UNCHANGED_FIELD } from './lib'
import type { QuantityUnit } from 'shared/types'

export type CadSource = {
  distributorSku?: Maybe<string>
  distributorName?: Maybe<string>
  url?: Maybe<string>
}

export type CompleteImportedPart = {
  hierarchy: string
  partNumber: string
  name: string
  summary: string
  cadRev: string
  quantity: number
  referenceDesignator?: string
  units: QuantityUnit
  categoryId: string
  isOffTheShelf: boolean
  metadata: Record<string, MetadataValue>
  sources: CadSource[]
}

export type ImportedPart = {
  hierarchy: string | UnchangedField
  partNumber: string
  name: string | UnchangedField
  summary: string | UnchangedField
  cadRev: string | UnchangedField
  quantity: number
  referenceDesignator?: string
  units: QuantityUnit
  categoryId: string | UnchangedField
  isOffTheShelf: boolean | UnchangedField
  metadata: Record<string, MetadataValue>
  sources: CadSource[]
}

export type CadDependency = {
  partNumber: string
  cadRev: string
  quantity: number
}

type Extra = {
  selectedProject: SelectedProject
  metadataSchema: MetadataSchema
  partNumberByKeyMutation:
    ReturnType<typeof useMutation<PartNumberByKeyMutation, PartNumberByKeyMutationVariables>>[0]
  categoryIdByPartNumberQuery:
    ReturnType<typeof useLazyQuery<CategoryIdByPartNumberQuery, CategoryIdByPartNumberQueryVariables>>[0]
  partNumberGenerationMutation:
    ReturnType<typeof useMutation<PartNumberGenerateMutation, PartNumberGenerateMutationVariables>>[0]
}

type BatchMatcher = {
  rowIndex: number
  column: string
  sourceIndex?: number
  type: 'metadata' | 'column' | 'source'
  action: MapExecutionConfig
}

type SimpleBatchMatcher = {
  rowIndex: number
  column: string
  type: 'metadata' | 'column' | 'source'
  action: Extract<MapExecutionConfig, {type: 'simple'} | {type: 'split'} | { type: 'regex' } | { type: 'empty' } | { type: 'quantity' } | { type: 'ignore' }>
}

type GenerateByKeyBatchMatcher = {
  rowIndex: number
  column: string
  type: 'metadata' | 'column' | 'source'
  action: Extract<MapExecutionConfig, {type: 'generateByKey'}>
}

type AlwaysGenerateBatchMatcher = {
  rowIndex: number
  column: string
  type: 'metadata' | 'column' | 'source'
  action: Extract<MapExecutionConfig, {type: 'alwaysGenerate'}>
}

type CategoryIdByPartNumberBatchMatcher = {
  rowIndex: number
  column: string
  type: 'metadata' | 'column' | 'source'
  action: Extract<MapExecutionConfig, {type: 'categoryIdByPartNumber'}>
}

const standardizeBom = async (mapperConfig: MapperConfig, bomRows: BomRow[], extra: Extra): Promise<ImportedPart[]> => {
  const {
    metadata: metadataConfigInput,
    sources: sourcesConfigInput,
    ...remaining
  } = mapperConfig.standardizeBom.columns
  const metadataConfig = metadataConfigInput || {}
  const sourcesConfig = sourcesConfigInput || []

  const { partNumberByKeyMutation, categoryIdByPartNumberQuery, partNumberGenerationMutation } = extra

  let bom = bomRows
  const hierarchyTransform = mapperConfig.standardizeBom.transforms?.hierarchy

  if (hierarchyTransform) {
    const { column } = hierarchyTransform

    type Converted = {
      bom: BomRow[]
      previousHierarchy: number[]
      previousLevel: number
    }

    // removing the first row is fairly custom for Noble and
    // should be made an option
    const converted = bomRows.slice(1).reduce((memo, row) => {
      const level = Number(row[column]!.replace(/[^0-9.]*/g, ''))
      if (level > memo.previousLevel) {
        const hierarchy = [...memo.previousHierarchy, 1]
        return {
          previousHierarchy: hierarchy,
          bom: [...memo.bom, {
            ...row,
            __hierarchy: hierarchy.join('.')
          }],
          previousLevel: level
        }
      }
      if (level === memo.previousLevel) {
        const baseHierarchy = memo.previousHierarchy.slice(0, -1)
        const endHierarchy = memo.previousHierarchy[memo.previousHierarchy.length - 1]!
        const hierarchy = [...baseHierarchy, endHierarchy + 1]
        return {
          previousHierarchy: hierarchy,
          bom: [...memo.bom, {
            ...row,
            __hierarchy: hierarchy.join('.')
          }],
          previousLevel: level
        }
      }
      if (level < memo.previousLevel) {
        const stepsDown = memo.previousLevel - level
        const baseHierarchy = memo.previousHierarchy.slice(0, -(stepsDown + 1))
        const endHierarchy = memo.previousHierarchy[memo.previousHierarchy.length - (stepsDown + 1)]!
        const hierarchy = [...baseHierarchy, endHierarchy + 1]
        return {
          previousHierarchy: hierarchy,
          bom: [...memo.bom, {
            ...row,
            __hierarchy: hierarchy.join('.')
          }],
          previousLevel: level
        }
      }
      return memo
    }, { bom: [], previousHierarchy: [1], previousLevel: 0 } as Converted)
    bom = converted.bom
  }

  const matchers = bom.reduce((batchMatchers, row, rowIndex) => {
    const columnBatch = runMapper(row, remaining, { ...extra, rowIndex })
    const metadataBatch = runMapper(row, metadataConfig, { ...extra, rowIndex })

    const sourcesBatch = sourcesConfig.flatMap((source, sourceIndex) => {
      return Object.entries(source).flatMap(([ key, sourceConfig ]) => {
        return runMapper(row, { [key]: sourceConfig }, { ...extra, rowIndex }).map(result => {
          return {
            ...result,
            sourceIndex,
          }
        })
      })
    })

    return [
      ...batchMatchers,
      ...columnBatch.map(batch => {
        return {
          ...batch,
          rowIndex,
          type: 'column',
        } as BatchMatcher
      }),
      ...metadataBatch.map(batch => {
        return {
          ...batch,
          rowIndex,
          type: 'metadata',
        } as BatchMatcher
      }),
      ...sourcesBatch.map(batch => {
        return {
          ...batch,
          rowIndex,
          type: 'source',
        } as BatchMatcher
      })
    ]
  }, [] as BatchMatcher[])

  const partNumberByKeyGeneratorResults = await generatePartNumbersByKey(
    matchers.filter(m => m.action.type === 'generateByKey') as GenerateByKeyBatchMatcher[],
    partNumberByKeyMutation
  )

  const partNumberGeneratorResults = await generatePartNumbers(
    matchers.filter(m => m.action.type === 'alwaysGenerate') as AlwaysGenerateBatchMatcher[],
    partNumberGenerationMutation
  )

  const categoryIdResults = await categoryIdsByPartNumbers(
    matchers.filter(m => m.action.type === 'categoryIdByPartNumber') as CategoryIdByPartNumberBatchMatcher[],
    categoryIdByPartNumberQuery
  )

  const simpleMatchers = matchers.filter(m => {
    return m.action.type === 'simple' ||
      m.action.type === 'split' ||
      m.action.type === 'regex' ||
      m.action.type === 'quantity' ||
      m.action.type === 'ignore'
  }) as SimpleBatchMatcher[]
  const simpleMatcherResult: (BatchMatcher & { result: string })[]
    = simpleMatchers.map(matcher => {
      return {
        ...matcher,
        result: matcher.action.value || ''
      }
    })

  const allResults = [...partNumberByKeyGeneratorResults, ...categoryIdResults, ...simpleMatcherResult, ...partNumberGeneratorResults]
  const allResultsLookup = allResults.reduce((output, result) => {
    return {
      ...output,
      [result.rowIndex]: [...(output[result.rowIndex] || []), result]
    }
  }, {} as Record<string, typeof allResults[number][]>)
  return bom.map((row, i) => {
    const rowColumnActions = (allResultsLookup[i] || []).filter(m => m.type === 'column')
    const columns = rowColumnActions.reduce((columns, matcher) => {
      return {
        ...columns,
        [matcher.column]: matcher.result
      }
    }, {} as Record<keyof ImportedPart, string>)

    const rowMetadataActions = (allResultsLookup[i] || []).filter(m => m.type === 'metadata')
    const metadata = rowMetadataActions.reduce((metadata, matcher) => {
      return {
        ...metadata,
        [matcher.column]: matcher.result
      }
    }, {} as Record<string, string>)
    const rowSourceActions = (allResultsLookup[i] || []).filter(m => m.type === 'source')
    const sources = rowSourceActions.reduce((sources, matcher) => {
      let output = [...sources]
      output[matcher.sourceIndex!] = output[matcher.sourceIndex!] || {}
      output[matcher.sourceIndex!] = {
        ...output[matcher.sourceIndex!],
        [matcher.column]: matcher.result
      }
      return output
    }, [] as CadSource[]).filter(source => {
      return source.distributorName || source.distributorSku || source.url
    })

    return castToImportedPart(columns, metadata, sources, extra.metadataSchema)
  }, {} as Record<string, string>)
}

const runMapper = (row: BomRow, mapperConfig: Record<string, MapperSpec>, extra: Extra & { rowIndex: number }) => {
  return Object.entries(mapperConfig).reduce((output, [column, config]) => {
    const possibles: ConditionsAndExection[] = Array.isArray(config) ? config : [{
      default: true,
      onMatch: config
    }]
    const spec = possibles.reduce((chosenPossible, possible) => {
      if (chosenPossible && !chosenPossible.default) return chosenPossible
      if (possible.default && chosenPossible) return chosenPossible
      if (possible.default) return possible

      if (conditionMatch(possible.conditions, row)) return possible
      return chosenPossible
    }, null as ConditionsAndExection | null)

    if (!spec) return output

    const action = renderRowMatcher(spec.onMatch, {
      row,
      project: extra.selectedProject,
      rowIndex: extra.rowIndex
    })

    return [
      ...output,
      {
        column,
        action
      }
    ]
  }, [] as Omit<BatchMatcher, 'rowIndex' | 'type'>[])
}

const rTruthy = /(true|yes)/i

const castToImportedPart = (input: Record<keyof ImportedPart, string>, metadataInput: Record<string, string>, sourcesInput: CadSource[], metadataSchema: MetadataSchema) => {
  const { quantity, units, ...stringValues } = input
  const output: ImportedPart = {
    ...stringValues,
    units: units as QuantityUnit,
    quantity: Number(input.quantity),
    referenceDesignator: input.referenceDesignator || undefined,
    isOffTheShelf: (input.isOffTheShelf === UNCHANGED_FIELD) ? UNCHANGED_FIELD : rTruthy.test(input.isOffTheShelf),
    metadata: castBomMetadataToMetadata(metadataSchema, pickBy(metadataInput) as Record<MetadataType, string>),
    sources: sourcesInput
  }

  return output
}

// Does an OR of all the conditions in the array
export const conditionMatch = (conditions: Condition[], row: BomRow) => {
  for (const condition of conditions) {
    if (condition.type === 'empty' && !row[condition.column]) return true
    if (condition.type === 'notEmpty' && row[condition.column]) return true
    if (condition.type === 'eq' && row[condition.column]?.toLowerCase() === condition.value?.toLowerCase()) {
      return true
    }
    if (condition.type === 'regex' && row[condition.column]) {
      const rTest = new RegExp(condition.value)
      return rTest.test(row[condition.column]!)
    }
    if (condition.type === 'contains' && row[condition.column] && row[condition.column]!.includes(condition.value)) {
      return true
    }
    if (condition.type === 'startsWith' && row[condition.column] && row[condition.column]!.indexOf(condition.value) === 0) {
      return true
    }
  }
  return false
}

type TemplateValues = {
  row: BomRow
  rowIndex: number
  project: SelectedProject
}

type RenderRowMatcher = (config: MapExecutionConfig, values: TemplateValues) => MapExecutionConfig
const renderRowMatcher: RenderRowMatcher = (config, values) => {
  if (config.type === 'empty') return { ...config }
  if (config.type === 'ignore') return { ...config, value: UNCHANGED_FIELD }
  if (config.type === 'simple') {
    return {
      ...config,
      value: renderTemplate(config.value, values)
    }
  }
  if (config.type === 'quantity') {
    return {
      ...config,
      value: config.value
    }
  }
  if (config.type === 'split') {
    const input = renderTemplate(config.input, values)
    const parts = input.split(config.splitOn)
    const value = renderTemplate(config.value, { ...values, parts })
    return {
      ...config,
      value
    }
  }

  if (config.type === 'regex') {
    const input = renderTemplate(config.input, values)
    const rMatch = new RegExp(config.regex)
    const allParts = input.match(rMatch) || []
    const match = allParts.slice(1)
    const value = renderTemplate(config.value, { ...values, match })

    return {
      ...config,
      value
    }
  }
  if (config.type === 'generateByKey') {
    return {
      ...config,
      categoryId: renderTemplate(config.categoryId, values),
      partNumberKey: renderTemplate(config.partNumberKey, values)
    }
  }
  if (config.type === 'alwaysGenerate') {
    return {
      ...config,
      categoryId: renderTemplate(config.categoryId, values),
    }
  }
  if (config.type === 'categoryIdByPartNumber') {
    return {
      ...config,
      partNumber: renderTemplate(config.partNumber, values)
    }
  }
  throw new Error('Unrecognized config type')
}

const generatePartNumbersByKey = async (
  partGeneratorMatchers: GenerateByKeyBatchMatcher[],
  partNumberByKeyMutation: ReturnType<typeof useMutation<PartNumberByKeyMutation, PartNumberByKeyMutationVariables>>[0]
) => {
  if (partGeneratorMatchers.length === 0) return []
  const partNumberGeneratorResult = await partNumberByKeyMutation({
    variables: {
      input: {
        keyOptions: partGeneratorMatchers.map(matcher => {
          const generatorOptions = (matcher.action.generatorOptions || {}) as Prisma.JsonValue
          return {
            categoryId: matcher.action.categoryId,
            partNumberKey: matcher.action.partNumberKey,
            generatorOptions,
            schemaKey: matcher.action.schemaKey
          }
        })
      }
    }
  })

  const partNumberGeneratorResults: (BatchMatcher & { result: string })[]
    = partNumberGeneratorResult.data!.partNumberByKey.map((item, i) => {
      const matcher = partGeneratorMatchers[i]!
      return {
        ...matcher,
        result: item.partNumber
      }
    })
  return partNumberGeneratorResults
}

const generatePartNumbers = async (
  partGeneratorMatchers: AlwaysGenerateBatchMatcher[],
  partNumberGenerationMutation: ReturnType<typeof useMutation<PartNumberGenerateMutation, PartNumberGenerateMutationVariables>>[0]
) => {
  if (partGeneratorMatchers.length === 0) return []
  const partNumberGeneratorResult = await partNumberGenerationMutation({
    variables: {
      input: {
        parts: partGeneratorMatchers.map(matcher => {
          return {
            categoryId: matcher.action.categoryId
          }
        })
      }
    }
  })

  const partNumberGeneratorResults: (BatchMatcher & { result: string })[]
    = partNumberGeneratorResult.data!.generatePartNumbers.map((item, i) => {
      const matcher = partGeneratorMatchers[i]!
      return {
        ...matcher,
        result: item.partNumber
      }
    })
  return partNumberGeneratorResults
}

const categoryIdsByPartNumbers = async (
  categoryIdByPartNumberMatchers: CategoryIdByPartNumberBatchMatcher[],
  categoryIdByPartNumberQuery:
    ReturnType<typeof useLazyQuery<CategoryIdByPartNumberQuery, CategoryIdByPartNumberQueryVariables>>[0]
) => {
  if (categoryIdByPartNumberMatchers.length === 0) return []
  const categoryIdResult = await categoryIdByPartNumberQuery({
    variables: {
      input: {
        partNumbers: categoryIdByPartNumberMatchers.map(matcher => {
          return {
            partNumber: matcher.action.partNumber,
            schemaKey: matcher.action.schemaKey
          }
        })
      }
    }
  })

  const categoryIdResults: (BatchMatcher & { result: string })[]
    = categoryIdResult.data!.partCategoryByPartNumber.map((item, i) => {
      const matcher = categoryIdByPartNumberMatchers[i]!
      // if (!item.category) throw new Error(`No category found for part number ${item.partNumber}`)
      return {
        ...matcher,
        result: item.category?.id || 'other'
      }
    })
  return categoryIdResults
}

export default standardizeBom
