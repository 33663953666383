import { AppLink } from 'src/lib/routing'
import { routes, useParams } from '@redwoodjs/router'

const ViewModeSwitch = ({ currentMode } : { currentMode: 'pages' | 'hierarchy' }) => {
  const orgId = useParams().orgId;
  const onPages = currentMode === 'pages';
  const itemClasses = 'flex-1 text-center flex items-center justify-center py-1 text-sm';
  const selectedClass = 'pointer-events-none bg-white rounded';
  return <div className='bg-gray-100 rounded flex w-48 p-1 gap-1'>
    <AppLink to={routes.parts({ orgId })}
      className={`${onPages ? selectedClass : ''} ${itemClasses}`}>
      List
    </AppLink>
    <AppLink to={routes.hierarchy({ orgId })} className={`${!onPages ? selectedClass : ''} ${itemClasses}`}>
      Hierarchy
    </AppLink>
  </div>
}

export default ViewModeSwitch
