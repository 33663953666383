import Sentry from "src/lib/sentry"
import type { CellFailureProps } from '@redwoodjs/web'
import { XMarkIcon } from "@heroicons/react/20/solid"
import { useState } from "react"

export default ({ error, errorCode }: CellFailureProps) => {
  // if we recieive this code, then the code didn't get set!
  let eventId: string = '005998'
  Sentry.withScope((scope) => {
    scope.setExtras({
      errorCode,
    })
    eventId = Sentry.captureException(error)
  })
  return <div className='flex bg-red-200 border border-red-500 p-5 rounded-md items-center gap-10'>
    <img src="/image/broken.gif" className='w-32' alt="Broken" />
    <div className='flex gap-1 flex-col'>
      <div className='text-lg font-medium'>There was an error loading this content</div>
      <div>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
    </div>
  </div>
}

export const FailureErrorBar = ({ error, errorCode, children, userErrorMessage }: CellFailureProps & { children: React.ReactNode, userErrorMessage: string }) => {
  // if we recieive this code, then the code didn't get set!
  let eventId: string = '005998'
  Sentry.withScope((scope) => {
    scope.setExtras({
      errorCode,
    })
    eventId = Sentry.captureException(error)
  })
  const [showError, setShowError] = useState(true)
  return <>
    {children}
    {showError && <div className='z-50 fixed right-0 bottom-0 left-0 bg-white border border-gray-400 rounded-md m-4 shadow-sm'>
      <div className='bg-red-200 font-medium rounded-t-md p-2 px-3 flex items-center'>
        <div className="flex-1 text-gray-700">{userErrorMessage}</div>
        <div className="cursor-pointer" onClick={() => setShowError(false)}><XMarkIcon className="w-5" /></div>
      </div>
      <div className='p-5 px-3 text-sm' >Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
    </div>}
  </>
}
