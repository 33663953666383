import { useParams, routes } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'
import { AppLink } from 'src/lib/routing'

const HomePage = () => {
  const orgId = useParams().orgId;
  return (
    <>
      <MetaTags title="Home" description="Home page" />
      <div>
        This is the home page
        <AppLink to={routes.parts({ orgId })}>Parts</AppLink>
        Home page
      </div>
    </>
  )
}

export default HomePage
