import type { ProjectSelectQuery } from 'types/graphql'
import * as ListBox from '../ListBox'
import * as HeadlessForm from '@redwoodjs/forms'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { useContext, useEffect, useState } from 'react'
import { useParams } from '@redwoodjs/router'
import { getMapperOptions } from 'src/lib/mapping'
import appContext from 'src/lib/appContext'
import PartNumberInput from '../PartNumberInput/PartNumberInput'
import { FailureErrorBar } from '../Failure/Failure'
import PartCategorySelect from '../PartCategorySelect/PartCategorySelect'
import Input from '../Input'

export const QUERY = gql`
  query ProjectSelectQuery {
    projects {
      id
      name
      link
      defaultMapper
      rootPartProto {
        partNumber
        categoryId
        currentVersionString
        currentVersion {
          cadRev
          name
          version
        }
      }
    }
  }
`

export type SelectedProject = {
  newProject: boolean
  noProject?: true
  defaultMapper: string
  link: string
  name: string
  categoryId: string
  id?: string
  rootPartNumber: string
}
type CellProps = {
  onChange: (value: SelectedProject) => void
  allowNone?: boolean
  formMethods: HeadlessForm.UseFormReturn<any, any, undefined>
}

export const beforeQuery = ({ formMethods, ...variables }) => {
  return { variables }
}

export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading projects' {...props}>
    <ProjectSelect {...props} projects={[]} />
  </FailureErrorBar>
}

export const Success = (props: CellProps & CellSuccessProps<ProjectSelectQuery>) => {
  return <ProjectSelect {...props} />
}

export type NewProjectSelectData = {
  categoryId: string
  partNumberBlockValues: Record<string, string>
}

export const ProjectSelect = ({ allowNone, projects, onChange, formMethods }: CellProps & CellSuccessProps<ProjectSelectQuery>) => {
  const [selectProjectId, setProjectId] = useState('')
  const [newProject, setNewProject] = useState(false)
  const [noProject, setNoProject] = useState(false)
  const [newName, setNewName] = useState('')
  const [newLink, setNewLink] = useState('')
  // const [newPartNumber, setNewPartNumber] = useState('')

  const orgId = useParams().orgId!

  const selectedProject = projects.find(p => p.id === selectProjectId)
  const context = useContext(appContext)

  useEffect(() => {
    if (!context) throw new Error('No appContext')
    if (newProject) {
      onChange({
        rootPartNumber: '__none__',
        name: newName,
        link: newLink,
        categoryId: '__none__',
        defaultMapper: getMapperOptions(orgId, context)[0]!.name,
        newProject: true
      })
      return
    }
    if (noProject) {
      onChange({
        rootPartNumber: '__none__',
        name: '__none__',
        link: '',
        categoryId: '__none__',
        defaultMapper: getMapperOptions(orgId, context)[0]!.name,
        newProject: false,
        noProject: true
      })
      return
    }

    if (selectedProject) {
      const rootPart = selectedProject.rootPartProto!
      onChange({
        id: selectedProject.id,
        name: selectedProject.name,
        link: selectedProject.link || '',
        categoryId: rootPart.categoryId,
        defaultMapper: selectedProject.defaultMapper,
        newProject: false,
        rootPartNumber: rootPart.partNumber
      })
    }
  }, [newName, newLink, newProject, selectedProject, noProject])

  const getDisplayValue = () => {
    if (newProject) return 'New Project'
    if (noProject) return 'No Project'
    if (selectedProject) {
      return `#${selectedProject.id} - ${selectedProject.name}`
    }
    return 'Select Project'
  }

  const fm = formMethods as HeadlessForm.UseFormReturn<NewProjectSelectData[], any, undefined>
  const formData = HeadlessForm.useWatch<NewProjectSelectData>()

  const newProjectInput = newProject ? <div className='mt-4 text-sm font-medium leading-6 text-gray-900'>
      <div className='mb-1'>Project Name</div>
      <Input onChange={setNewName} placeholder='Choose a name for your project' />
      <div className='mt-3 mb-1'>Part Category</div>
      <PartCategorySelect
        autoFocus={false}
        className='w-full text-sm h-10'
        displayValue={'Select Category'}
        name={`categoryId`} required />
      <div className='mt-3 mb-1'>Part Number</div>
      <PartNumberInput namePrefix={'partNumberBlockValues'} categoryId={formData.categoryId} formMethods={fm} />
    </div> : null

  const handleSelectChange = (id: string) => {
    setProjectId(id)
    if (id === '__new__') {
      setNewProject(true)
    } else if (id === '__none__') {
      setNoProject(true)
    } else {
      setNewProject(false)
    }
  }
  return <div>
    <ListBox.ListBox value={selectProjectId} onChange={handleSelectChange}>
      {({ open }) => (
        <>
          <div className="relative text-sm">
            <ListBox.Button displayValue={getDisplayValue()} className='w-full text-sm' />
            <ListBox.Options open={open}>
              {projects.map((p) => (
                <ListBox.Option
                  key={p.id}
                  className='py-3'
                  value={p.id}
                  display={`#${p.id} - ${p.name}`} />
              ))}
              <ListBox.Option key='__new__' className='border-t py-3' value='__new__' display={'New Project'} />
              {allowNone && <ListBox.Option key='__none__' className='border-t py-3' value='__none__' display={'Without Project'} />}
            </ListBox.Options>
          </div>
        </>
      )}
    </ListBox.ListBox>
    {newProjectInput}
  </div>
}
