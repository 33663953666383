type IconProps = {
  className?: string
}
export const ZipIcon: React.FC<IconProps> = ({ className }) => {
  return <svg className={className || ''} width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
  <g style={{ fill: 'currentColor' }}>
    <path d="m857.59 600.01h-515.18c-40.477 0-73.598 33.117-73.598 73.598v145.36c0 40.477 33.117 73.598 73.598 73.598h515.18c40.477 0 73.598-33.117 73.598-73.598v-145.36c0-40.48-33.121-73.598-73.598-73.598zm-308.71 223.78h-114.5v-26.156l75.805-95.883h-73.891v-26.273h112.38v24.859l-76.793 97.266h76.984zm58.363 0h-30.793v-148.31h30.793zm136.79-64.191c-8.6836 7.2422-21.078 10.863-37.18 10.863h-30.898v53.328h-30.793v-148.31h63.676c14.703 0 26.391 3.8281 35.137 11.465 8.7305 7.6523 13.086 19.488 13.086 35.531-0.003906 17.52-4.3438 29.883-13.027 37.125z"/>
    <path d="m703.91 701.23h-27.953v43.672h27.938c7.0664 0 12.57-1.75 16.484-5.3125 3.9453-3.5469 5.8711-9.1992 5.8711-16.883 0-7.7148-1.9727-13.203-5.8711-16.5-3.9141-3.3281-9.4062-4.9766-16.469-4.9766z"/>
    <path d="m999.57 258.78-168.75-168.74c-19.75-19.754-55.727-34.648-83.633-34.648h-478.38c-56.801 0-103.04 46.234-103.04 103.04v883.16c0 56.801 46.234 103.04 103.04 103.04h662.38c56.801 0 103.04-46.234 103.04-103.04v-699.17c0-27.922-14.895-63.883-34.648-83.637zm-65.707 17.531c-0.91406-0.015625-1.7812-0.13281-2.6797-0.13281h-73.598c-24.348 0-44.16-19.812-44.16-44.16v-73.598c0-0.91406-0.11719-1.7656-0.13281-2.6641zm41.477 765.28c0 24.348-19.812 44.16-44.16 44.16l-662.37-0.003906c-24.348 0-44.16-19.812-44.16-44.16l0.003906-883.16c0-24.348 19.812-44.16 44.16-44.16h441.58c24.348 0 44.16 19.812 44.16 44.16v73.598c0 56.801 46.234 103.04 103.04 103.04h73.598c24.348 0 44.16 19.812 44.16 44.16v220.79z"/>
  </g>
  </svg>
}
