import type {
  NotificationsQuery,
  NotificationsQueryVariables,
  NotificationsReadMutation,
  NotificationsReadMutationVariables
} from 'types/graphql'
import { useMutation, type CellSuccessProps } from '@redwoodjs/web'

import { QUERY as APP_BODY_QUERY } from 'src/components/AppBodyCell'
import { prettyDateTime } from 'src/lib/formatters'
import { ButtonLink } from 'src/components/Button'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { useEffect } from 'react'
import { reportMutationError } from 'src/lib/reportError'

export const QUERY = gql`
  query NotificationsQuery {
    me {
      id
      notifications {
        id
        createdAt
        header
        body
        buttonText
        buttonLink
      }
    }
  }
`

const NOTIFICATIONS_READ_MUTATION = gql`
  mutation NotificationsReadMutation ($ts: String!) {
    updateUserNotificationsReadAt (ts: $ts)
  }
`

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Success = (props: CellSuccessProps<NotificationsQuery, NotificationsQueryVariables>) => {
  return <Notifications {...props} />
}

const Notifications = ({
  me: {
    notifications
  },
}: CellSuccessProps<NotificationsQuery, NotificationsQueryVariables>) => {
  const [notificationsReadMutation] = useMutation<NotificationsReadMutation, NotificationsReadMutationVariables>(NOTIFICATIONS_READ_MUTATION)

  const updateReadAt = async () => {
    const variables: NotificationsReadMutationVariables = {
      ts: String(Date.now())
    }
    const { errors } = await notificationsReadMutation({
      refetchQueries: [{ query: APP_BODY_QUERY }],
      awaitRefetchQueries: true,
      variables
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error setting notifications read at`
      })
    }
  }
  useEffect(() => {
    updateReadAt()
  }, [])

  return (<div className='flex flex-col items-center pt-8'>
      <div className='flex flex-col gap-8 mt-2 max-w-[56rem] w-full'>
        <div className='text-2xl'>Notifications</div>
        <div className='flex flex-col gap-4'>
          {notifications.length > 0 ? notifications.map(n => {
            const tsMs = Number(n.createdAt)
            return <div key={n.id} className='border-t border-gray-300 gap-4 flex flex-col text-sm p-4'>
              <div className='flex gap-2 px-4rounded-t-md  items-center'>
                <div className='font-medium flex-1 flex gap-4 flex-col items-start'>
                  <div>{n.header}</div>
                </div>
                <div className='italic text-gray-600'>{prettyDateTime(new Date(tsMs))}</div>
              </div>
              <div>
                <ButtonLink size='sm' to={n.buttonLink}>{n.buttonText}</ButtonLink>
              </div>
            </div>
          }): <div className='italic'>You haven't received any notifications yet</div>}
        </div>
      </div>
    </div>
  )
}
