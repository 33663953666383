import type {
  Part,
  Dependency,
  PartProto,
  Project,
  Maybe,
  PartEditType,
  DependencySection,
  DependencyInput,
  PartDeltaInput,
  PartCategory,
  Source,
} from 'types/graphql'
import isEqual from 'lodash.isequal'
import pick from 'lodash.pick'
import omit from 'lodash.omit'

import { MetadataValue } from 'src/lib/metadata'
import { findAncestors, findPartParent, findPartChildren, partIsDescendent } from 'api/src/lib/bom'
import type { ImportedPart, CompleteImportedPart } from 'src/lib/mapping'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { ImportOutputFields } from 'src/lib/mapping/mapperConfigs'
import { CadSource } from 'src/lib/mapping/3-standardizeBom'
import { UNCHANGED_FIELD } from 'src/lib/mapping/lib'
import type { QuantityUnit } from 'shared/types'

type NullableOn<T, K extends keyof T> = Omit<T, K> & {
  [Properties in keyof Pick<T, K>]?: T[Properties] | null | undefined
}

/*
 * TODO edge case checks
 * - [x] All changes to items at the top level should be registered
 * - [x] Dereference an assembly, ensure its children are not marked as changed or dereferenced
 * - [ ] Project references a part at cadRev X, part exists in the system as cadRev Y
 *       and then new import references cadRev Y. This should show as a change. (may need to get project hierarchy for this).
 *       No! I think we just need to check whether parts in general are referencing older versions. This can be done using
 *       yet another diff on dependencies!
 * - [ ] Assembly has a new part reference, and the assembly has a cadrev change. The same assembly is referenced elsewhere with these
 *       changes. Is the hierarchy the best index, or should these changes be indexed by part number for changes and then partnumber +
 *       child part number for a reference change?
*/

/*
 * Change Logic
 * ------------
 * When a BOM is imported, each part can have changes (i.e. they are different to the latest version in the system), and
 * each reference to a part can change (a part reference could be added, removed, or the quantity or cad version modified).
 *
 * Adding these up, you get part created or updated and part reference added, removed, or modified. Each of these changes
 * could be split up when selected e.g. a part number could be created or updated and at the same time multiple references
 * to that part could also be updated.
 *
 * In order to make the UI more simple, we group reference changes with part changes.
 *
 * For example, take a reference that has been added to an assembly X, already exists in assembly Y and the part
 * has been updated. If the user chooses to update the reference in assembly Y, then they can optionally include
 * the new reference in assembly X. But if they add the new reference in assembly X, then assembly Y will automatically
 * get the update too (else the BOM will have 2 different versions of the same part).
 *
 * For example, take a reference whos quantity has been adjusted in assembly X, already exists in assembly Y and the part
 * has been updated. If the user chooses to update the quantity then the part must be updated too, as well as the reference
 * in assembly Y (else the BOM will have 2 different versions of the same part).
 *
 * For example, take the hierarchy of parts A > B > C > D and A > X > C > D. If the quantity of D is updated, and D is modified,
 * and C is modified, then if you include the new quantity of D then the change for D will be included. If you choose to then include
 * the changes from C, then both A > B > C and A > X > C will be updated.
 *
 *
 *
 * Traversing the change tree
 * --------------------------
 * The comparison is currently against parts in the change order, and the most up to date parts in the system. However
 * parts need to also be compared to how they are in the actual up-to-date project tree. When calculating part node change
 * we should do a final check to see if the cadrev in the BOM is ahead of the cadrev in the project tree, and if it is then
 * we should update the reference to that part to the current version (iff the cad revs match). If the CAD revs do not match
 * then it is an error (because the reference must be to a new CAD rev we haven't seen which is a version behind our systems
 * cadrev)
 */

type HeadPartDependency = Pick<Dependency, 'section' | 'toVersionRange' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'name' | 'cadRev' | 'version' | 'partNumber' | 'isOffTheShelf' | 'summary'> & {
    metadata: Record<string, MetadataValue>
    sources: Pick<Source, 'distributorId' | 'distributorSku'>[]
    proto: {
      category: Pick<PartCategory, 'id'>
    }
  }
}
type HeadPartProto = Pick<PartProto, 'partNumber'> & {
  category: Pick<PartCategory, 'id'>
  project?: Maybe<Pick<Project, 'id'>>,
}
type HeadPart = Pick<Part,
  'name' |
  'cadRev' |
  'summary' |
  'version' |
  'isOffTheShelf'> & {
    dependencies: HeadPartDependency[]
    proto: HeadPartProto
    sources: Pick<Source, 'distributorId' | 'distributorSku'>[]
    metadata: Record<string, MetadataValue>
  }

export type ExistingPartProto = HeadPartProto & {
  currentVersion?: Maybe<Omit<HeadPart, 'proto'>>
}
export type ChangeOrderPart = HeadPart & {
  proto: HeadPartProto
}

export type NormalizedHeadPart = HeadPart & {
  inChangeOrder: Boolean
}

export type MapperSource = {
  distributorSku?: Maybe<string>
  distributorId?: Maybe<string>
  url?: Maybe<string>
}

export const normalizeHeadParts: (
  importedBom: ImportedPart[],
  existingProtos: ExistingPartProto[],
  changeOrderParts: ChangeOrderPart[],
  rootPartNumber: string
) => NormalizedHeadPart[] = (
  importedBom,
  existingProtos,
  changeOrderParts,
  rootPartNumber
) => {
  const bomPartNumbers = [...new Set(importedBom.map(p => p.partNumber))]
  const normalizedMainParts = existingProtos.map(p => {
    return {
      ...p.currentVersion!,
      inChangeOrder: false,
      proto: {
        category: p.category,
        partNumber: p.partNumber,
        project: p.project
      }
    }
  })
  type y = (typeof normalizedMainParts)[number]['sources']

  const relevantChangeOrderParts = changeOrderParts
    .filter(p => bomPartNumbers.includes(p.proto.partNumber) || p.proto.partNumber === rootPartNumber)
    .map(p => {
      return {
        ...p,
        inChangeOrder: true
      } as NormalizedHeadPart
    })
  type x = (typeof relevantChangeOrderParts)[number]['sources']

  return normalizedMainParts.reduce((headParts, p) => {
    if (headParts.some(h => h.proto.partNumber === p.proto.partNumber)) return headParts
    return [...headParts, p]
  }, relevantChangeOrderParts)
}


type CompleteInputBomInput = {
  importedBom: ImportedPart[]
  normalizedHeadParts: NormalizedHeadPart[]
}
export const completeInputBom = ({
  importedBom,
  normalizedHeadParts,
}: CompleteInputBomInput) => {
  const projectNodes = importedBom.filter(partNode => {
    const headPart = normalizedHeadParts
      .find(hp => hp.proto.partNumber === partNode.partNumber)
    return headPart?.proto.project
  })

  // Exclude sub-project hierarchies but not the projects themselves
  // which can still be updated by the parent project
  const bomWithoutSubProjectsDescendants = importedBom.filter(partNode => {
    return !projectNodes.some(pn => partIsDescendent(pn.hierarchy, partNode.hierarchy))
  })

  return bomWithoutSubProjectsDescendants.map((partNode) => {
    const headPart = normalizedHeadParts
      .find(hp => hp.proto.partNumber === partNode.partNumber)
    return equalizeIgnores(partNode, headPart)
  })
}

// ensure that for each repeated part that the hierarchy
// below it is identical, and that there are not 2 parts
// with the same part number that have different changes
// or one that has a change and another that does not
export const validateBom = (importedBom: ImportedPart[]) => {

}

type DiffablePart = {
  cadRev?: Maybe<string>
  name?: Maybe<string>
  summary?: Maybe<string>
  metadata: Record<string, MetadataValue>
  isOffTheShelf?: Maybe<boolean>
  sources?: MapperSource[]
}

export const findNewSources = (headSources: MapperSource[], incomingSources: MapperSource[]) => {
  return incomingSources.filter(source => {
    if (!source.distributorSku && !source.distributorId) {
      // If any value is there, but there is no identifiers
      // then include it. If no values then exclude.
      return Object.values(source).some(value => value)
    }
    return !headSources.some(headSource => {
      // return true where head source is assumed to be the incoming source

      // when there is no incoming SKU, assume that any matching distributor ID
      // is a match
      if (source.distributorId && !source.distributorSku) {
        return headSource.distributorId === source.distributorId
      }

      // when there is no incoming distributor ID, assume that any matching SKU is a match
      if (source.distributorSku && !source.distributorId) {
        return headSource.distributorSku === source.distributorSku
      }

      return headSource.distributorId === source.distributorId &&
        headSource.distributorSku === source.distributorSku
    })
  })
}

const diffFields: (keyof DiffablePart)[] = [
  'cadRev',
  'name',
  'isOffTheShelf',
  'metadata',
  'summary'
]

const findPartDifference = (headNode: DiffablePart, incomingNode: DiffablePart, importedMetadataKeys: string[]) => {
  const head = {
    ...headNode,
    metadata: pick(headNode.metadata, importedMetadataKeys)
  }
  const incoming = {
    ...incomingNode,
    metadata: pick(incomingNode.metadata, importedMetadataKeys)
  }

  const headSources = headNode.sources || []
  const incomingSources = incomingNode.sources || []
  const newIncomingSources = findNewSources(headSources, incomingSources)

  if (newIncomingSources.length > 0) return true

  const difference = diffFields.some(field => {
    // falsey values are always equal
    if (!head[field] && !incoming[field]) return false
    return !isEqual(head[field], incoming[field])
  })
  return difference
}

/*
 * Make fields which are ignored the same as the head part
 * so that they stay the same after import.
 */
const equalizeIgnores = (incomingNode: ImportedPart, headNode?: NormalizedHeadPart) => {
  const incoming = {
    ...incomingNode,
  }

  const ensureHead: (fieldName: string, h?: NormalizedHeadPart) => asserts h is NonNullable<typeof headNode>
    = (fieldName: string, h) => {
    if (!h) {
      throw new Error(`There is no head part to receive ${fieldName} from`)
    }
  }

  if (incoming.cadRev === UNCHANGED_FIELD) {
    incoming.cadRev = headNode?.cadRev || ''
  }

  if (incoming.name === UNCHANGED_FIELD) {
    ensureHead('name', headNode)
    incoming.name = headNode.name || ''
  }

  if (incoming.isOffTheShelf === UNCHANGED_FIELD) {
    incoming.isOffTheShelf = headNode?.isOffTheShelf || false
  }

  if (incoming.name === UNCHANGED_FIELD) {
    ensureHead('name', headNode)
    incoming.name = headNode.name || '-'
  }

  if (incoming.summary === UNCHANGED_FIELD) {
    incoming.summary = headNode?.summary || ''
  }

  if (incoming.categoryId === UNCHANGED_FIELD) {
    ensureHead('categoryId', headNode)
    incoming.categoryId = headNode.proto.category.id
  }

  return incoming as CompleteImportedPart
}

const addChildChanges = (
  partNumber: string,
  partHierarchy: string,
  childNodes: CompleteImportedPart[],
  headPartDependencies: HeadPartDependency[],
  allChanges: AllChangesWithoutLinks,
  importOutputFields: ImportOutputFields
) => {
  let nextChanges: AllChangesWithoutLinks = [...allChanges]
  nextChanges = childNodes
    .reduce((memo, childNode) => {
      // node referenced by this part
      const headNode = headPartDependencies.find(d => d.to.partNumber === childNode.partNumber)

      // latest version of the node - could not be there if it has been removed in imported BOM
      // const headPart = normalizedHeadParts.find(d => d.proto.partNumber === childNode.partNumber)
      if (!headNode) {
        const changes: AllChangesWithoutLinks = [
          ...memo,
          {
            key: `${partNumber}--${childNode.partNumber}`,
            partNumber,
            node: childNode,
            changeSubject: 'reference',
            changeType: 'reference' as PartNodeChange
          }
        ]
        return changes
      }

      const refDesEmoty = !headNode.referenceDesignator && !childNode.referenceDesignator

      const nodeModified =
        headNode.quantity !== childNode.quantity
        || headNode.units !== childNode.units
        || (!refDesEmoty && headNode.referenceDesignator !== childNode.referenceDesignator)

      const partModified = headNode && findPartDifference(headNode.to, childNode, importOutputFields.metadata)

      // const updatedRef = headNode.to.cadRev !== childNode.cadRev
      // // only works if the cadrev in the BOM is the latest version!
      // // which should be done in validation insted!
      // const validUpdate = childNode.cadRev === headPart?.cadRev
      if (nodeModified || partModified) {
        return [
          ...memo,
          {
            partNumber,
            key: `${partNumber}--${childNode.partNumber}`,
            node: childNode,
            changeType: 'modify' as PartNodeChange,
            headDependencyData: headNode,
            changeSubject: 'reference',
          }
        ]
      }

      return memo
    }, nextChanges)

  const removedNodes = headPartDependencies
    .filter(d => {
      if (d.section === 'Manual') return false
      return childNodes.every(n => n.partNumber !== d.to.partNumber)
    })

  let index = childNodes.reduce((highestIndex, node) => {
    const nodeIndex = Number(node.hierarchy.match(/([^.])+$/)![1])
    return nodeIndex > highestIndex ? nodeIndex : highestIndex
  }, 1)

  nextChanges = removedNodes
    .reduce((memo, childNode) => {
      index++
      const hierarchy = `${partHierarchy}.${index}`
      const changes: AllChangesWithoutLinks = [
        ...memo,
        {
          partNumber,
          key: `${partNumber}--${childNode.to.partNumber}`,
          node: {
            hierarchy,
            summary: childNode.to.summary!,
            sources: childNode.to.sources as CadSource[],
            partNumber: childNode.to.partNumber,
            name: childNode.to.name!,
            cadRev: childNode.to.cadRev!,
            quantity: childNode.quantity,
            referenceDesignator: childNode.referenceDesignator || '',
            units: childNode.units as QuantityUnit,
            categoryId: childNode.to.proto.category.id,
            isOffTheShelf: childNode.to.isOffTheShelf,
            metadata: childNode.to.metadata
          },
          changeSubject: 'reference',
          changeType: 'dereference' as PartNodeChange
        }
      ]
      return changes
    }, nextChanges)
  return nextChanges
}

type FindChangesInput = {
  rootPartNumber: string
  completeInputBom: CompleteImportedPart[]
  normalizedHeadParts: NormalizedHeadPart[]
  importOutputFields: ImportOutputFields
}

type PartChange = 'create' | 'modify'
type PartNodeChange = 'reference' | 'dereference' | 'modify'

export type Change = {
  partNumber: string
  key: string
  node: CompleteImportedPart
  onAddLinks: string[]
  onRemoveLinks: string[]
} & ({
    changeSubject: 'part',
    changeType: PartChange,
    head?: HeadPart
  }
  | {
    changeSubject: 'reference',
    changeType: PartNodeChange,
    headDependencyData?: HeadPartDependency
  })
type AllChanges = Change[]

type ChangeWithoutLink = {
  partNumber: string
  key: string
  node: CompleteImportedPart
} & ({
    changeSubject: 'part',
    changeType: PartChange,
    head?: HeadPart
  }
  | {
    changeSubject: 'reference',
    changeType: PartNodeChange,
    headDependencyData?: HeadPartDependency
  })
type AllChangesWithoutLinks = ChangeWithoutLink[]

export type ChangeGroup = {
  id: string | null
  changes: AllChanges
}

export const findChanges = ({
  rootPartNumber,
  completeInputBom,
  normalizedHeadParts,
  importOutputFields
}: FindChangesInput) => {
  const allChanges = completeInputBom.reduce((allChanges, incomingNode) => {
    const headPart = normalizedHeadParts
      .find(hp => hp.proto.partNumber === incomingNode.partNumber)

    let nextChanges: AllChangesWithoutLinks = allChanges
    if (!headPart) {
      nextChanges = [
        ...nextChanges,
        {
          key: incomingNode.partNumber,
          partNumber: incomingNode.partNumber,
          changeType: 'create' as PartChange,
          changeSubject: 'part',
          node: incomingNode
        },
      ]
    }

    const partModified = headPart && findPartDifference(headPart, incomingNode, importOutputFields.metadata)

    if (partModified) {
      nextChanges = [
        ...allChanges,
        {
          key: incomingNode.partNumber,
          partNumber: incomingNode.partNumber,
          head: headPart,
          changeType: 'modify' as PartChange,
          changeSubject: 'part',
          node: incomingNode
        }
      ]
    }

    if (headPart?.proto.project) {
      return nextChanges
    }

    const childNodes: CompleteImportedPart[] = findPartChildren(completeInputBom, incomingNode.hierarchy)
    const childRefChanges = addChildChanges(
      incomingNode.partNumber,
      incomingNode.hierarchy,
      childNodes,
      headPart?.dependencies || [],
      nextChanges,
      importOutputFields
    )
    return childRefChanges
  }, [] as AllChangesWithoutLinks)

  const rootChildren = findPartChildren(completeInputBom, '1')
  const rootHeadPart = normalizedHeadParts.find(p => p.proto.partNumber === rootPartNumber)
  const parentRefChanges = addChildChanges(rootPartNumber, '1', rootChildren, rootHeadPart?.dependencies || [], allChanges, importOutputFields)

  const changesWithAddLinks = parentRefChanges.map(change => {
    if (change.changeSubject === 'part') return { ...change, onAddLinks: [], onRemoveLinks: [] }
    const changeAncestors = findAncestors(completeInputBom, change.node.hierarchy).map(a => a.partNumber)
    return {
      ...change,
      onAddLinks: parentRefChanges.filter(otherChange => {
        if (otherChange.changeSubject === 'part') return otherChange.partNumber === change.node.partNumber

        // if other change is an ancestor and is also being
        // newly referenced, then that change needs to be included too
        if (changeAncestors.includes(otherChange.node.partNumber) && otherChange.changeType === 'reference') {
          return true
        }
        return false
      }).map(c => c.key),
      onRemoveLinks: []
    }
  }) as AllChanges

  // Calculate remove links as the inverse of add links
  const changesWithLinks = changesWithAddLinks.map(change => {
    const onRemoveLinks = changesWithAddLinks.filter(otherChange => otherChange.onAddLinks.includes(change.key)).map(c => c.key)
    return {
      ...change,
      onRemoveLinks
    }
  })
  return changesWithLinks
}

export const groupChanges = (rootPartNumber: string, changes: AllChanges) => {
  const changeGroups = changes.reduce((changeGroups, change) => {
    const changeGroupId = change.changeSubject === 'part' ? null : change.partNumber
    const existingChangeGroup = changeGroups.find(cg => cg.id === changeGroupId)
    if (!existingChangeGroup) {
      return [
        ...changeGroups,
        {
          id: changeGroupId,
          changes: [change]
        }
      ]
    }
    return changeGroups.map(cg => {
      if (cg.id === changeGroupId) {
        return {
          ...cg,
          changes: [...cg.changes, change]
        }
      }
      return cg
    })
  }, [] as ChangeGroup[])
  return changeGroups.sort((a, b) => {
    if (a.id === null || b.id === rootPartNumber) return 1
    if (b.id === null || a.id === rootPartNumber) return -1
    return a.id > b.id ? 1 : -1
  })
}

type DeltasExtraInput = {
  rootPartNumber: string
  completeInputBom: CompleteImportedPart[]
  allChanges: AllChanges
  normalizedHeadParts: NormalizedHeadPart[]
  importOutputFields: ImportOutputFields
  defaultPartVersion: string
}
export const deltas = (selectedPartChanges: string[], {
  rootPartNumber,
  allChanges,
  completeInputBom,
  normalizedHeadParts,
  importOutputFields,
  defaultPartVersion
}: DeltasExtraInput) => {
  // find selected changes from all changes
  const selectedChanges = Object.values(allChanges)
    .filter(change => selectedPartChanges.includes(change.key))

  type CadDelta = {
    categoryId: string
    name?: string
    summary?: string
    cadRev?: string
    metadata: Record<string, MetadataValue>
    isOffTheShelf: boolean
    addedAndModifiedDependencies: Record<string, { quantity: number, referenceDesignator?: string, units: string }>
    removedDependencies: string[]
    descendantChange?: boolean
    addedSources: CadSource[]
  }
  type CadDeltas = Record<string, CadDelta>
  // for each part changes, prepare deltas (by part number)
  const partChanges: CadDeltas = selectedChanges.reduce((partChanges, change) => {
    const partNumber = change.node.partNumber

    let nextChanges = { ...partChanges }

    const mergeChange = (partChanges: CadDeltas, pn: string, change: Partial<CadDelta>) => {
      const update = {} as Omit<CadDelta, 'addedAndModifiedDependencies' | 'removedDependencies'>

      if (change.isOffTheShelf) update.isOffTheShelf = change.isOffTheShelf
      if (change.categoryId) update.categoryId = change.categoryId
      if (change.name) update.name = change.name
      if (change.summary) update.summary = change.summary
      if (change.cadRev) update.cadRev = change.cadRev
      if (change.metadata) update.metadata = change.metadata
      if (change.addedSources) update.addedSources = change.addedSources
      if (change.descendantChange) update.descendantChange = change.descendantChange
      return {
        ...partChanges,
        [pn]: {
          ...partChanges[pn],
          ...update,
          addedSources: change.addedSources || [],
          addedAndModifiedDependencies: {
            ...partChanges[pn]?.addedAndModifiedDependencies,
            ...change.addedAndModifiedDependencies
          },
          removedDependencies: [
            ...(partChanges[pn]?.removedDependencies || []),
            ...(change.removedDependencies || [])
          ],
        }
      }
    }

    if (change.changeSubject === 'part') {
      // don't bubble up when it's create only
      return mergeChange(nextChanges, partNumber, {
        categoryId: change.node.categoryId,
        name: change.node.name,
        summary: change.node.summary,
        cadRev: change.node.cadRev,
        isOffTheShelf: change.node.isOffTheShelf,
        metadata: change.node.metadata,
        addedSources: findNewSources(change.head?.sources || [], change.node.sources || [])
      })
    }

    if (change.changeType === 'dereference') {
      const parent = findPartParent(completeInputBom, change.node.hierarchy)
      const parentPn = parent?.partNumber || rootPartNumber
      nextChanges = mergeChange(nextChanges, parentPn, {
        removedDependencies: [partNumber]
      })
    }

    // even if the part node is not modified, we mark it as changed because
    // there will be a part change
    if (change.changeType !== 'dereference') {
      const parent = findPartParent(completeInputBom, change.node.hierarchy)
      const parentPn = parent?.partNumber || rootPartNumber
      nextChanges = mergeChange(nextChanges, parentPn, {
        addedAndModifiedDependencies: {
          [partNumber]: { quantity: change.node.quantity, referenceDesignator: change.node.referenceDesignator, units: change.node.units }
        }
      })
    }

    return nextChanges
  }, {} as CadDeltas)

  type DeltasWithVersion = Record<string, CadDelta & {
    headPart?: NormalizedHeadPart
    version: string
  }>
  const changesWithVersions = Object.entries(partChanges).reduce((memo, [partNumber, change]) => {
    const headPart = normalizedHeadParts.find(p => p.proto.partNumber === partNumber)

    const version = () => {
      if (!headPart) return defaultPartVersion
      if (headPart.inChangeOrder) return headPart.version
      return calculateNextVersion(headPart.version)
    }

    return {
      ...memo,
      [partNumber]: {
        headPart,
        version: version(),
        ...change
      }
    }
  }, {} as DeltasWithVersion)

  const deltas = Object.entries(changesWithVersions).map(([partNumber, change]) => {
    const { version, headPart, addedAndModifiedDependencies, removedDependencies } = change

    type GetEditType = () => PartEditType
    const getEditType: GetEditType = () => {
      if (!headPart) return 'Create'
      return headPart.inChangeOrder ? 'Patch' : 'Push'
    }

    const dependencies = () => {
      const updatedDeps = Object.entries(addedAndModifiedDependencies).map(([partNumber, d]) => {
        const dep: NullableOn<DependencyInput, 'groupId' | 'referenceDesignator'> = {
          partNumber,
          versionRange: '*',
          section: 'CAD' as DependencySection,
          quantity: d.quantity,
          units: d.units,
          //TODO: should this groupId be importable?
          //groupId: nullControlSeq,
          //groupId: d.groupId,
          referenceDesignator: d.referenceDesignator,
        }
        return dep
      })

      // created parts don't need to merge anything
      if (!headPart) return updatedDeps

      const dependencyToInput = (d: HeadPartDependency) => {
        const input: NullableOn<DependencyInput, 'groupId' | 'referenceDesignator'> = {
          partNumber: d.to.partNumber,
          versionRange: d.toVersionRange,
          section: d.section,
          quantity: d.quantity,
          units: d.units,
          referenceDesignator: d.referenceDesignator,
          groupId: d.groupId,
        }
        return input
      }

      const remainingHeadDeps = headPart.dependencies.filter(d => {
        if (updatedDeps.some(ud => ud.partNumber === d.to.partNumber)) {
          return false
        }
        if (removedDependencies.includes(d.to.partNumber)) {
          return false
        }
        return true
      }).map(dependencyToInput)

      return [...updatedDeps, ...remainingHeadDeps]
    }

    type Update = Omit<CadDelta, 'addedAndModifiedDependencies' | 'removedDependencies' | 'metadata' | 'addedSources'> & {
      metadata: Record<string, any>
      sources: (CadSource & { priority: number })[]
    }
    const update = {} as Update
    if (change.name) update.name = change.name
    if (change.summary) update.summary = change.summary
    if (change.cadRev) update.cadRev = change.cadRev
    if (change.metadata) {
      update.metadata = {
        ...omit(headPart?.metadata, importOutputFields.metadata),
        ...change.metadata
      }
    }
    if (change.addedSources.length) {
      update.sources = ([
        ...(headPart?.sources || []),
        ...change.addedSources
      ]).map((source, i) => {
        return {
          ...omit(source, ['__typename']),
          priority: i
        }
      })
    }
    if (change.isOffTheShelf) update.isOffTheShelf = change.isOffTheShelf

    type OldDeps = NonNullable<NonNullable<PartDeltaInput['part']>['dependencies']>[number]
    type NewDeps = NullableOn<OldDeps, 'groupId' | 'referenceDesignator'>
    type WithoutPart = Omit<PartDeltaInput, 'part'>
    type NewPart = Omit<NonNullable<PartDeltaInput['part']>, 'dependencies'> & {
      dependencies?: NewDeps[] | null | undefined
    }
    type PartialDeltaInput = WithoutPart & {
      part?: NewPart | null | undefined
    }

    const partDelta: PartialDeltaInput = {
      version,
      partNumber,
      type: getEditType(),
      part: {
        ...update,
        dependencies: dependencies()
      }
    }

    if (partDelta.type === 'Create') partDelta.categoryId = change.categoryId!
    return partDelta
  })

  return deltas
}
