import { MetaTags } from '@redwoodjs/web'
import { NavLink, routes, useParams, Link } from '@redwoodjs/router'

import { ButtonLink } from 'src/components/Button'
import ChangeOrdersCell from "src/components/ChangeOrdersCell"
import { ChangeOrderState } from 'types/graphql'

const StateFilters: Record<string, ChangeOrderState> = {
  draft: 'Draft',
  review: 'Review',
  complete: 'Complete',
  cancelled: 'Cancelled'
}

function isStateFilter(filter: string): filter is keyof typeof StateFilters {
  return (filter in StateFilters);
}

const ChangeOrdersPage = () => {
  const orgId = useParams().orgId!
  const state = useParams().state!
  const filter = isStateFilter(state) ? state : 'draft';

  return (
    <>
      <MetaTags title="Change Orders" description="ChangeOrders page" />
      <div className='rounded-lg border border-slate-200 mt-6'>
        <div className='flex bg-gray-50 p-4 border-b border-b-gray-200 rounded-t-lg'>
          <nav className='flex gap-2 *:inline-block *:text-gray-700'>
            {Object.entries(StateFilters).map(([state, label]) =>
              <li key={state}>
                <NavLink
                  className='pr-2 pl-2 align-middle'
                  activeClassName='font-semibold'
                  to={routes.changeOrders({state, orgId})}>{label}
                  </NavLink>
              </li>
            )}
          </nav>
          <div className='ml-auto'>
            <ButtonLink writeOnly variant='primary' to={routes.changeOrderCreate({ orgId })}>
              Create Change Order
            </ButtonLink>
          </div>
        </div>
        <div className='border-1 border-t-0 rounded-b-lg border-gray-300'>
          <ChangeOrdersCell filter={StateFilters[filter]!}/>
        </div>
      </div>
  </>)
}

export default ChangeOrdersPage
