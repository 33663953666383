import OrgSettingsLifecycleCell from 'src/components/OrgSettingsLifecycleCell'
import { MetaTags } from '@redwoodjs/web'

const OrgSettingsLifecycleCellPage = () => {
  return (
    <>
      <MetaTags
        title="Lifecycle Settings"
        description="Company Lifecycle Settings"
      />
      <OrgSettingsLifecycleCell/>
    </>
  )
}

export default OrgSettingsLifecycleCellPage
