// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful :)

// This import will be automatically removed when building for production
import { ErrorPage } from 'src/components/FatalErrorBoundary/FatalErrorBoundary'
import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

const productionError: ErrorPage = ({ eventId, ...rest }) => {
  console.log(eventId, rest)
    return <div className='flex items-center fixed left-0 right-0 top-0 bottom-0 justify-center border-t-8 border-red-600'>
      <div className='flex flex-col items-center gap-4 max-w-screen-sm'>
        <img src="/image/broken.gif" className='' alt="Broken" />
        <div className='flex gap-6 flex-col p-4'>
          <div className='text-xl font-medium'>There was an error loading this content</div>
          <div>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
          <a href='/' className='text-blue-700 font-medium text-right'>Go back to parts page</a>
        </div>
      </div>
    </div>
}

// export default DevFatalErrorPage || productionError
export default DevFatalErrorPage || productionError
