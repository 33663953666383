import { useMemo } from 'react'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import type {
  RootPartHierarchyQuery,
  RootPartHierarchyQueryVariables,
} from 'types/graphql'
import GenericFailure from '../Failure/Failure'

import { HierarchyControllerFactoryParams, HierarchyContainer, addVersionWarnings } from '../PartHierarchy/PartHierarchy';

type RootPart = RootPartHierarchyQuery['rootParts'][number]

export const QUERY = gql`
  query RootPartHierarchyQuery {
    orphanParts {
      name
      partNumber
      summary
      version
      lifeCycle
      proto {
        category {
          name
        }
        currentVersionString
      }
      hierarchy {
        hierarchy
        part {
          name
          version
          lifeCycle
          partNumber
          proto {
            category {
              name
            }
            currentVersionString
          }
        }
        dependency {
          quantity
          referenceDesignator
          units
          groupId
          toVersionRange
        }
      }
    }
  }
`

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

export const Empty = () => <></>

export const Failure = GenericFailure

type SuccessProps = CellSuccessProps<RootPartHierarchyQuery, RootPartHierarchyQueryVariables> & {
  highlightedPartNumber?: string
  alignX?: 'header' | 'children'
}
export const Success = ({
  orphanParts,
  alignX,
  highlightedPartNumber
}: SuccessProps) => {

  const rootComponents: HierarchyControllerFactoryParams[] = useMemo(() => {
    return orphanParts
      .map(root => {
        if (!highlightedPartNumber) {
          return { root }
        }
        const rootMatch = root.partNumber === highlightedPartNumber
        if (rootMatch) {
          return {
            highlightRoot: true,
            root
          }
        }
        const highlighedNodes = root.hierarchy.filter(n => {
          return n.part.partNumber === highlightedPartNumber
        })

        return {
          root,
          highlighedNodes
        }
      })
      .filter((result) => !(result.highlighedNodes?.length === 0))
      .map(({ root, highlighedNodes, highlightRoot }): HierarchyControllerFactoryParams => {
        const expandedNodes = () => {
          if (!highlighedNodes) return []
          return highlighedNodes.flatMap(n => {
            // special case root part
            // if (n.hierarchy.split('.').length === 2) return '1'
            return findAncestors(root.hierarchy, n.hierarchy).map(n => n.hierarchy)
          })
        }

        // There may be multiple nodes that are highlighted, but only one part number
        const highlightedPartNumber = () => {
          if (highlightRoot) return [{
            hierarchy: '1',
            className: 'bg-blue-100',
            hideChildren: true
          }]
          if (highlighedNodes?.length) {
            return highlighedNodes.map(node => {
              return {
                hierarchy: node.hierarchy,
                className: 'bg-blue-100',
                hideChildren: true
              }
            })
          }
          return []
        }

        return {
          rootPart: root,
          subjectParts: addVersionWarnings(root, highlightedPartNumber()),
          initiallyExpandedNodes: expandedNodes()
        }
      })
  }, [orphanParts])

  const body = rootComponents.length === 0 ?
    <div>This part is not included in any root components</div> :
    <HierarchyContainer alignX={alignX} controllers={rootComponents} />

  return <>
    <div className='flex flex-col gap-3'>
      {body}
    </div>
  </>
}


const findAncestors = (fullHierarchy: RootPart['hierarchy'], childLevel: string) => {
  const ancestors = fullHierarchy
    .filter(p => {
      const partLevel = p.hierarchy.split('.');
      const descendentOfPartFinder = new RegExp('^' + partLevel.join('[.]') + '[.]')
      const isAncestor = descendentOfPartFinder.test(childLevel)
      return isAncestor
    })
  return ancestors
}
