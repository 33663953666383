import { MapperConfig, RowsExplodeConfig, RowsMergeConfig, RowsNormalizeConfig, RowsOrderedLevelConfig } from './mapperConfigs'
import type { BomRow } from './1-fileToRows'
import set from 'lodash.set'
import get from 'lodash.get'
import { renderTemplate } from './lib'

import { conditionMatch } from './3-standardizeBom'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import pick from 'lodash.pick'

type Extra = {
  selectedProject: SelectedProject
}

const rowsToBom = (config: MapperConfig, input: BomRow[], extra: Extra) => {
  if (!config.rowsToBom) return input
  return config.rowsToBom.reduce((output, config) => {
    if (input.length === 0) return output

    if (config.type === 'MergeRows') {
      return mergeRows(output, config.config, extra)
    }
    if (config.type === 'NormalizeRows') {
      return normalizeRows(output, config.config, extra)
    }
    if (config.type === 'SubassemblyExplode') {
      return explodeSubAssembly(output, config)
    }
    if (config.type === 'FilterRemove') {
      return output.filter(row => !conditionMatch(config.config.conditions, row))
    }
    if (config.type === 'FilterKeep') {
      return output.filter(row => conditionMatch(config.config.conditions, row))
    }
    if (config.type === 'OrderedLevelHierarchy') {
      return orderedLevelHierarchy(output, config)
    }
    return output
  }, input)
}

const mergeRows = (input: BomRow[], config: RowsMergeConfig['config'], { selectedProject }: Extra) => {
  const rowsByCheckValue = input.reduce((rowsByCheckValue, row, i) => {
    const checkValue = renderTemplate(config.mergeOn, {
      row,
      project: selectedProject
    })
    return {
      ...rowsByCheckValue,
      [checkValue]: [
        ...(rowsByCheckValue[checkValue] || []),
        row
      ] as [BomRow, ...BomRow[]]
    }
  }, {} as Record<string, [BomRow, ...BomRow[]]>)

  return input.map(row => {
    const checkValue = renderTemplate(config.mergeOn, {
      row,
      project: selectedProject
    })
    const matchingGroup = rowsByCheckValue[checkValue]!
    const rowIsFirstMatch = matchingGroup[0].__originalIndex === row.__originalIndex

    if (!rowIsFirstMatch) return false

    return Object.entries(config.output).reduce((outputRow, [fieldName, outputValue]) => {
      return {
        ...outputRow,
        [fieldName]: renderTemplate(outputValue, {
          project: selectedProject,
          rows: matchingGroup
        })
      }
    }, row)
  }).filter(Boolean)
}

const normalizeRows = (input: BomRow[], config: RowsNormalizeConfig['config'], { selectedProject }: Extra) => {
  let rowsByCheckValue: Record<string, BomRow> = {}
  return input.map((row, i) => {
    const checkValue = renderTemplate(config.normalizeOn, {
      row,
      project: selectedProject
    })
    if (rowsByCheckValue[checkValue]) {
      return {
        ...row,
        ...pick(rowsByCheckValue[checkValue], config.normalizeColumns)
      }
    }
    rowsByCheckValue[checkValue] = row
    return row
  })
}

const orderedLevelHierarchy = (input: BomRow[], config: RowsOrderedLevelConfig) => {
  // level number 1 is defined as a child of the root
  let currentIncrementors: number[] = [1, 0]
  return input.map((row, i) => {
    const level = Number(
      renderTemplate(config.config.input, { row })
    ) - 1

    // went down a level
    if (currentIncrementors.length > level + 1) {
      currentIncrementors = currentIncrementors.slice(0, level + 1)
    } else if (currentIncrementors.length < (level + 1)) {
      // went up a level
      currentIncrementors = [...currentIncrementors, 0]
    }

    currentIncrementors[level]!++
    return {
      ...row,
      __hierarchy: currentIncrementors.join('.')
    }
  })
}

const explodeSubAssembly = (input: BomRow[], config: RowsExplodeConfig) => {
  const configInputs = config.config
  const emptyRow = Object.keys(input[0]!).reduce((empty, key) => {
    return {
      ...empty,
      [key]: ''
    }
  }, {} as BomRow)

  const byKeyGroups = input.reduce((output, bomRow) => {
    const keys = configInputs.map(c => bomRow[c.subassemblyKeyColumn]!)
    const groupRows = get(output, keys) || []
    return set(output, keys, [...groupRows, bomRow])
  }, {})

  // only support 2 level explodes for now, but there is commented
  // WIP below to support any level explodes
  return Object.entries(byKeyGroups).flatMap(([skidNumber, skid], i) => {
    const keyedColumn0 = configInputs[0]!.subassemblyKeyColumn
    const skidPart = {
      ...emptyRow,
      [keyedColumn0]: skidNumber,
      __hierarchy: `1.${i + 1}`,
      __explode_identifier: configInputs[0]!.explodeIdentifier
    }
    return [skidPart,
      ...Object.entries(skid)
        .flatMap(([lineNumber, lineParts], j) => {
          const keyedColumn1 = configInputs[1]!.subassemblyKeyColumn
          const linePart = {
            ...emptyRow,
            [keyedColumn0]: skidNumber,
            [keyedColumn1]: lineNumber,
            __hierarchy: `1.${i + 1}.${j + 1}`,
            __explode_identifier: configInputs[1]!.explodeIdentifier
          }
          // @ts-ignore
          const partRows = lineParts.map((p, k) => {
            return {
              ...p,
              __hierarchy: `1.${i + 1}.${j + 1}.${k + 1}`,
            }
          })
          return [linePart, ...partRows]
        })
    ]
  })

  // WIP recursive function for any explode count
  // Just needs a way to be able to assign values to the assembly parts for their keys
  // which could work by adjusting the structure of byKeyGroups to be a flat object
  // where the keys are stringified.
  // function assignHierarchy (inputObject: Record<string, unknown>, level: number[], output: BomRow[]) {
  //   let i = 0
  //   for (const key in inputObject) {
  //     i++
  //     const nextLevel = [...level, i]

  //     const parentKeyValues = configInputs.reduce((parentKeyValues, c, n) => {
  //       if (n > level.length - 1) return parentKeyValues
  //       return {
  //         ...parentKeyValues,
  //         [c.subassemblyKeyColumn]:
  //       }
  //     }, {})
  //     const parentPart = {
  //       ...emptyRow,
  //       // [keyedColumn0]: skidNumber,
  //       __hierarchy: nextLevel.join('.'),
  //       __explode_identifier: configInputs[level.length - 1]!.explodeIdentifier
  //     }
  //     output = [...output, parentPart]

  //     const entry = inputObject[key]
  //     if (Array.isArray(entry)) {
  //       output = [...output, ...entry.map((row, j) => {
  //         return { ...row, __hierarchy: [...nextLevel, j + 1].join('.') }
  //       })]
  //     } else {
  //       output = [...output, ...assignHierarchy(entry as Record<string, unknown>, nextLevel, output)]
  //     }
  //   }
  //   return output
  // }
}

export default rowsToBom
