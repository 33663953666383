import { useState, Fragment, createContext, useContext } from 'react';
import type {
  PartHistoryQuery,
  PartHistoryQueryVariables,
  RecursiveForkQuery,
  RecursiveForkQueryVariables,
} from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { useLazyQuery } from '@apollo/client'
import { routes, navigate, useParams, Link } from '@redwoodjs/router';
import { AppLink } from 'src/lib/routing'
import * as ListBox from '../ListBox';
import { ChevronLeftIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { prettyDateTime } from 'src/lib/formatters'
import Button from '../Button';

export const QUERY = gql`
  query PartHistoryQuery($partNumber: String!) {
    partProto(partNumber: $partNumber) {
      partNumber
      instances {
        changeMessage
        transitionPlan
        version
        branch
        publishId
        createdAt
        inChangeOrder {
          number
          name
          appliedAt
        }
      }
      forkFrom {
        partNumber
        version
      }
    }
  }
`

export const RECURSIVE_FORK_QUERY = gql`
  query RecursiveForkQuery($partNumber: String!) {
    partProto(partNumber: $partNumber) {
      partNumber
      instances {
        changeMessage
        transitionPlan
        version
        branch
        publishId
        createdAt
        inChangeOrder {
          number
          name
          appliedAt
        }
      }
      forkFrom {
        partNumber
        version
      }
    }
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

type InstanceType = CellSuccessProps<PartHistoryQuery, PartHistoryQueryVariables>['partProto']['instances'][number]

export const Success = (props: CellSuccessProps<PartHistoryQuery, PartHistoryQueryVariables>) => {
  return <PartHistory {...props} />
}

const PartHistory = ({
  partProto,
}: Omit<CellSuccessProps<PartHistoryQuery, PartHistoryQueryVariables>, 'currentOrg'>) => {
  const orgId = useParams().orgId!
  if (!partProto) throw new Error('No part found')
  const { partNumber, instances } = partProto;

  const liveInstances = instances.filter(p => {
    // latest version, not sure what happens when added to a change order? maybe new version
    // if (!p.inChangeOrder) return true

    // main branch
    return p.branch === -1
  })
  liveInstances.sort((a, b) => {
    // those without publish ID will be filtered anyway
    return b.publishId! - a.publishId!
  })

  const [nextFork, setNextFork] = useState(partProto.forkFrom)
  const [stitchedHistory, setStitchedHistory] = useState<NonNullable<RecursiveForkQuery['partProto']>[]>([])

  const [fetchNextBatch, forkQueryResults] = useLazyQuery<RecursiveForkQuery, RecursiveForkQueryVariables>(RECURSIVE_FORK_QUERY)
  const handleFetchForkHistory = async () => {
    const variables = { partNumber: nextFork!.partNumber, }

    const result = await fetchNextBatch({variables})

    if (result.data) {
      const forkProto = result.data.partProto;
      const previousFork = nextFork;
      const newFork = forkProto?.forkFrom
      setNextFork(newFork)

      let liveForkInstances = forkProto?.instances.filter(p => p.branch === -1)
      if (liveForkInstances?.length && forkProto) {
        liveForkInstances.sort((a, b) => b.publishId! - a.publishId!)

        const startIndex = liveForkInstances.findIndex(i => i.version === previousFork!.version)

        liveForkInstances = liveForkInstances.slice(startIndex)

        setStitchedHistory([...stitchedHistory, {
          ...forkProto,
          instances: liveForkInstances
        }])
      }
    }
  }

  const compareOptions: (InstanceType & { partNumber: string })[] = liveInstances
    .map(i => ({ ...i, partNumber }))
    .concat(
      stitchedHistory.flatMap(s => s.instances.map(i => ({ ...i, partNumber: s.partNumber })))
    )

  const handleCompareVersion = (versionA: string, partNumberA: string) => (versionB: string, partNumberB: string) => {
    const instanceA = compareOptions.find(i => i.partNumber === partNumberA && i.version === versionA)!
    const instanceB = compareOptions.find(i => i.partNumber === partNumberB && i.version === versionB)!

    const laterInstance = instanceA.createdAt > instanceB.createdAt ? instanceA : instanceB;
    const earlierInstance = instanceA.createdAt > instanceB.createdAt ? instanceB : instanceA;

    navigate(routes.partCompare({
      orgId,
      basePartNumber: earlierInstance.partNumber,
      baseVersion: earlierInstance.version,
      incomingPartNumber: laterInstance.partNumber,
      incomingVersion: laterInstance.version,
    }))
  }


  return (<>
    <div className='flex flex-col gap-2 pt-12'>
      <div className='text-xl pb-10 flex justify-between items-center'>
        <AppLink
            className='cursor-pointer flex items-center'
            to={routes.part({ orgId, partNumber  })}>
              <ChevronLeftIcon className='h-6 w-6 text-gray-500'/><div>Back</div>
        </AppLink>
        <div className=''>Part #{partNumber} History</div>
      </div>
      <div className='flex gap-6 flex-col'>
        {liveInstances.map(i =>
          <HistoryPanel
            partNumber={partNumber}
            version={i.version}
            changeMessage={i.changeMessage}
            transitionPlan={i.transitionPlan}
            inChangeOrder={i.inChangeOrder}
            forkFrom={partProto.forkFrom}
            createdAt={i.createdAt}
            compareOptions={compareOptions.filter(o => !(o.partNumber === partNumber && o.version === i.version))}
            handleCompareVersion={handleCompareVersion(i.version, partNumber)} />
        )}
        {stitchedHistory.map(forkProto =>
          <Fragment key={`fork${forkProto.partNumber}`}>
            <li className='flex gap-1 pt-2 pb-1 pl-5 pr-9 text-xs text-gray-600 h-10 items-center'>
              Fork from #{forkProto.partNumber}
            </li>
            {forkProto.instances.map(i =>
              <HistoryPanel
                partNumber={forkProto.partNumber}
                showPartNumber={true}
                version={i.version}
                changeMessage={i.changeMessage}
                transitionPlan={i.transitionPlan}
                inChangeOrder={i.inChangeOrder}
                forkFrom={forkProto.forkFrom}
                createdAt={i.createdAt}
                compareOptions={compareOptions.filter(o => !(o.partNumber === forkProto.partNumber && o.version === i.version))}
                handleCompareVersion={handleCompareVersion(i.version, forkProto.partNumber)} />
            )}
          </Fragment>
        )}
        {nextFork &&
          <div className='flex gap-1 flex items-center h-10 w-full'>
            <div className='flex gap-1 pt-2 pb-1 pl-5 pr-9 text-xs text-gray-600 grow text-nowrap'>
              Fork from #{nextFork.partNumber}
            </div>
            <button type='button' onClick={handleFetchForkHistory} className='flex gap-1 items-center my-4 bg-gray-100 p-3 text-xs hover:bg-gray-200 rounded-md w-full justify-center' disabled={forkQueryResults.loading}>
              View History
              <ChevronDownIcon className='w-4' />
            </button>
          </div>
        }
      </div>
    </div>
    </>
  )
}

type HistoryPanelProps = {
  partNumber: string
  showPartNumber?: boolean
  version: string
  inChangeOrder?: {
    name: string
    number: number
    appliedAt?: string | null
  } | null
  forkFrom?: {} | null
  createdAt: string
  changeMessage?: string | null
  transitionPlan?: string | null
  compareOptions: { version: string, partNumber: string }[]
  handleCompareVersion: (version: string, partNumber: string) => void
}

const separator = '___'
const HistoryPanel = ({partNumber, showPartNumber = false, version, inChangeOrder, forkFrom, createdAt, changeMessage, transitionPlan, compareOptions, handleCompareVersion}: HistoryPanelProps) => {
  const orgId = useParams().orgId!

  const handleSelect = (joinedPart: string) => {
    handleCompareVersion(...joinedPart.split(separator) as [string, string])
  }

  return (
    <div key={version} className='rounded-xl border border-gray-150 p-6 flex'>
      <div  className='flex flex-col gap-2 flex-1'>
        <div className='flex gap-6 flex-col'>
          <div className='flex gap-2'>
            <div className='font-medium'>
              {showPartNumber &&
                <span>
                  #<span className='font-bold'>{partNumber}</span>{' '}
                </span>
              }
              Version {version}
            </div>
            <div>-</div>
            <div>
              {inChangeOrder ?
                <div className='flex gap-2'>
                  <div className='font-medium'>Change Order</div>
                  <AppLink
                    className='underline text-blue-700 cursor-pointer'
                    to={routes.changeOrder({ orgId, orderNumber: inChangeOrder.number  })}>
                    #{inChangeOrder.number} {inChangeOrder.name}
                  </AppLink>
                </div> :
                <div className='text-gray-500 italic'>
                  {forkFrom ? 'Created via fork' : 'Created without change order'}
                </div>
              }
            </div>
          </div>
          {inChangeOrder &&
            <div className='text-sm flex gap-4 flex-col'>
              <div className='flex flex-col gap-1'>
                <div className='text-gray-900 font-medium'>Change Message</div>
                <div className='text-gray-700 whitespace-pre-line'>
                  {changeMessage ?
                    <div>{changeMessage}</div> :
                    <div className='text-gray-500'>No change message</div>
                  }
                </div>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='text-gray-900 font-medium'>Transition Plan</div>
                <div className='text-gray-700 whitespace-pre-line'>
                  {transitionPlan ?
                    <div>{transitionPlan}</div> :
                    <div className='text-gray-500'>No transition plan</div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className='flex flex-col items-end gap-4'>
        <div>
          {inChangeOrder ?
            <div className='text-gray-500 italic'>
              <span>Applied on </span>
              <span className='font-medium text-gray-700'>
                {prettyDateTime(new Date(inChangeOrder.appliedAt!))}
              </span>
            </div> :
           forkFrom ?
            <div className='text-gray-500 italic'>
              <span>Created at </span>
              <span className='font-medium text-gray-700'>
                {prettyDateTime(new Date(createdAt))}
              </span>
            </div> :
            <div className='italic text-gray-500'>No change order</div>
          }
        </div>
        <ListBox.ListBox value='' disabled={compareOptions.length === 0} onChange={handleSelect}>
          {({ open, disabled }) => (<>
            <div className="relative text-sm">
              <ListBox.Button className='' displayValue={`Compare to`} disabled={disabled} data-testid={`compareTo${partNumber}~${version}`} />
              <ListBox.Options open={open}>
                {compareOptions.map(otherInstance =>
                  <ListBox.Option display={`${partNumber !== otherInstance.partNumber ? `#${otherInstance.partNumber} ` : ''} v${otherInstance.version}`}
                    value={otherInstance.version + separator + otherInstance.partNumber}
                    key={otherInstance.version + separator + otherInstance.partNumber} />
                )}
              </ListBox.Options>
            </div>
          </>)}
        </ListBox.ListBox>
      </div>
    </div>
  )
}
