import { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import type {
  CreateChangeOrderWithMultiplePartsCellQuery,
  CreateChangeOrderWithMultiplePartsCellQueryVariables,
} from 'types/graphql'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'

import CreateChangeOrder from '../CreateChangeOrderCell/CreateChangeOrder'

export const QUERY = gql`
  query CreateChangeOrderWithMultiplePartsCellQuery($partNumbers: [String!]!) {
    partProtos(partNumbers: $partNumbers) {
      partNumber
      category {
        id
        name
      }
      currentVersion {
        name
        version
        publishId
        name
        summary
        cadRev
      }
      owner {
        name
      }
    }
  }
`

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Success = ({ partProtos }: CellSuccessProps<CreateChangeOrderWithMultiplePartsCellQuery>) => {
  return <CreateChangeOrder withParts={partProtos}/>
}
