import { MetaTags } from '@redwoodjs/web'
import { useParams } from '@redwoodjs/router'
import ChangeOrderCell from 'src/components/ChangeOrderCell'
import ChangeOrderChangesCell from 'src/components/ChangeOrderChangesCell'

const ChangeOrderPage = () => {
  const { orderNumber, tab, orgId } = useParams()
  const selectedTab = tab || 'activity'

  return (
    <>
      <MetaTags title="ChangeOrder" description="ChangeOrder page" />
      <ChangeOrderCell orgId={orgId!} tab={selectedTab} orderNumber={orderNumber as unknown as number}>
        {selectedTab === 'activity' ?
          null :
          <ChangesTab changeOrderNumber={orderNumber as unknown as number} />
        }
      </ChangeOrderCell>
    </>
  )
}

const ChangesTab = ({ changeOrderNumber }: { changeOrderNumber: number }) => {
  return (
    <div>
      <ChangeOrderChangesCell orderNumber={changeOrderNumber} />
    </div>
  )
}

export default ChangeOrderPage
