import { useResolvedLifeCycle } from "src/lib/lifecycle"
import * as Tooltip from "src/components/ToolTip"

const colors = {
  Hidden: 'opacity-0 hidden',
  Gray: 'bg-[rgb(245,245,245)] border-gray-50',
  Purple: 'bg-[rgb(248,95,249)] border-purple-400',
  Blue: 'bg-[rgb(46,228,255)] border-blue-400',
  Green: 'bg-[rgb(116,232,149)] border-green-500',
  Red: 'bg-[rgb(255,78,78)] border-red-600'
} as const

const sizes = {
  sm: 'w-2 h-2',
  md: 'w-3 h-3'
} as const

type StatusProps = {
  lifeCycle: string | undefined | null
  size?: keyof typeof sizes
  className?: string
  alignTooltip?: 'center' | 'left' | 'right' | 'leftHorizontal'
}

export const StatusDot: React.FC<StatusProps> = ({ lifeCycle, size, className = '', alignTooltip }) => {
  const state = useResolvedLifeCycle(lifeCycle)
  const colorClass = colors[state.color]
  const sizeClass = size ? sizes[size] : sizes.md
  return <Tooltip.Container className={`h-fit ${className}`}>
    <div className={`border rounded-xl group ${sizeClass} ${colorClass}`} tabIndex={0}>
      <Tooltip.Message className="group-focus:visible group-focus:z-50" position={alignTooltip || 'center'}>
        Lifecycle stage: {state.name}
      </Tooltip.Message>
    </div>
  </Tooltip.Container>
}
