import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

const mapperConfig: MapperConfig = {
  orgIds: ['noble-demo'],
  name: 'Noble',
  rootCategoryId: '5',
  Logo: DocumentIcon,
  rootIsTopLevel: true,
  importOutputFields: {
    metadata: ['color', 'material', 'oldPartNumber']
  },
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        color: {
          type: 'simple',
          value: '{{row.color}}'
        },
        material: {
          type: 'simple',
          value: '{{row.material}}'
        },
        oldPartNumber: {
          type: 'simple',
          value: '{{row.old_part_number_for_ref}}'
        }
      },
      hierarchy: {
        value: '1.{{row.item_no}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.current_part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.rev}}',
        type: 'simple'
      },
      categoryId: [{
        conditions: [{
          type: 'regex',
          column: 'current_part_number',
          value: 'N\\d{4}-\\d{4}'
        }],
        onMatch: {
          type: 'regex',
          input: '{{row.current_part_number}}',
          regex: 'N\\d{4}-(\\d)',
          value: '{{match.0}}',
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'other'
        },
      }],
      isOffTheShelf: {
        value: 'true',
        type: 'simple'
      },
      name: {
        value: '{{row.part_name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.qty}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export default mapperConfig
