import { Switch as HeadlessSwitch } from '@headlessui/react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}


type SwitchProps = {
  accessibleDescription: string
  onChange: (v: boolean) => void
  enabled: boolean
  disabled?: boolean
}
const Switch: React.FC<SwitchProps> = ({ enabled, onChange, accessibleDescription, disabled }) => {
  const enabledClass = disabled ? 'bg-brand-200' : 'bg-brand-500'
  return (
    <HeadlessSwitch
      disabled={disabled}
      checked={enabled}
      onChange={onChange}
      className={classNames(
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        enabled ? enabledClass : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">{accessibleDescription}</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </HeadlessSwitch>
  )
}

export default Switch
