import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ChangeOrderSelectCell, { SelectedChangeOrder } from 'src/components/ChangeOrderSelectCell'
import Button from 'src/components/Button'
import { ConditionalModal } from 'src/components/Modal'

type ChangeOrderSelect = {
  changeOrderNumber: number
  createChangeOrder: false
} | {
  changeOrderNumber: null
  createChangeOrder: true
}

interface ChangeOrderSelectDialogProps {
  omit?: number[]
  onSelect: (select: ChangeOrderSelect) => void
  onCancel: () => void
  className?: string
}

const ChangeOrderSelectDialog: React.FC<ChangeOrderSelectDialogProps> = ({ omit, onSelect, onCancel, className }) => {
  const [selected, setSelected] = useState<ChangeOrderSelect | null>(null)

  const handleSelect = (changeOrder: SelectedChangeOrder) => {
    setSelected({
      changeOrderNumber: changeOrder.number,
      createChangeOrder: false
    })
  }
  const handleCreate = () => {
    setSelected({
      changeOrderNumber: null,
      createChangeOrder: true
    })
  }

  const handleConfirm = () => {
    if (!selected) throw new Error('No change order selected')
    onSelect(selected)
  }

  return (
    <ConditionalModal onClose={onCancel} className={twMerge('w-fit mt-10', className)}>
      <div className='flex flex-col gap-4 p-4'>
        <div className='text-sm'>
          Select a change order to add the parts to
        </div>
        <ChangeOrderSelectCell
          mode='select'
          testId='change-order-dialog:select'
          omit={omit}
          placeholder='Select Change Order'
          className=''
          onCreate={handleCreate}
          onSelect={handleSelect}
        />
        <div className='flex justify-between mt-4'>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant='primary'
            data-testid='change-order-dialog:confirm'
            onClick={handleConfirm} disabled={!selected}>Confirm</Button>
        </div>
      </div>
    </ConditionalModal>
  )
}

export default ChangeOrderSelectDialog