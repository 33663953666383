import React from 'react'
import humanize from 'humanize-string'

const MAX_STRING_LENGTH = 150

export const formatEnum = (values: string | string[] | null | undefined) => {
  let output = ''

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value))
    output = humanizedValues.join(', ')
  } else if (typeof values === 'string') {
    output = humanize(values)
  }

  return output
}

export const jsonDisplay = (obj: unknown) => {
  return (
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  )
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const jsonTruncate = (obj: unknown) => {
  return truncate(JSON.stringify(obj, null, 2))
}

export const timeTag = (dateTime?: string) => {
  let output: string | JSX.Element = ''

  if (dateTime) {
    output = (
      <time dateTime={dateTime} title={dateTime}>
        {new Date(dateTime).toUTCString()}
      </time>
    )
  }

  return output
}

export const checkboxInputTag = (checked: boolean) => {
  return <input type="checkbox" checked={checked} disabled />
}

export function prettyDate (date: Date) {
  // Determine the day suffix (st, nd, rd, th)
  const day = date.getDate();
  let suffix = 'th';
  if (day < 4 || day > 20) {
      suffix = ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';
  }

  // Check if the year is different from the current year
  const yearSuffix = date.getFullYear() !== new Date().getFullYear() ? `, ${date.getFullYear()}` : '';

  // Format the date
  const formatter = new Intl.DateTimeFormat('en-US', {
      weekday: 'long', // "Monday"
      month: 'short', // "Dec"
      day: 'numeric' // 12
  });

  const formattedDate = formatter.format(date);

  // Return the formatted string with day suffix and possibly the year
  return `${formattedDate}${suffix}${yearSuffix}`;
}

export function prettyDateTime (date: Date) {
  return `${prettyDate(date)} at ${date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
}
