import { useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import PartCell from "src/components/PartCell"

import { rwUrlDecode } from 'src/lib/util';

const PartPage = () => {
  // no links use branch but it's useful
  const { partNumber, version, branch } = useParams();
  const n = rwUrlDecode(decodeURI(partNumber));
  const v = version ? rwUrlDecode(decodeURI(version)) : undefined;
  return (
    <>
      <MetaTags title={"#" + n} description="Part page" />
      <PartCell partNumber={n} version={v} branch={Number(branch)} />
    </>
  )
}

export default PartPage
