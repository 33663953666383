import { useState } from 'react'

import { reportMutationError } from 'src/lib/reportError'
import {
  EditPartMutation,
  EditPartMutationVariables,
} from 'types/graphql'

import { EDIT_PART_MUTATION } from 'src/components/ChangeOrderChangesCell'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import * as Form from 'src/components/Form'
import { Info } from 'src/components/ToolTip'
import { useMutation } from '@redwoodjs/web'
import Button, { EditButton } from '../Button'
import { MetadataPanel } from 'src/components/Metadata'
import { PartChangePart, PartDiffSummary, EditMode } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import Switch from 'src/components/Switch'
import isEqual from 'lodash.isequal'

type OverviewChangesProps = {
  isExpanded?: boolean
  edit?: {
    changeOrderNumber: number
    partNumber: string
  }
  partDiff: PartDiffSummary
  changeOrderComplete: boolean
}
export const OverviewChanges: React.FC<OverviewChangesProps> = ({
  partDiff,
  isExpanded,
  edit,
  changeOrderComplete
}) => {
  const editModes: EditModes = changeOrderComplete ? {} : {
    metadata: 'edit',
    name: 'edit',
    summary: 'edit',
    cadRev: 'edit',
    isRoot: 'edit',
    isOffTheShelf: 'edit'
  }

  const { headPart, incomingPart } = partDiff

  if (!headPart) {
    return <div>
      <div
        className='text-sm font-semibold text-gray-900 h-8'>Overview</div>
      <Overview
        isExpanded
        editModes={editModes}
        part={incomingPart}
        edit={edit} />
      </div>
  }

  // TODO use changes in partDiff instead
  const changes = {
    name: headPart.name !== incomingPart.name,
    summary: headPart.summary !== incomingPart.summary,
    metadata: !isEqual(headPart.metadata, incomingPart.metadata),
    cadRev: headPart.cadRev !== incomingPart.cadRev,
    isRoot: headPart.isRoot !== incomingPart.isRoot,
    isOffTheShelf: headPart.isOffTheShelf !== incomingPart.isOffTheShelf,
  }

  const changedFields = Object.entries(changes).filter(([_k, v]) => v).map(([k, _v]) => k)
  if (headPart && changedFields.length === 0 && !isExpanded) return null
  return <div className='flex'>
      <div className='flex-1'>
        <div
        className='text-sm font-semibold text-gray-900 h-8'>Overview</div>
        <Overview
          compact
          onlyFields={isExpanded ? undefined : changedFields}
          part={headPart} />
      </div>
      <div className='border-l border-gray-200 mx-10'></div>
      <div className='flex-1'>
        <div
        className='text-sm font-semibold text-gray-900 h-8'>Overview</div>
        <Overview
          compact
          onlyFields={isExpanded ? undefined : changedFields}
          partDiff={partDiff}
          changes={changes}
          hideTitle
          headMetadata={headPart.metadata}
          editModes={editModes}
          part={incomingPart}
          edit={edit} />
      </div>
    </div>
}

type OverviewKeys = 'metadata' | 'name' | 'summary' | 'cadRev' | 'isRoot' | 'isOffTheShelf'
type ChangesKeys = Exclude<OverviewKeys, 'metadata'>
type EditModes = {
  [Key in OverviewKeys]?: EditMode;
};
type Changes = {
  [Key in ChangesKeys]?: boolean;
};
type OverviewPart = Pick<PartChangePart, OverviewKeys | 'version'>
type OverviewProps = {
  edit?: {
    changeOrderNumber: number
    partNumber: string
  }
  part: OverviewPart
  compact?: boolean
  changes?: Changes
  hideTitle?: boolean
  isExpanded?: boolean
  onlyFields?: string[]
  headMetadata?: OverviewPart['metadata']
  editModes?: EditModes
  partDiff?: PartDiffSummary
}
export const Overview: React.FC<OverviewProps> = ({
  edit,
  part,
  compact,
  editModes,
  changes,
  onlyFields,
  headMetadata,
  partDiff,
}) => {
  editModes = editModes || {}
  changes = changes || {}

  const name = () => {
    if (onlyFields && !onlyFields.includes('name')) return null
    return <OverviewField
      editMode={editModes['name']}
      fieldName='name'
      label='Name'
      inputType={'input'}
      hasChange={changes.name}
      nextVersion={part.version}
      empty={`This part doesn't have a name`}
      edit={edit}
      value={part.name} />
  }

  const summary = () => {
    if (onlyFields && !onlyFields.includes('summary')) return null
    return <OverviewField
      editMode={editModes['summary']}
      fieldName='summary'
      label='Summary'
      inputType={'textarea'}
      rows={6}
      hasChange={changes.summary}
      nextVersion={part.version}
      empty={`This part doesn't have a summary`}
      edit={edit}
      value={part.summary} />
  }

  const topLevel = () => {
    if (onlyFields && !onlyFields.includes('isRoot')) return null
    return <BooleanField
      help='Top level assemblies are the highest hierarchical assembly for a product'
      editMode={editModes['isRoot']}
      fieldName='isRoot'
      edit={edit}
      label='Top Level Assembly?'
      hasChange={changes.isRoot}
      nextVersion={part.version}
      value={part.isRoot} />
  }

  const offTheShelf = () => {
    if (onlyFields && !onlyFields.includes('isOffTheShelf')) return null
    return <BooleanField
      help='Off the shelf parts have sources information associated'
      editMode={editModes['isOffTheShelf']}
      fieldName='isOffTheShelf'
      label='Off the shelf part?'
      hasChange={changes.isOffTheShelf}
      nextVersion={part.version}
      edit={edit}
      value={part.isOffTheShelf} />
  }

  const cadRev = () => {
    if (onlyFields && !onlyFields.includes('cadRev')) return null
    if (!part.cadRev && !changes.cadRev) return null
    return <OverviewField
      editMode={editModes['cadRev']}
      fieldName='cadRev'
      label='CAD Revision'
      inputType={'input'}
      hasChange={changes.cadRev}
      nextVersion={part.version}
      empty={`This part doesn't have a CAD revision`}
      edit={edit}
      value={part.cadRev} />
  }

  const metadata = () => {
    if (onlyFields && !onlyFields.includes('metadata')) return null
    const editInput = edit ? {
      ...edit,
      diff: partDiff?.fields.metadata,
      headMetadata
    } : undefined
    return <MetadataPanel
      edit={editInput}
      diff={partDiff?.fields.metadata}
      headMetadata={headMetadata}
      editMode={editModes['metadata']}
      part={part} />
  }

  const body = () => {
    if (compact) {
      return <div className='flex flex-col gap-4'>
        {name()}
        {summary()}
        {topLevel()}
        {offTheShelf()}
        {cadRev()}
        {metadata()}
      </div>
    }
    return <div className='flex gap-4'>
      {/* Left Overview */}
      <div className='flex flex-col flex-1 gap-4 py-3'>
        {name()}
        {summary()}
        {topLevel()}
        {offTheShelf()}
      </div>
      {/* Right Overview */}
      <div className='flex flex-col flex-1 gap-4 py-3'>
        {cadRev()}
        {metadata()}
      </div>
    </div>
  }

  return <div className='flex-1 flex flex-col'>
    {body()}
  </div>
}

type BooleanFieldProps = {
  help?: string
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  label: string
  nextVersion: string
  value: boolean
  fieldName: 'isRoot' | 'isOffTheShelf'
  editMode?: EditMode
  hasChange?: boolean
}

const BooleanField: React.FC<BooleanFieldProps> = ({
  edit,
  editMode,
  label,
  nextVersion,
  value,
  fieldName,
  hasChange,
  help
}) => {
  const [editPending, setEditPending] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(value);
  const [editPartMutation, { loading }] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)

  if (editMode === 'edit' && !edit) {
    throw new Error('No change order number to allow edit')
  }

  const saveEdit = async () => {
    if (!edit) {
      throw new Error('Cannot edit part in this context')
    }
    const variables = {
      partNumber: edit.partNumber,
      changeOrderNumber: edit.changeOrderNumber,
      version: nextVersion,
      [fieldName]: checked
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: edit.changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: edit.changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part ${fieldName}`
      })
    }
    setEditPending(false)
  }


  if (editPending !== false) {
    return <Form.Form className='flex flex-col gap-0.5 pr-5' onSubmit={saveEdit}>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-1 text-sm font-medium text-gray-500'>
          <div>{label}</div>
          {help ? <Info size={4} message={help} position='right' /> : null}
        </div>

        <Switch
          accessibleDescription={label}
          enabled={checked}
          onChange={enabled => setChecked(enabled)} />
      </div>
      <div className='flex gap-2 justify-end'>
        <Button disabled={loading} onClick={() => setEditPending(false)}>
          Cancel
        </Button>
        <Form.Submit disabled={loading} variant='primary'>
          Save
        </Form.Submit>
      </div>
    </Form.Form>
  }

  const color = hasChange ? 'bg-yellow-100 outline outline-2 outline-yellow-100' : ''
  return <div className='flex flex-col gap-0.5 pr-5'>
    <div className='flex items-center gap-1 text-sm font-medium text-gray-500'>
      <div>{label}</div>
      {editMode && <EditButton size={4} disabled={editMode === 'locked'} onClick={() => setEditPending(true)} testId={label}/>}
    </div>
    <div className='text-sm text-gray-800 flex gap-2 items-center'>
      <div className={color}>{value ? 'Yes' : 'No'}</div>
      {help ? <Info className='flex items-center' position='right' message={help} size={4} /> : null}
    </div>
  </div>
}


type OverviewFieldProps = {
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  label: string
  nextVersion: string
  empty: string
  value?: string | null
  fieldName: 'summary' | 'name' | 'changeMessage' | 'cadRev' | 'transitionPlan'
  inputType: 'textarea' | 'input'
  rows?: number
  editMode?: EditMode
  hasChange?: boolean
  className?: string
}

export const OverviewField: React.FC<OverviewFieldProps> = ({
  className,
  edit,
  editMode,
  label,
  nextVersion,
  empty,
  value: defaultValue,
  fieldName,
  rows,
  hasChange,
  inputType
}) => {
  const [value, setValue] = useState<string | undefined>(defaultValue || undefined)
  const [editing, setEditing] = useState<boolean>(false)
  const [editPartMutation, { loading, error }] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)

  if (editMode === 'edit' && !edit) {
    throw new Error('No change order number to allow edit')
  }

  const saveEdit = async () => {
    if (!edit) {
      throw new Error('Cannot edit part in this context')
    }
    const variables = {
      partNumber: edit.partNumber,
      changeOrderNumber: edit.changeOrderNumber!,
      version: nextVersion,
      [fieldName]: value
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: edit.changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: edit.changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part ${fieldName}`
      })
    }
    setEditing(false)
  }


  if (editing) {
    const input = inputType === 'textarea' ?
      <Form.TextArea rows={rows || 3} className='my-2' name={fieldName} value={value} onChange={e => setValue(e.target.value)} />
      : <Form.TextField className='my-2' name={fieldName} value={value} onChange={e => setValue(e.target.value)} />

    return <Form.Form className={`flex flex-col gap-0.5 pr-5 ${className}`} onSubmit={saveEdit}>
      <div className='flex items-center gap-1 text-sm font-medium text-gray-500'>
        <div>{label}</div>
      </div>
      {input}
      <div className='flex gap-2 justify-end'>
        <Button disabled={loading} onClick={() => {
          setEditing(false)
          setValue(defaultValue || undefined)
        }}>
          Cancel
        </Button>
        <Form.Submit disabled={loading} variant='primary' data-testid={`overview-save-${fieldName}`}>
          Save
        </Form.Submit>
      </div>
    </Form.Form>
  }

  const placeholder = <span className='italic font-light'>
    {empty}
  </span>
  const color = hasChange ? 'bg-yellow-100 outline outline-2 outline-yellow-100' : ''
  return <div className={`flex flex-col gap-0.5 pr-5 ${className}`}>
    <div className='flex items-center gap-1 text-sm font-medium text-gray-500'>
      <div>{label}</div>
      {editMode && <EditButton size={4} disabled={editMode === 'locked'} onClick={() => setEditing(true)} testId={label} />}
    </div>
    <div className='text-sm text-gray-800'>
      <div className={`${color} whitespace-pre-line`} data-testid={`overview-show-${fieldName}`}>{value ? value : placeholder}</div>
    </div>
  </div>
}
