import { GraphQLError } from 'graphql';
import * as Sentry from "@sentry/react";

type MutationErrorInput = {
  errors?: readonly GraphQLError[]
  variables: Record<string, unknown>
  message: string
}
export const reportMutationError = (context: MutationErrorInput) => {
  console.error('GraphQL Mutation errors', context)
  let eventId: string
  Sentry.withScope(scope => {
    scope.setTag('kind', 'gqlmutation');
    scope.setExtra('errors', context.errors);
    scope.setExtra('variables', context.variables);
    eventId = Sentry.captureException(new Error(context.message))
  })
  return eventId!
}

export const reportGenericError = (error: unknown) => {
  console.error("Generic Error", error)
  let eventId: string
  Sentry.withScope(scope => {
    scope.setTag('kind', 'generic')
    eventId = Sentry.captureException(error)
  })
  return eventId!
}
