import {
  ChangeOrderChangesQuery,
  ChangeOrderChangesQueryVariables,
  Maybe,
} from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'

import { useState, useEffect } from 'react'

import { CHANGE_ORDER_CHANGES_QUERY } from 'src/lib/queries'

import calculateAllChanges, { PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { resolveMetadata } from 'src/lib/metadata'
import { DocumentTextIcon, ArrowDownTrayIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { CsvLink, ArtifactsBundle, csvConfig } from 'src/components/PartCell/ExportDialog'
import ExcelJS, { Fill, Borders } from 'exceljs'
import Button from 'src/components/Button'
import LoadingSpinner from 'src/components/Loading'
import { generateCsv } from "export-to-csv";
import { useLifeCycleStages, resolveLifeCycle, LifeCycleStages } from 'src/lib/lifecycle'


export const QUERY = CHANGE_ORDER_CHANGES_QUERY

type ChangeOrderChanges = CellSuccessProps<ChangeOrderChangesQuery, ChangeOrderChangesQueryVariables>;
export const Loading = () => <div className='flex justify-center'> <LoadingSpinner/> </div>
import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

//not sure why cell is getting exported wrong, requiring these params
export const Success = ({ onComplete, ...props }: Partial<ChangeOrderChanges> & { onComplete: () => void }) => {
  const lifeCycleStages = useLifeCycleStages()
  const changeOrder = props.changeOrder!
  const currentOrg = props.currentOrg!
  const sortedParts = ([...changeOrder.includedParts]).sort((a, b) => {
    if (a.isRoot && !b.isRoot) return -1
    if (!a.isRoot && b.isRoot) return 1
    if (a.dependencies.length !== b.dependencies.length) {
      return a.dependencies.length > b.dependencies.length ? -1 : 1
    }
    const aPartNumber = a.proto.partNumber
    const bPartNumber = b.proto.partNumber
    return aPartNumber > bPartNumber ? 1 : -1
  })
  const parts = calculateAllChanges(currentOrg, sortedParts)
  const changedParts = parts.filter(change => change.type !== 'none')
  if (!changedParts.length) {
    return <div>
      <div className='flex'>
      <ExclamationCircleIcon className='w-6 mr-3 text-yellow-400'/>
      <div>There are no changes in the ECO</div>
      </div>
      <div className='flex gap-2 mt-4'>
        <Button type='button' onClick={() => onComplete()} >Back</Button>
      </div>
    </div>
  }

  const [headers, ...table] = tabularizeChangeOrder({lifeCycleStages, parts, changeOrder, currentOrg})

  const fileName = currentOrg.name + '_ChangeOrder_#' + changeOrder.number
  const xlsxFileName = fileName + '.xlsx'
  const csvFileName = fileName + '.csv'

  const [sheet, setSheet] = useState<Blob | undefined>()

  const createSheet = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Changes');

    ws.columns = [
      { header: getValue(headers!.cells[0]), width: 30 },
      { header: getValue(headers!.cells[1]), width: 30 },
      { header: getValue(headers!.cells[2]), width: 30 },
      { header: getValue(headers!.cells[3]), width: 30 },
    ];
    ws.getCell('A1').font = { bold: true }
    ws.getCell('B1').font = { bold: true }
    ws.getCell('C1').font = { bold: true }
    ws.getCell('D1').font = { bold: true }

    let idx = 2;
    for (const row of table) {
      const rowFill = getStyle(row)
      const border = getBorders(row)

      let cell: Cell
      let exCell: ExcelJS.Cell
      let fill: Fill | undefined

      cell = row.cells[0]
      exCell = ws.getCell('A' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[1]
      exCell = ws.getCell('B' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[2]
      exCell = ws.getCell('C' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[3]
      exCell = ws.getCell('D' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      idx++;
    }

    const buffer = await wb.xlsx.writeBuffer()

    return new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }

  useEffect(() => {
    (async () => setSheet(await createSheet()))()
  }, [])

  if (!sheet) {
    return <Loading/>
  }

  const writeXlsx = async () => {
    let link = document.createElement('a');
    link.href = URL.createObjectURL(sheet);
    link.download = xlsxFileName
    link.click();
    URL.revokeObjectURL(link.href);
  }

  const csvBody = table.map(row => ({
    [getValue(headers!.cells[0])!]: getValue(row.cells[0]),
    [getValue(headers!.cells[1])!]: getValue(row.cells[1]),
    [getValue(headers!.cells[2])!]: getValue(row.cells[2]),
    [getValue(headers!.cells[3])!]: getValue(row.cells[3]),
  }))

  const files = ([
    {
      sourceContent: sheet,
      destination: xlsxFileName,
    },
    {
      sourceContent: String(generateCsv(csvConfig)(csvBody)),
      destination: csvFileName,
    },
  ] as ({source: string, destination: string} | {sourceContent: string, destination: string})[] ).concat(parts
    .flatMap(p =>
      p.fields.artifacts?.change
        ?.filter(c => c.changeType !== 'nothing')
        .flatMap(c =>
          c.changeType === 'deletion' ? [
            {
              source: c.artifact.file.url,
              destination: 'previousArtifacts/' + c.artifact.filename
            }
          ] :
          c.changeType === 'addition' ? [
            {
              source: c.artifact.file.url,
              destination: 'updatedArtifacts/' + c.artifact.filename
            }
          ] : [
            {
              source: p.headPart?.artifacts.find(a => a.filename === c.artifact.filename)?.file.url!,
              destination: 'previousArtifacts/' + c.artifact.filename
            },
            {
              source: c.artifact.file.url,
              destination: 'updatedArtifacts/' + c.artifact.filename
            }
          ]
        )
      ?? (p.type === 'create' ? p.incomingPart.artifacts.map(a => ({
        source: a.file.url,
        destination: 'updatedArtifacts/' + a.filename
      })) : [])
  ))

  return (
    <div className='flex flex-col gap-2 p-3'>
      <div className='font-medium mb-5 text-lg text-gray-900'>Export Change Order</div>

      <div className="text-sm text-gray-500">
        Formatted Excel
      </div>
      <button
        className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
        onClick={writeXlsx}>
          <DocumentTextIcon className='h-10 w-10 text-green-500'/>
          <div className='flex-1 text-left'>{xlsxFileName}</div>
          <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
      </button>

      <div className="text-sm text-gray-500">
        Raw CSV
      </div>
      <CsvLink body={csvBody} fileName={csvFileName}/>

      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Bundle (with Artifacts)
        </div>
        <ArtifactsBundle files={files} name={`${fileName}.zip`} align='left' />
      </div>

      <div className='flex gap-2 mt-4'>
        <Button type='button' onClick={() => onComplete()} >Cancel</Button>
      </div>
    </div>
  )
}

type Cell = {
  value?: string | null
  style: 'addition' | 'deletion' | 'change' | 'disabled' | 'header'
} | string | null | undefined
type Row = {
  cells: Cell[]
  style?: 'addition' | 'deletion'
  borderTop?: boolean
}

const getValue = (c: Cell): string | undefined => {
  if (c) {
    if (typeof c === 'string') return c;
    else return c.value || undefined
  }
  else {
    return
  }
}

const fillBase: Fill = {
  type: 'pattern',
  pattern: 'solid'
}
const getStyle = (c: Cell | Row): undefined | Fill => {
  if (c && typeof c !== 'string' && c.style) {
    if (c.style === 'addition') {
      return { ...fillBase, fgColor: { argb: 'FFBBF7D0' } }
    }
    else if (c.style === 'change') {
      return { ...fillBase, fgColor: { argb: 'FFFEF08A' } }
    }
    else if (c.style === 'deletion') {
      return { ...fillBase, fgColor: { argb: 'FFFECACA' } }
    }
  }
  return;
}
const getBorders = (r: Row): undefined | Partial<Borders> => {
  if (r.borderTop) {
    return {
      top: {
        style: 'thick',
        color: { argb:'FF000000' }
      }
    }
  }
  else return undefined;
}

const isStyledCell = (v: any): v is Extract<Cell, { 'style': string }> => {
  return v && typeof v !== 'string'
}

const changedCell = (value?: string | null): Cell => ({style: 'change', value})
const addedCell = (value?: string | null): Cell => ({style: 'addition', value})
const deletedCell = (value?: string | null): Cell => ({style: 'deletion', value})
const headerCell = (value?: string | null): Cell => ({style: 'header', value})

type TabularizedChangeOrderProps = {
  lifeCycleStages: LifeCycleStages | undefined,
  changeOrder: ChangeOrderChanges['changeOrder'],
  currentOrg: ChangeOrderChanges['currentOrg'],
  parts: PartDiffSummary[]
}

const tabularizeChangeOrder = (props: TabularizedChangeOrderProps) => {
  const { parts, currentOrg, lifeCycleStages } = props;
  const partHeaders: Row = { cells: ['Part Number', 'Changed', 'Before', 'After'].map(headerCell) }
  const mapped = parts.filter(p => p.type !== 'none').flatMap((p, idx) => {
    type Fields = (typeof p)['fields']
    const isCreate = p.type === 'create';
    const hasChange = (field: keyof (typeof p)['fields']) => {
      return isCreate || p.fields[field].hasChange
    }
    const rowStyle: Row['style'] = isCreate ? 'addition' : undefined;
    //const disabledCell: Cell = { style: 'disabled' }

    const pn = '#' + p.proto.partNumber;

    const rows: Cell[][] = [];

    const pushMaybe = (...input: (Cell[] | undefined | null | false | "")[]) => {
      for (const cell of input) {
        if (cell) {
          rows.push(cell)
        }
      }
    }

    type KeysWithStrings<T extends object> = {
      [K in keyof T]-?: T[K] extends Maybe<string> | undefined ? K : never
    }[keyof T];

    type StringFields = KeysWithStrings<NonNullable<typeof p.headPart>>

    const changeRow = (label: string, field: StringFields) => {
      const head = p.headPart?.[field];
      const incoming = p.incomingPart?.[field]
      //this is doing too much
      if (head || incoming)
        return [pn, label, head, changedCell(incoming)]
    }

    pushMaybe(
      Boolean(p.incomingPart.changeMessage) && [pn, 'Change Message', null, addedCell(p.incomingPart.changeMessage)],
      Boolean(p.incomingPart.transitionPlan) && [pn, 'Transition Plan', null, addedCell(p.incomingPart.transitionPlan)],
      changeRow('Version', 'version'),
      hasChange('name') && changeRow('Name', 'name'),
      hasChange('cadRev') && changeRow('Cad Rev', 'cadRev'),
      Boolean(hasChange('lifeCycle') && lifeCycleStages?.length) &&
        [pn, 'Lifecycle',
          resolveLifeCycle(lifeCycleStages, p.headPart?.lifeCycle).name ?? '',
          changedCell(resolveLifeCycle(lifeCycleStages, p.incomingPart?.lifeCycle).name ?? ''),
        ],
      hasChange('summary') && changeRow('Summary', 'summary'),
    )

    if (hasChange('metadata')) {
      const change: Fields['metadata']['change'] = p.fields.metadata.change || resolveMetadata(currentOrg.metadataSchema, p.incomingPart.metadata as any)
      if (change) {
        for (const m of change) {
          const headMeta = p.headPart?.metadata as Record<string, any> | undefined
          const e = m.entry;
          const { changeType } = m
          if (isCreate || changeType) {
            const styledCell = changeType === 'Change' ? changedCell : changeType === 'Remove' ? deletedCell : addedCell

            let oldValue: string, newValue: string;
            if (typeof e === 'object' && 'unit' in e) {
              const headEntry  = headMeta?.[m.key]
              oldValue = headEntry ? (headEntry.value.toString() + ' ' + headEntry.unit) : ''
              newValue = e.value.toString() + ' ' + e.unit
            }
            else {
              oldValue = headMeta?.[m.key]?.toString()
              newValue = e.toString()
            }

            if (changeType === 'Remove') {
              newValue = ''
            }

            rows.push([pn, `${m.displayName}`,
              oldValue, styledCell(newValue)])
          }
        }
      }
    }

    if (hasChange('sources')) {
      let change = p.fields.sources.change;

      console.log({change})

      const allNew = change.length === 0;
      if (allNew) change = p.incomingPart.sources;
      for (const s of change) {
        const { priority } = s;
        const sourceRenderIdx = priority + 1
        const headSource = p.headPart?.sources[priority]
        const incomingSource = p.incomingPart.sources[priority]

        const renderCurrency = (source: typeof incomingSource) => source ?
          source.priceCurrency + ' ' + source.price?.toString() : ''

        if (s.wholeSource || allNew) {
          if (s.wholeSource === 'removed') {
            pushMaybe(
              headSource?.distributor?.name && [pn, `[Src ${sourceRenderIdx}] Distributor`,
                headSource?.distributor?.name,
                deletedCell()
              ],
              headSource?.distributorSku && [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`,
                headSource?.distributorSku,
                deletedCell()
              ],
              headSource?.comment && [pn, `[Src ${sourceRenderIdx}] Comment`,
                headSource?.comment,
                deletedCell()
              ],
              typeof headSource?.leadTimeDays === 'number' && [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`,
                headSource?.leadTimeDays.toString(),
                deletedCell()
              ],
              typeof headSource?.price === 'number' && [pn, `[Src ${sourceRenderIdx}] Price`,
                renderCurrency(headSource),
                deletedCell()
              ],
              typeof headSource?.stock === 'number' && [pn, `[Src ${sourceRenderIdx}] Stock`,
                headSource.stock.toString(),
                deletedCell()
              ],
              headSource?.url && [pn, `[Src ${sourceRenderIdx}] Url`,
                headSource.url,
                deletedCell()
              ],
            )
          }
          else if (s.wholeSource === 'added' || allNew) {
            pushMaybe(
              incomingSource?.distributor?.name && [pn, `[Src ${sourceRenderIdx}] Distributor`, null,
                addedCell(incomingSource?.distributor?.name)
              ],
              incomingSource?.distributorSku && [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`, null,
                addedCell(incomingSource?.distributorSku)
              ],
              incomingSource?.comment && [pn, `[Src ${sourceRenderIdx}] Comment`, null,
                addedCell(incomingSource?.comment)
              ],
              typeof incomingSource?.leadTimeDays === 'number' && [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`, null,
                addedCell(incomingSource?.leadTimeDays.toString())
              ],
              typeof incomingSource?.price === 'number' && [pn, `[Src ${sourceRenderIdx}] Price`, null,
                addedCell(renderCurrency(incomingSource))
              ],
              typeof incomingSource?.stock === 'number' && [pn, `[Src ${sourceRenderIdx}] Stock`, null,
                addedCell(incomingSource.stock.toString())
              ],
              incomingSource?.url && [pn, `[Src ${sourceRenderIdx}] Url`, null,
                addedCell(incomingSource.url)
              ],
            )
          }
        }
        else {
          const styledCell = (c: NonNullable<(typeof s)['fields']>['comment']) =>
            c === 'added' ? addedCell : c === 'removed' ? deletedCell : changedCell
          pushMaybe(
            s.fields?.distributorId && [pn, `[Src ${sourceRenderIdx}] Distributor`,
              headSource?.distributor?.name,
              styledCell(s.fields.distributorId)(incomingSource?.distributor?.name)
            ],
            s.fields?.distributorSku && [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`,
              headSource?.distributorSku,
              styledCell(s.fields.distributorSku)(incomingSource?.distributorSku)
            ],
            s.fields?.comment && [pn, `[Src ${sourceRenderIdx}] Comment`,
              headSource?.comment,
              styledCell(s.fields.comment)(incomingSource?.comment)
            ],
            s.fields?.leadTimeDays && [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`,
              headSource?.leadTimeDays?.toString(),
              styledCell(s.fields.leadTimeDays)(incomingSource?.leadTimeDays?.toString())
            ],
            s.fields?.price && [pn, `[Src ${sourceRenderIdx}] Price`,
              headSource ? renderCurrency(headSource) : '',
              styledCell(s.fields.price)(renderCurrency(incomingSource))
            ],
            s.fields?.stock && [pn, `[Src ${sourceRenderIdx}] Stock`,
              headSource?.stock?.toString(),
              styledCell(s.fields.stock)(incomingSource?.stock?.toString())
            ],
            s.fields?.url && [pn, `[Src ${sourceRenderIdx}] Url`,
              headSource?.url,
              styledCell(s.fields.url)(incomingSource?.url)
            ],
          )
        }
      }
    }

    if (hasChange('artifacts')) {
      let { change } = p.fields.artifacts;

      if (!change) {
        change = p.incomingPart.artifacts.map((a, i) => ({
          changeType: 'addition',
          artifact: a,
          concreteIndex: i,
        }))
      }

      let aIdx = -1;
      for (const c of change) {
        aIdx++

        const { changeType } = c
        if (changeType === 'nothing') { continue; }

        const styledCell = changeType === 'edit' ? changedCell :
          changeType === 'deletion' ? deletedCell : addedCell

        const showBytes = (size: Maybe<number> | undefined): string =>
          (changeType === 'edit' && size) ? ` (${size} bytes)` : ''


        const head = p.headPart?.artifacts[aIdx]
        const first = (head?.filename ?? '') + showBytes(head?.file.size)
        const second = changeType === 'deletion' ? undefined :
          c.artifact.filename + showBytes(c.artifact.file.size)

        pushMaybe(
          [pn, `[Artifact ${aIdx + 1}]`,
            first,
            styledCell(second)]
        )
      }
    }

    if (hasChange('dependencies')) {
      const depChanges = p.fields.dependencies.change
      if (depChanges.length !== 0) {
        for (const d of depChanges) {
          if (d.wholeDependency) {
            if (d.wholeDependency === 'added') {
              const newDep = p.incomingPart.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
              pushMaybe(
                [pn, `[Dep] #${d.partNumber} Version`, null, addedCell(newDep.to.version)],
                [pn, `[Dep] #${d.partNumber} Quantity`, null, addedCell(newDep.quantity.toString() + ' ' + newDep.units)],
                (!!newDep.referenceDesignator) &&
                  [pn, `[Dep] #${d.partNumber} Reference`, null, addedCell(newDep.referenceDesignator)],
              )
            }
            else {
              const oldDep = p.headPart?.dependencies.find(dep => dep.to.partNumber === d.partNumber)!

              pushMaybe(
                [pn, `[Dep] #${d.partNumber} Version`, oldDep.to.version, deletedCell('')],
                [pn, `[Dep] #${d.partNumber} Quantity`, oldDep.quantity.toString() + ' ' + oldDep.units, deletedCell('')],
                (!!oldDep.referenceDesignator) &&
                  [pn, `[Dep] #${d.partNumber} Reference`, oldDep.referenceDesignator, deletedCell('')],
              )
            }
          }
          else {
            const oldDep = p.headPart?.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
            const newDep = p.incomingPart.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
            pushMaybe(
              d.version && [pn, `[Dep] #${d.partNumber} Version`, oldDep.to.version, changedCell(newDep.to.version)],
              d.quantity &&
                [pn, `[Dep] #${d.partNumber} Quantity`, oldDep.quantity.toString() + ' ' + oldDep.units, changedCell(newDep.quantity.toString() + ' ' + newDep.units)],
              d.referenceDesignator &&
                [pn, `[Dep] #${d.partNumber} Reference`, oldDep.referenceDesignator, changedCell(newDep.referenceDesignator)],
            )
          }
        }
      }
    }

    const makeRow = (cells: Cell[], rowIdx: number) => {
      const borderTop = rowIdx === 0 && idx !== 0;
      return { style: rowStyle, cells, borderTop }
    }

    return rows.map(makeRow) as Row[]
  })
  return [partHeaders, ...mapped];
}

/*
const ChangeOrderTable = (props: {table: ReturnType<typeof tabularizeChangeOrder>}) => {
  const getCellStyle = (row: Row, c?: Extract<Cell, { style: string }>) => {
    if (row.style && c?.style !== 'disabled') {
      if (row.style === 'addition') {
        return 'bg-green-200'
      }
      else if (row.style === 'deletion') {
        return 'bg-red-200'
      }
    }
    if (c?.style) {
      return (
        c.style === 'addition' ? 'bg-green-200' :
        c.style === 'deletion' ? 'bg-red-200' :
        c.style === 'change' ? 'bg-yellow-200' :
        c.style === 'disabled' ? 'bg-gray-50' :
        c.style === 'header' ? 'font-semibold' :
        ''
      )
    }
    return ''
  }

  return <>
    <div className='flex flex-col'>
      {props.table.map(row =>
        <div className='flex h-8'>
          {row.cells.map(cell => isStyledCell(cell) ?
            <div className={`basis-1/4 p-1 ${getCellStyle(row, cell)}`}>{cell.value}</div> :
            <div className={`basis-1/4 p-1 ${getCellStyle(row)}`}>
              {cell}
            </div>
          )}
        </div>
      )}
    </div>
  </>
}
  */
