import { useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import PartHistoryCell from "src/components/PartHistoryCell"

import { rwUrlDecode } from 'src/lib/util';

const PartPage = () => {
  const partNumber = useParams().partNumber!
  const n = rwUrlDecode(decodeURI(partNumber));
  return (
    <>
      <MetaTags title={"#" + n} description="Part history page" />
      <PartHistoryCell partNumber={n}/>
    </>
  )
}

export default PartPage
