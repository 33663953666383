import { Link } from '@redwoodjs/router'

import { retainedParams, useQueryState } from 'src/lib/hooks/useQueryState';

type LinkInput = Parameters<typeof Link>[0]
// Link for internal navigation that retains specified search params
export const AppLink = ({retainParams = true, ...props}: LinkInput & { retainParams?: boolean }) => {
  return <Link {...props}> {props.children} </Link>

  //disable this functionality for now
  /*
  const [queryState] = useQueryState();
  if (retainParams) {
    const parsedTo = new URL(props.to, window.location.origin);

    for (const p of retainedParams) {
      if (queryState[p] && !parsedTo.searchParams.has(p)) {
        parsedTo.searchParams.set(p, queryState[p])
      }
    }
    return <Link {...props} to={parsedTo.pathname + parsedTo.search}>
        {props.children}
      </Link>
  }
  else return <Link {...props}> {props.children} </Link>
  */
}

//TODO: do similar override for manual navigation
