import type {
  SuperUserOrganizationQuery,
  SetUserRoleMutation,
  SetUserRoleMutationVariables,
  UserRole,
  SetManualExportMapper,
  SetManualExportMapperVariables,
  UserInviteMutation,
  UserInviteMutationVariables,
  SetUserDefaultOrg,
  SetUserDefaultOrgVariables
} from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'

import { routes } from '@redwoodjs/router'
import { useState, useEffect } from 'react';

import { PlusIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/solid';
import { Button, TextField, Text, Heading, Dialog, Flex } from '@radix-ui/themes';
import * as Form from 'src/components/Form'
import * as ListBox from 'src/components/ListBox'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { prettyDateTime } from 'src/lib/formatters'
import z from 'zod'

export const QUERY = gql`
  query SuperUserOrganizationQuery ($organizationId: ID!) {
    organization (input: { id: $organizationId }) {
      id
      isDemo
      name
      manualExportMapper
      members {
        user {
          id
          name
          defaultOrgId
          email
          lastLoggedInView
          lastInvitationAt
          memberOf {
            orgId
          }
        }
        role
      }
      aggregationConfigs {
        name
        targetType {
          type
          unit
        }
        metadata {
          key
          multiplyByQuantity
        }
        sources {
          key
          multiplyByQuantity
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Empty = () => <div>Organization not found</div>

export const Failure = ({
  error,
}: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

// Define a new type for your cell's success props
interface SuperUserSwitcherSuccessProps extends CellSuccessProps<SuperUserOrganizationQuery> {
  organizationId?: string;
}

const CREATE_MEMBER = gql`
  mutation CreateMemberMutation($name: String!, $email: String!, $organizationId: ID!) {
    createUser(input: { name: $name, email: $email, organizationId: $organizationId }) {
      id
      name
      email
    }
  }
`

const NewUserDialog: React.FC<
  { organizationId: string }
> = ({ organizationId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFieldError, setUserFieldError] = useState('');
  const [emailFieldError, setEmailFieldError] = useState('');
  const [CreateMemberMutation] = useMutation(CREATE_MEMBER);
  const createMember = async (e: React.FormEvent<HTMLFormElement>) => {
    // The browser is handling validation via required attribute, and
    // the onInvalid event handler so we can assume everything is valid
    // here.
    e.preventDefault();
    await CreateMemberMutation({
      variables: {
        name: userName,
        email: userEmail,
        organizationId
      },
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    });
    setDialogOpen(false);
  }

  useEffect(() => {
    if (userName) {
      setUserFieldError('');
    }
    if (userEmail) {
      setEmailFieldError('');
    }
  }, [userName, userEmail])

  return <Dialog.Root open={dialogOpen}>
    <Dialog.Trigger>
      <Button variant="surface" color="gray" onClick={() => setDialogOpen(true)}>
        <PlusIcon className="h-4 w-4 text-gray"/>Create New User
      </Button>
    </Dialog.Trigger>

    <Dialog.Content style={{ maxWidth: 450 }}>
      <Dialog.Title>Create new user</Dialog.Title>
      <form onSubmit={createMember} className='mt-5'>
        <Flex direction="column" gap="5" >
            <Text as="div" size="1">The user will automatically be sent an invitation via email to join the organization</Text>
            <Flex direction="column" gap="1">
              <Text as="div" size="2" weight="bold">
                Name*
              </Text>
              <TextField.Input
                value={userName}
                onChange={e => setUserName(e.target.value)}
                autoComplete="off"
              />
            </Flex>
            <Flex direction="column" gap="2">
              <Text as="div" size="2" weight="bold">
                Email*
              </Text>
              <TextField.Input
                type="email"
                required
                value={userEmail}
                onInvalid={e => setEmailFieldError('User email is required')}
                onChange={e => setUserEmail(e.target.value)}
                placeholder={emailFieldError || "User email"}
                color={emailFieldError ? 'red' : undefined}
                variant={emailFieldError ? 'soft' : undefined}
                autoComplete="off"
              />
            </Flex>
        </Flex>

        <Flex gap="3" mt="6" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
          </Dialog.Close>
          <Button type='submit' className='cursor-pointer'>Create</Button>
        </Flex>
      </form>
    </Dialog.Content>
  </Dialog.Root>
}

const UPDATE_ROLE = gql`
  mutation SetUserRoleMutation($input: SetUserRoleInput!) {
    setUserRole(input: $input) {
      org {
        id
        members {
          id
          role
        }
      }
    }
  }
`

const SET_USER_DEFAULT_ORG = gql`
  mutation SetUserDefaultOrg($input: SetUserDefaultOrgInput!) {
    setUserDefaultOrg(input: $input) {
      id
    }
  }
`

type UserRoleUpdaterProps = {
  initialRole: UserRole
  defaultOrgId: string
  userId: string
  memberOrgIds: string[]
} & Omit<SetUserRoleMutationVariables['input'], 'role'>;
const UserRoleUpdater = ({ initialRole, defaultOrgId, memberOrgIds, ...props }: UserRoleUpdaterProps) => {
  console.log({ defaultOrgId })
  const [ updateRole ] = useMutation<SetUserRoleMutation, SetUserRoleMutationVariables>(UPDATE_ROLE)
  const [ setDefaultOrg ] = useMutation<SetUserDefaultOrg, SetUserDefaultOrgVariables>(SET_USER_DEFAULT_ORG)

  type FormData = { role: UserRole, defaultOrgId: string }
  const doMutation = (data: FormData): void => {
    console.log({ data })
    updateRole({
      variables: {
        input: {
          role: data.role,
          email: props.email,
          organizationId: props.organizationId
        }
      }
    })
    setDefaultOrg({
      variables: {
        input: {
          id: props.userId,
          orgId: data.defaultOrgId
        }
      }
    })
  }

  return <>
    <Form.Form<FormData> onSubmit={doMutation} className='flex gap-4 items-end'>
      <div className='flex flex-col gap-2'>
      <div className='font-medium'>Role in Org </div>
      <ListBox.HookedListBox name='role' defaultValue={initialRole}>
        <ListBox.UncontrolledButton className='py min-w-32'/>
        <ListBox.Options align='left'>
          {(['Admin', 'ReadWrite', 'ReadOnly'] as UserRole[]).map((role) => (
            <ListBox.Option key={role} className='py-3' value={role} display={role} />
          ))}
        </ListBox.Options>

      </ListBox.HookedListBox>
      </div>
      <div className='flex flex-col gap-2'>
      <div className='font-medium'>Default Org </div>
      <ListBox.HookedListBox name='defaultOrgId' defaultValue={defaultOrgId}>
        <ListBox.UncontrolledButton className='py min-w-32'/>
        <ListBox.Options align='left'>
          {(memberOrgIds).map((orgId) => (
            <ListBox.Option key={orgId} className='py-3' value={orgId} display={orgId} />
          ))}
        </ListBox.Options>

      </ListBox.HookedListBox>
      </div>
      <Button size={'2'}>Update User</Button>
    </Form.Form>
    </>
}

const SET_MANUAL_EXPORT_MAPPER = gql`
  mutation SetManualExportMapper($input: SetManualExportMapperInput!) {
    setManualExportMapper(input: $input) {
      id
      manualExportMapper
    }
  }
`

type ExportMapperUpdaterProps = {
  org: SuperUserOrganizationQuery['organization']
}
const ExportMapperUpdater = (props: ExportMapperUpdaterProps) => {
  const { org } = props;
  const [success, setSuccess] = useState(false)
  const [ updateExportMapper, { loading, error } ] =
    useMutation<SetManualExportMapper, SetManualExportMapperVariables>(SET_MANUAL_EXPORT_MAPPER)

  type FormData = {
    manualExportMapper: string
  }
  const handleSubmit = async (formData: FormData) => {
    setSuccess(false)
    await updateExportMapper({
      variables: {
        input: {
          id: org.id,
          manualExportMapper: formData.manualExportMapper
        }
      },
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    });
    setSuccess(true)
  }

  if (loading) {
    return <div className='mb-[2000px]'>
      <LoadingSpinner/>
    </div>
  }

  return <div className='mb-24 bg-gray-100 p-2'>
    <div className='text-bold'>
      Set Organization Export Mapper
    </div>
    <div className='text-sm'>
      Write in a JSON record of key value pairs that map the header (key) to a new value (value). Use "null" to remove. Example:
      <pre>{`
{
  "key": "value"
}
      `}</pre>
    </div>
    <Form.Form<FormData> onSubmit={handleSubmit} className='flex flex-col gap-2'>
      <Form.TextArea
        name='manualExportMapper'
        className='min-h-48'
        validation={{
          validate: {
            isValidJson: (value) => {
              //@ts-ignore
              if (value?.error === '__parseError__') {
                return 'Not valid JSON. Ensure the keys are wrapped in quotes (")'
              }
              else {
                for (const v in value) {
                  if (typeof v !== 'string' || typeof value[v] !== 'string') {
                    return 'JSON must be a Record<string, string>.'
                  }
                }
              }
            },
          },
          //valueAsJSON: true
          setValueAs: (raw: string) => {
            try {
              return JSON.parse(raw)
            }
            catch(e) {
              console.log(e)
              return {
                error: '__parseError__',
                e
              }
            }
          }
        }}
        defaultValue={JSON.stringify(org.manualExportMapper, null, 2)}
        autoComplete="off"/>
      <Form.FieldError name='manualExportMapper' chill></Form.FieldError>
      <Form.Submit>
        Submit
      </Form.Submit>
      {success && <div className='bg-green-200'>
        Success
      </div>}
      <Form.BasicFormError error={error}/>
    </Form.Form>
  </div>
}

const clientAggregationConfig = z.array(z.object({
  name: z.string(),
  targetType: z.object({
    type: z.enum(["Number", "Price", "Mass"]),
    unit: z.string().optional()
  }),
  metadata: z.array(z.object({
    key: z.string(),
    multiplyByQuantity: z.boolean()
  })),
  sources: z.array(z.object({
    key: z.string(),
    multiplyByQuantity: z.boolean()
  }))
}))


/*
type AggregationUpdaterProps = {
  org: SuperUserOrganizationQuery['organization']
}
const AggregationUpdater = (props: AggregationUpdaterProps) => {
  const { org } = props;
  const [success, setSuccess] = useState(false)
  const [ updateAggregationConfigs, { loading, error } ] =
    useMutation<SetAggregationConfigs, SetAggregationConfigsVariables>(SET_AGGREGATION_CONFIGS)

  type FormData = {
    aggregationConfigs: any
  }
  const handleSubmit = async (formData: FormData) => {
    setSuccess(false)
    await updateAggregationConfigs({
      variables: {
        input: {
          id: org.id,
          aggregationConfigs: formData.aggregationConfigs
        }
      },
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    });
    setSuccess(true)
  }

  if (loading) {
    return <div className='mb-[2000px]'>
      <LoadingSpinner/>
    </div>
  }

  return <div className='mb-24 bg-gray-100 p-2'>
    <div className='text-bold'>
      Set Aggregation Configs
    </div>
    <div className='text-sm'> </div>
    <Form.Form<FormData> onSubmit={handleSubmit} className='flex flex-col gap-2'>
      <Form.TextArea
        name='aggregationConfigs'
        className='min-h-48'
        validation={{
          validate: {
            isValidJson: (value) => {
              //@ts-ignore
              if (value?.error === '__parseError__') {
                return 'Not valid JSON. Ensure the keys are wrapped in quotes (")'
              }
              else {
                const attempt = clientAggregationConfig.safeParse(value)
                if (!attempt.success) {
                  return JSON.stringify(attempt.error, null, 2)
                }
              }
            },
          },
          //valueAsJSON: true
          setValueAs: (raw: string) => {
            try {
              return JSON.parse(raw)
            }
            catch(e) {
              console.log(e)
              return {
                error: '__parseError__',
                e
              }
            }
          }
        }}
        defaultValue={JSON.stringify(org.aggregationConfigs, null, 2)}
        autoComplete="off"/>
      <Form.FieldError name='aggregationConfigs' chill></Form.FieldError>
      <Form.Submit>
        Submit
      </Form.Submit>
      {success && <div className='bg-green-200'>
        Success
      </div>}
      <Form.BasicFormError error={error}/>
    </Form.Form>
  </div>
}
*/

export const USER_INVITE_MUTATION = gql`
  mutation UserInviteMutation ($email: String!) {
    sendUserInvitation (email: $email) {
      id
    }
  }
`

type MemberProps =
  SuperUserSwitcherSuccessProps['organization']['members'][number] &
  Pick<UserRoleUpdaterProps, 'organizationId'>

const Member: React.FC<MemberProps> = ({ user: { name,
  email,
  lastLoggedInView,
  lastInvitationAt,
  defaultOrgId,
  memberOf,
  id
}, role, organizationId }) => {
  const [sendInvite] = useMutation<UserInviteMutation, UserInviteMutationVariables>(USER_INVITE_MUTATION, {
    variables: {
      email
    },
    refetchQueries: [QUERY]
  })
  const lastView = lastLoggedInView ? prettyDateTime(new Date(lastLoggedInView)) : 'None'
  const lastInvite = lastInvitationAt ? prettyDateTime(new Date(lastInvitationAt)) : 'None'
  return <div className='flex gap-2 items-end text-sm border-b border-gray-300 py-4'>
    <div className='min-w-[600px] flex-1'>
      <div className='text-md font-bold'>Name: {name || 'None'}, Email: {email}</div>
      <div className='flex gap-2'>
        <div>Last view: {lastView}</div>
        <div>Last Invitation: {lastInvite || 'Not sent'}</div>
      </div>
    </div>
    <UserRoleUpdater
      userId={id}
      email={email}
      memberOrgIds={memberOf.map(m => m.orgId)}
      defaultOrgId={defaultOrgId}
      organizationId={organizationId}
      initialRole={role}/>
    <Button onClick={() => sendInvite()}>
      (Re)Send Invitation
    </Button>
  </div>
}

const RESET_DEMO_DATA = gql`
  mutation ResetDemoData($organizationId: ID!, $wipeOnly: Boolean!) {
    resetDemoData(input: { id: $organizationId, wipeOnly: $wipeOnly })
  }
`

export const Success = ({
  organization
}: SuperUserSwitcherSuccessProps) => {
  const [success, setSuccess] = useState(false)
  const [resetDemoData, { loading, error }] = useMutation(RESET_DEMO_DATA, {
    variables: {
      organizationId: organization.id
    },
    refetchQueries: [QUERY],
    awaitRefetchQueries: true
  });

  const doMutation = async (wipeOnly?: boolean) => {
    setSuccess(false)
    await resetDemoData({
      variables: {
        wipeOnly: Boolean(wipeOnly)
      }
    });
    setSuccess(true)
  }

  const actions = <div className='flex flex-col gap-2'>
    {organization.isDemo && <div>
      <Button variant="surface" color="gray" onClick={() => doMutation(true)}>
        Delete All Parts and Change Orders
      </Button>
      <Button className='ml-4' variant="surface" color="gray" onClick={() => doMutation()}>
        Delete and Reseed
      </Button>
      {loading && <div className='bg-gray-200'>
        Loading
      </div>}
      {success && <div className='bg-green-200'>
        Success
      </div>}
      <Form.BasicFormError error={error}/>
    </div> }
    <ExportMapperUpdater org={organization}/>
  </div>

  return <div className='p-10 flex flex-col space-y-4' key={organization.id}>
    <div>
      <Heading size="3" className="mb-2">Organization Name</Heading>
      <div>{organization.name}</div>
    </div>
    <div>
      <Heading size="3" className="mb-2">Organization URL</Heading>
      <div className='flex gap-3'>
        <a
          target='blank'
          className='text-blue-600'
          href={routes.home({ orgId: organization.id })}>{location.host + routes.home({ orgId: organization.id })}</a>
        <a className='text-blue-600' href={routes.home({ orgId: organization.id })}>
          <ArrowRightEndOnRectangleIcon className="mt-1 h-4 w-4 text-gray"/>
        </a>
      </div>
    </div>
    <div>
      <Heading size="3" className="mb-2">Demo</Heading>
      <div>{organization.isDemo ? 'Yes' : 'No'}</div>
    </div>
    <div>
      <Heading size="3" className="mb-2">Users</Heading>
      <div className="mb-2 flex flex-col gap-6">
        {organization.members.map(m =>
          <Member key={m.user.email} organizationId={organization.id} {...m}/>
        )}
      </div>
      <NewUserDialog organizationId={organization.id}/>
    </div>
    <div>
      <Heading size="3" className="mb-2">Actions</Heading>
      {actions}
    </div>
  </div>
}
