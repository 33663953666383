import { MetaTags } from '@redwoodjs/web'
import { routes, useParams, navigate } from '@redwoodjs/router'
import { useForm } from 'react-hook-form'
import type {
  CreateChangeOrderMutation,
  CreateChangeOrderMutationVariables,
  AddToNewChangeOrderMutation,
  AddToNewChangeOrderMutationVariables,
  LivePartProto,
  Part,
  User,
  PartCategory
} from 'types/graphql'

import PartSummary, { PartSummaryPart } from '../PartSummary'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { reportMutationError } from 'src/lib/reportError'
import { ButtonLink } from 'src/components/Button'
import { Form, Label, TextArea, TextField, Submit, FormError } from 'src/components/Form'

import { useMutation } from '@redwoodjs/web'

export const CREATE_CHANGE_ORDER = gql`
  mutation CreateChangeOrderMutation ($name: String!, $description: String!) {
    createChangeOrder(input: { name: $name, description: $description }) {
      name
      number
    }
  }
`

export const ADD_TO_NEW_CHANGE_ORDER_MUTATION = gql`
mutation AddToNewChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: $input) {
    partNumber
  }
}
`

export type WithPart = Pick<LivePartProto, 'partNumber'> & {
  currentVersion: Pick<Part, 'version' | 'name' | 'summary' | 'publishId'>
  owner: Pick<User, 'name'>
  category: Pick<PartCategory, 'id' | 'name'>
}

type CreateChangeOrderProps = {
  withPart?: WithPart
  withParts?: WithPart[]
}

const CreateChangeOrder: React.FC<CreateChangeOrderProps> = ({ withPart, withParts }) => {
  const [createChangeOrder, { loading, error }] = useMutation<
    CreateChangeOrderMutation,
    CreateChangeOrderMutationVariables
  >(CREATE_CHANGE_ORDER)

  const [addToNewChangeOrderMutation] = useMutation<AddToNewChangeOrderMutation, AddToNewChangeOrderMutationVariables>(ADD_TO_NEW_CHANGE_ORDER_MUTATION)

  const orgId = useParams().orgId!

  const formMethods = useForm<ChangeOrderFormData>()

  type ChangeOrderFormData = {
    description: string;
    changeOrderName: string;
  }
  const onCreateChangeOrder = async (changeOrderInput: ChangeOrderFormData) => {
    const { changeOrderName, description } = changeOrderInput
    const createChangeOrderVariables: CreateChangeOrderMutationVariables = {
      name: changeOrderName,
      description,
    }
    const { errors, data } = await createChangeOrder({
      variables: createChangeOrderVariables
    })

    if (errors || !data) {
      reportMutationError({
        errors,
        variables: createChangeOrderVariables,
        message: `Error creating change order`
      })
      return
    }

    if (!withPart && !withParts) {
      navigate(routes.changeOrder({
        orgId,
        orderNumber: data.createChangeOrder.number
      }))
      return
    }

    const input: AddToNewChangeOrderMutationVariables['input'] = withPart ? [{
      type: 'Push',
      partNumber: withPart.partNumber,
      version: calculateNextVersion(withPart.currentVersion!.version!),
    }] : withParts!.map(p => ({
      type: 'Push',
      partNumber: p.partNumber,
      version: calculateNextVersion(p.currentVersion!.version!),
    }))

    const variables: AddToNewChangeOrderMutationVariables = {
      changeOrderNumber: data.createChangeOrder.number,
      input
    }
    const { errors: addToErrors } = await addToNewChangeOrderMutation({
      variables: variables,
    })
    if (addToErrors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part${withParts ? 's' : ''} to change order`
      })
      return
    }
    navigate(routes.changeOrderTab({
      orgId,
      orderNumber: data.createChangeOrder.number,
      tab: 'changes'
    }))
  }

  const partSummary =
    withPart ? <div>
        <Label className='mb-2'>Included Parts</Label>
        <PartSummary part={withPart} notInteractive />
    </div> :
    withParts ? <div>
      <Label className='mb-2'>Included Parts</Label>
      {withParts.map(p =>
        <div key={p.partNumber} className='mb-2'>
          <PartSummary part={p} notInteractive />
        </div>
      )}
    </div> :
    null

  return (
    <>
      <MetaTags
        title="Create Change Order"
        description="Create Change Order page"
      />
      <div>
        <Form onSubmit={onCreateChangeOrder} formMethods={formMethods} className='flex flex-col gap-4 pt-10'>
          <div className='text-2xl'>
            Create New Change Order
          </div>
          <div>
            <Label htmlFor='changeOrderName'>Change Order Name</Label>
            <TextField
              name='changeOrderName'
              className='mt-1'
              validation={{ required: true }}
              placeholder='Select a name for the change order'/>
          </div>
          <div>
            <Label>Description</Label>
            <TextArea
              name='description'
              rows={5}
              className='mt-1'
              placeholder='Change order description'/>
          </div>
          {partSummary}
          <div className='flex gap-2 ml-auto mt-10'>
            <ButtonLink to={routes.changeOrders({orgId, state: 'draft'})}>
              Cancel
            </ButtonLink>
            <Submit writeOnly disabled={loading || !formMethods.formState.isValid} variant='primary'>
              Create Change Order
            </Submit>
          </div>
          {/* TODO: Much improvement needed here */}
          <FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-4 rounded" />
        </Form>
      </div>
    </>
  )
}

export default CreateChangeOrder
