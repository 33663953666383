import { MapperConfig } from './mapperConfigs'
import Mustache from 'mustache'
import { quantityUnits } from 'shared/types'
import type { ImportedPart } from "./3-standardizeBom"
import { AppContext } from '../appContext'
import {
  rValidPartNumber
} from 'shared/partNumbers'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import { UNCHANGED_FIELD } from './lib'

type Extra = {
  selectedProject: SelectedProject
  appContext: AppContext
}

// Does not escape and chars when they are read from rows
Mustache.escape = function(text) {return text;};

export type Invalid = {
  issues: { message: string }[]
  part: ImportedPart
  rowIndex: number
}

const validateBom: (config: MapperConfig, bom: ImportedPart[], extra: Extra) => Invalid[]
  = (config: MapperConfig, bom: ImportedPart[], extra: Extra) => {
  if (bom.length === 0) return []
  const categories = extra.appContext.partCategories
  return bom.map((part, index) => {
    const issues = []
    if (isNaN(part.quantity)) {
      issues.push({
        message: 'Quantity is invalid'
      })
    }
    if (!quantityUnits.includes(part.units)) {
      issues.push({
        message: `Quantity unit: "${part.units}" is invalid, must be one of ${units.quantity.map(q => `"${q}"`).join(', ')}`
      })
    }
    if (part.categoryId === '') {
      issues.push({
        message: `Part category ID is empty`
      })
    } else if (part.categoryId !== UNCHANGED_FIELD && !categories.some(c => c.id === part.categoryId)) {
      issues.push({
        message: `Part category with ID: ${part.categoryId} does not exist`
      })
    }

    if (part.partNumber === '') {
      issues.push({
        message: `Part number is empty`
      })
    } else if (!rValidPartNumber.test(part.partNumber)) {
      issues.push({
        message: `Part number is invalid`
      })
    }
    // TODO also test for the schema

    if (part.hierarchy === '') {
      issues.push({
        message: `Hierarchy is empty`
      })
    } else if (!/^(0|[1-9]\d*)(\.(0|[1-9]\d*))*$/.test(part.hierarchy)) {
      issues.push({
        message: `Hierarchy "${part.hierarchy}" is invalid`
      })
    }

    if (part.name === '') {
      issues.push({
        message: `Name is empty`
      })
    }

    return {
      part,
      issues,
      rowIndex: index
    }
  }).filter(i => i.issues.length > 0)
}

export default validateBom
