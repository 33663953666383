import { useState } from 'react'
import type {
  OrgSettingsLifecycleQuery,
  SetLifeCycleStagesMutation,
  SetLifeCycleStagesMutationVariables,
} from 'types/graphql'

import {
  CellSuccessProps,
  useMutation,
  //CellFailureProps,
  //TypedDocumentNode,
} from '@redwoodjs/web'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import * as Form from 'src/components/Form'
import { reportMutationError } from 'src/lib/reportError'

export const QUERY = //: TypedDocumentNode< FindOrgSettingsLifecycleQuery, FindOrgSettingsLifecycleQueryVariables >
  gql`
    query OrgSettingsLifecycleQuery {
      currentOrg {
        lifeCycleStages {
          key
          name
          color
        }
      }
    }
  `

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner/></div>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

export const SET_LIFECYCLE_STAGES_MUTATION = gql`
mutation SetLifeCycleStagesMutation ($input: SetLifeCycleStagesInput!) {
  setLifeCycleStages(input: $input)
}
`

type SuccessProps = CellSuccessProps<OrgSettingsLifecycleQuery>
export const Success = ({
  currentOrg,
}: SuccessProps) => {
  const [setStages, { loading, error }] = useMutation<SetLifeCycleStagesMutation, SetLifeCycleStagesMutationVariables>(SET_LIFECYCLE_STAGES_MUTATION)
  const [success, setSuccess] = useState<boolean>(false)

  const stages = currentOrg.lifeCycleStages.map(({__typename, ...rest}) => rest)

  type FormData = {
    lifeCycleStages: any
  }
  const handleSubmit = async (data: FormData) => {
    const variables = {
      input: {
        lifeCycleStages: data.lifeCycleStages
      }
    }
    const { errors } = await setStages({
      variables,
      onCompleted() {
        setSuccess(true)
        setTimeout(() => {
          location.reload()
        }, 1000)
      }
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error editing lifeCycleStages`
      })
    }
  }

  return (
    <div className='pb-12 flex flex-col gap-5 mb-12'>
      <div className='bold text-2xl'>
        Lifecycle
      </div>
      <div className='text-sm -mt-1'>Edit the lifecycle stages that are available for all parts.</div>
      <div>
        <Form.Form<FormData> onSubmit={handleSubmit} className='flex flex-col gap-2'>
          <Form.TextArea
            name='lifeCycleStages'
            className='min-h-[550px]'
            validation={{
              validate: {
                isValidJson: (value) => {
                  //@ts-ignore
                  if (value?.error === '__parseError__') {
                    return 'Not valid JSON. Ensure the keys are wrapped in quotes (")'
                  }
                  else {
                    if (!Array.isArray(value)) {
                      return 'JSON must be a Array'
                    }
                    for (const v of value) {
                      if (!v.key || !v.name || !v.color) {
                        return 'Each entry must have a key, name, and color.'
                      }
                    }
                  }
                },
              },
              //valueAsJSON: true
              setValueAs: (raw: string) => {
                try {
                  return JSON.parse(raw)
                }
                catch(e) {
                  console.log(e)
                  return {
                    error: '__parseError__',
                    e
                  }
                }
              }
            }}
            defaultValue={JSON.stringify(stages, null, 2)}
            autoComplete="off"/>
          <Form.FieldError name='lifeCycleStages' chill></Form.FieldError>
          <Form.Submit disabled={loading}> Submit </Form.Submit>
          {success && <div className='bg-green-200'> Success </div>}
          <Form.BasicFormError error={error}/>
        </Form.Form>
      </div>
    </div>
  )
}
