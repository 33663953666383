import { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import type {
  CreateChangeOrderCellQuery,
  CreateChangeOrderCellQueryVariables,
} from 'types/graphql'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'

import CreateChangeOrder, { WithPart } from './CreateChangeOrder'

export const QUERY = gql`
  query CreateChangeOrderCellQuery($partNumber: String!) {
    partProto(partNumber: $partNumber) {
      partNumber
      category {
        id
        name
      }
      currentVersion {
        name
        version
        publishId
        name
        summary
        cadRev
      }
      owner {
        name
      }
    }
  }
`

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

function assertCurrentVersionExists(partProto: CreateChangeOrderCellQuery['partProto']): asserts partProto is WithPart {
  partProto!.currentVersion!.name!
  partProto!.currentVersion!.summary!
}

export const Success = ({ partProto }: CellSuccessProps<CreateChangeOrderCellQuery>) => {
  assertCurrentVersionExists(partProto)
  return <CreateChangeOrder withPart={partProto}/>
}
