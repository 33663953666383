import {
  RemovePartMutation,
  RemovePartMutationVariables
} from 'types/graphql'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'

export const REMOVE_PART_MUTATION = gql`
mutation RemovePartMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
) {
  removePart(changeOrderNumber: $changeOrderNumber, input: {
    partNumber: $partNumber
  }) {
    number
  }
}
`

export const useRemovePartMutation = () => {
  const [mutation, result] = useMutation<RemovePartMutation, RemovePartMutationVariables>(REMOVE_PART_MUTATION)

  const handleMutation = async ({ changeOrderNumber, partNumber }: RemovePartMutationVariables) => {
    const variables: RemovePartMutationVariables = {
      changeOrderNumber, partNumber
    }
    const mutationResult = await mutation({
      variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: changeOrderNumber } },
      ],
      awaitRefetchQueries: true
    })

    if (mutationResult.errors) {
      reportMutationError({
        errors: mutationResult.errors,
        variables,
        message: `Error removing part ${variables.partNumber} from change order ${changeOrderNumber}`
      })
    }

    return mutationResult
  }

  return [handleMutation, result] as const;
}
