import { MetaTags } from '@redwoodjs/web'
import { useParams, navigate, routes } from '@redwoodjs/router'
import SuperUserSwitcherCell from "src/components/SuperUserSwitcherCell"
import SuperUserOrganizationCell from "src/components/SuperUserOrganizationCell"

const SuperUserPage = () => {
  const orgId = useParams().orgId;
  const navigateToOrg = (orgId: string) => {
    navigate(routes.superUserOrg({ orgId }));
  }
  return (
    <>
      <MetaTags title="Super User page" description="For configuration" />
      <SuperUserSwitcherCell organizationId={orgId} onChange={(orgId) => navigateToOrg(orgId)} />
      {orgId && <SuperUserOrganizationCell organizationId={orgId} />}
    </>
  )
}

export default SuperUserPage
