import React from 'react'
import type { PropsWithChildren } from 'react'
import ApolloProviderWithOrg from 'src/components/ApolloProviderWithOrg'
import AppBodyCell from 'src/components/AppBodyCell'

type AppBodyProps = PropsWithChildren<{}>
const AppLayout = ({children}: AppBodyProps) =>
  <ApolloProviderWithOrg key='stable'>
      <AppBodyCell key='stable'>
        {children}
      </AppBodyCell>
  </ApolloProviderWithOrg>

export default AppLayout

export const FullWidthLayout = ({children}: AppBodyProps) =>
  <ApolloProviderWithOrg key='stable'>
      <AppBodyCell key='stable' width='full'>
        {children}
      </AppBodyCell>
  </ApolloProviderWithOrg>
