import { useRef, useState, useEffect, useReducer, useContext, useMemo, useCallback } from 'react'
import { reportMutationError } from 'src/lib/reportError'
import {
  ChangeOrderChangesQuery,
  ChangeOrderChangesQueryVariables,
  EditPartMutationVariables,
  EditPartVersionMutation,
  EditPartVersionMutationVariables,
  EditLifeCycleMutation,
  EditLifeCycleMutationVariables,
  Distributor,
  Part,
  ChangeOrderProtoMutation,
  ChangeOrderProtoMutationVariables,
} from 'types/graphql'

import * as Tooltip from "src/components/ToolTip"
import { ConditionalModal } from '../Modal'
import * as Form from 'src/components/Form'
import * as ListBox from 'src/components/ListBox'
import VersionChangeSummary from './VersionChangeSummary'
import type { CellSuccessProps } from '@redwoodjs/web'
import { routes, useParams, Link } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import Button, { EditButton } from '../Button'
import { DependencyChanges } from 'src/components/Dependencies'
import { ArtifactChanges } from '../Artifacts/Artifacts'
import { OverviewChanges, OverviewField } from '../PartOverview/PartOverview'
import { SourceChanges } from 'src/components/Sources/Sources'
import calculateAllChanges, { PartChangePart, PartDiffSummary } from './calculateAllChanges'
import { uiVersionParts, isVersionIncrement } from 'src/lib/version'
import RemovePart from 'src/components/RemovePart'
import MergeConflictHandler from './MergeConflict'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { ExclamationTriangleIcon, TagIcon } from '@heroicons/react/24/outline'
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { useLifeCycleStages, resolveLifeCycle } from "src/lib/lifecycle"

import { CHANGE_ORDER_CHANGES_QUERY } from 'src/lib/queries'
import GenericFailure from '../Failure/Failure'
import { TemplateBlockConfigs } from 'shared/partNumbers'
import AppContext from 'src/lib/appContext'
import { ControlledCategorySelect } from '../PartCategorySelect'
import { ChevronDownIcon, ChevronRightIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import keyBy from 'lodash.keyby'
export const Failure = GenericFailure

export const QUERY = CHANGE_ORDER_CHANGES_QUERY

export type ChangeOrderContextInfo = {
  isPartInOrder: (partNumber: string) => PartDiffSummary | undefined
}

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

type ChangeOrderChanges = CellSuccessProps<ChangeOrderChangesQuery, ChangeOrderChangesQueryVariables>
export const Success = ({ changeOrder, currentOrg, updating }: ChangeOrderChanges) => {
  const [, rerender] = useReducer((s) => s + 1, 0)
  const stages = useLifeCycleStages()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const params = useParams();
  const orgId = params.orgId!

  const [editPartProtoMutation] = useMutation<ChangeOrderProtoMutation, ChangeOrderProtoMutationVariables>(EDIT_PROTO_MUTATION)

  const editPartProto = async (partNumber: string, input: { categoryId: string }) => {
    const variables: ChangeOrderProtoMutationVariables = {
      partNumber,
      input: {
        categoryId: input.categoryId
      }
    }
    const { errors } = await editPartProtoMutation({
      variables
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part proto to category: ${input.categoryId}`
      })
    }
  }

  const appContext = useContext(AppContext)
  if (!appContext) return <LoadingSpinner className='flex p-10 items-center justify-center' />

  const changeOrderComplete = changeOrder.state === 'Complete' || changeOrder.state === 'Cancelled'

  const editableCategories = appContext.partNumberSchemas.flatMap(schema => {
    const templateConfig = schema.templateConfig as TemplateBlockConfigs
    const isCategoryEditable = Object.values(templateConfig).every(block => {
      return !['categoryNumber', 'categoryId'].includes(block.type)
    })
    if (isCategoryEditable) return schema.categories
    return []
  })

  const [deletePartCandidate, setDeletePartCandidate] = useState<string | undefined>();



  // overarching function for calculating changes - should be refactored to pass changes to
  // components instead of duplicating their logic
  const partDiffSummary = useMemo(() => {
    // Deterministic sort function so that parts don't jump around so much.
    // Sorts by most children, followed by ascending part number.
    //
    // This should be improved to roughly resemble the hierarchy, root component first
    // followed by children, followed by their children etc etc.
    //
    const sortedParts = ([...changeOrder.includedParts]).sort((a, b) => {
      if (a.isRoot && !b.isRoot) return -1
      if (!a.isRoot && b.isRoot) return 1
      if (a.dependencies.length !== b.dependencies.length) {
        return a.dependencies.length > b.dependencies.length ? -1 : 1
      }
      const aPartNumber = a.proto.partNumber
      const bPartNumber = b.proto.partNumber
      return aPartNumber > bPartNumber ? 1 : -1
    })
    return calculateAllChanges(currentOrg, sortedParts)
  }, [currentOrg, changeOrder.includedParts])

  const focusPrefix = '#part-change-'
  const hashTarget = decodeURI(location.hash)
  useEffect(() => {
    if (updating) return;
    if (containerRef.current && location.hash?.startsWith(focusPrefix)) {
      const targetElement = containerRef.current?.querySelector(hashTarget)
      targetElement?.scrollIntoView({ behavior: 'instant', block: 'start' })
    }
  }, [updating])
  const targetPartNumber = hashTarget.substring(focusPrefix.length)

  const storageRef = useRef<{[partNumber: string]: boolean}>()
  const storageKey = `changeOrderViewState-${orgId}-${changeOrder.number}`;

  if (!storageRef.current) {
    const collapsedRaw = localStorage.getItem(storageKey)
    storageRef.current = collapsedRaw ?
      JSON.parse(collapsedRaw) : {}
  }
  const collapsedParts = storageRef.current!

  const AUTO_COLLAPSE_LENGTH = 200
  const newEntriesCollapsed = partDiffSummary.length >= AUTO_COLLAPSE_LENGTH

  let hasUnseenParts = false
  for (const p of partDiffSummary) {
    if (collapsedParts[p.proto.partNumber] === undefined) {
      hasUnseenParts = true
      collapsedParts[p.proto.partNumber] = newEntriesCollapsed
    }
  }
  if (hasUnseenParts) {
    localStorage.setItem(storageKey, JSON.stringify(collapsedParts))
  }

  const onCollapseToggle = (partNumber: string, newState: boolean, removeHash: boolean) => {
    collapsedParts[partNumber] = newState
    localStorage.setItem(storageKey, JSON.stringify(collapsedParts))
    if (removeHash) {
      history.replaceState({}, '', window.location.pathname + window.location.search)
    }
    rerender()
  }

  const diffByPn = keyBy(partDiffSummary, 'proto.partNumber')

  const [partRenderIds, setPartRenderIds] = useState<Record<string, number>>({})
  const handleSetPartRenderIds = useCallback((partNumber: string) => () => {
    setPartRenderIds({
      ...partRenderIds,
      [partNumber]: (partRenderIds[partNumber] ?? 0) + 1
    })
  }, [partRenderIds])

  const parts = partDiffSummary.map((partDiff) => {
    const { headPart, incomingPart, proto } = partDiff
    const headerPartName = headPart ? headPart.name : incomingPart.name

    const targeted = proto.partNumber === targetPartNumber;
    const collapsed = !targeted && collapsedParts[proto.partNumber]!

    const thisLifeCycle = resolveLifeCycle(stages, incomingPart.lifeCycle)
    const validDependencyState = incomingPart.dependencies.every(d =>
      resolveLifeCycle(stages, d.to.lifeCycle).stageIndex >= thisLifeCycle.stageIndex
    )

    const changeOrderContext: ChangeOrderContextInfo = {
      isPartInOrder(partNumber) {
        return diffByPn[partNumber]
      }
    }

    const header =
      partDiff.type === 'create' ? { color: 'bg-green-100', action: 'Create' }
        : partDiff.type === 'update' ? { color: 'bg-yellow-100', action: 'Update' }
          : { color: 'bg-gray-100', action: 'No Changes to' }

    const category = () => {
      if (partDiff.type !== 'create' || !editableCategories.find(ec => ec.id === proto.category.id) || changeOrderComplete) {
        return <><TagIcon className='h-3 w-3' /><div>{proto.category.name}</div></>
      }
      return <><TagIcon className='h-3 w-3' />
        <ControlledCategorySelect
          size='sm'
          keepInternalState
          showOnly={editableCategories.map(c => c.id)}
          categories={appContext.partCategories}
          value={proto.category.id}
          onCategoryIdChange={c => editPartProto(proto.partNumber, { categoryId: c })}
        />
      </>
    }

    const removeButton = () => {
      if (changeOrderComplete) return
      const dependencyChangesRequired = partDiff.incomingPart.dependencies.filter((dep) => {
        const depDiff = diffByPn[dep.to.partNumber]
        // dep is not in change order, no worries
        if (!depDiff) return false

        const previousDep = partDiff.headPart?.dependencies.find(hd => hd.to.partNumber === dep.to.partNumber)

        // if dep newly added, then isn't a problem
        if (!previousDep) return false

        // if dep was previously pinned then the parent can be removed
        if (previousDep.toVersionRange !== '*') return false

        // this dep prevents the parent being removed
        return true
      }).map(d => `#${d.to.partNumber}`)

      if (dependencyChangesRequired.length > 0) {
        return <Tooltip.Container className='relative'>
          <Tooltip.Message className="bottom-10 z-50" position='left'>
            {dependencyChangesRequired.join(', ')} must be removed from the change order to remove this part
          </Tooltip.Message>
          <Button disabled size='sm'>
            Remove from Change Order
          </Button>
        </Tooltip.Container>
      }
      return <Button size='sm' writeOnly onClick={() => setDeletePartCandidate(proto.partNumber)}>
            Remove from Change Order
          </Button>
    }

    return <div className={`rounded-lg flex flex-col scroll-mt-32 ${collapsed ? 'mb-1' : 'mb-8'} ${targeted ? 'border-2 border-blue-400' : 'border border-gray-300'}`} key={proto.partNumber} id={`part-change-${proto.partNumber}`}>
      {/* Header */}
      <div className={`${header.color} pl-8 py-4 pr-7 flex gap-4 flex-col sticky top-16 z-10  ${collapsed ? 'rounded-lg' : 'rounded-t-lg'}`}>
        <div className='flex items-start gap-1'>
          <button className='p-1 -ml-5 mr-2 self-center' onClick={() => onCollapseToggle(proto.partNumber, !collapsed, targeted)}>
            {collapsed ?
              <ChevronRightIcon className='w-5 text-gray-500'/> :
              <ChevronDownIcon className='w-5 text-gray-500'/>
            }
          </button>
          <div className='flex-1  text-gray-900 gap-1 items-center'>
            <div className='flex gap-1 text-gray-700 text-xs items-center mb-2 -ml-1'>
              {category()}
            </div>

            <div className={`flex gap-1 text-sm ${proto.partNumber === targetPartNumber ? 'font-bold' : ''}`} >
              <a className={proto.partNumber === targetPartNumber ? '!font-bold' : 'font-medium'} href={`#part-change-${proto.partNumber}`}>
                <span>{header.action} #{proto.partNumber} </span>
                <span>{headerPartName ?? ''}</span>
              </a>
            </div>
          </div>
          {removeButton()}
          {partDiff.type !== 'create' &&
            <Link to={routes.part({ orgId, partNumber: incomingPart.partNumber })} className='leading-none'>
              <Button size='sm'> View Part </Button>
            </Link>
          }
        </div>
      </div>
      {!collapsed &&
        <>
          {!changeOrderComplete &&
            <MergeConflictHandler changeOrderNumber={changeOrder.number} proto={proto} headPart={headPart} incomingPart={incomingPart} onComplete={handleSetPartRenderIds(proto.partNumber)}/>
          }
          {!validDependencyState &&
            <div className='-mb-4 mx-2 mt-1 p-2 flex flex-col gap-2'>
              <div className='flex items-center gap-4 bg-red-100 rounded-xl p-4 py-2 text-xs text-gray-800 border-red-200 border'>
                <ExclamationTriangleIcon className='w-6 mt-0.5 text-red-500' />
                <div className='font-medium'>
                  A part included in this assembly is behind the lifecycle status of the assembly. This change order cannot be applied until all the child parts are at the same status or higher.
                </div>
              </div>
            </div>
          }
          <PartChange
            key={proto.partNumber + ":" + (partRenderIds[proto.partNumber] ?? '0')}
            edit={{
              changeOrder
            }}
            changeOrderComplete={changeOrderComplete}
            partDiff={partDiff}
            incomingPart={incomingPart}
            distributors={currentOrg.distributors}
            // TODO P1: Lookup head part instead of version in main
            headPart={headPart}
            changeOrderContext={changeOrderContext}
          />
        </>
      }
    </div>
  })

  const partsOrPlaceholder = parts.length > 0 ? parts : <div className='text-center italic text-gray-600 bg-gray-100 p-5 rounded-md'>
    There are no parts in the change order
  </div>

  const versionsSummary = changeOrderComplete ? null : <div className='flex flex-col gap-6'>
    <div className='text-2xl'>Tree View</div>
    <div className='text-gray-900 text-sm'>
      Hierarchy view of updates for top level and orphan parts.
    </div>
    <VersionChangeSummary changeOrder={changeOrder} />
  </div>

  return <>
    {deletePartCandidate &&
      <ConditionalModal onClose={() => setDeletePartCandidate(undefined)}>
        <RemovePart
          changeOrderNumber={changeOrder.number}
          partNumber={deletePartCandidate}
          onComplete={() => setDeletePartCandidate(undefined)} />
      </ConditionalModal>
    }
    <div className='flex flex-col gap-16 pt-8'>
      {versionsSummary}
      <div className='flex flex-col gap-6' ref={containerRef}>
        <div className='text-2xl'>Parts</div>
        <div className='text-gray-900 text-sm'>
          These parts are included in the change order, showing any changes side by side.
        </div>
        <div className='flex flex-col gap-4'>
          {partsOrPlaceholder}
        </div>
      </div>
    </div>
  </>
}

export const EDIT_PROTO_MUTATION = gql`
mutation ChangeOrderProtoMutation (
  $partNumber: String!
  $input: UpdatePartProtoInput!
) {
  updatePartProto(partNumber: $partNumber, input: $input) {
    partNumber
  }
}
`

export const EDIT_PART_MUTATION = gql`
mutation EditPartMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $name: String
  $cadRev: String
  $summary: String
  $isRoot: Boolean
  $isOffTheShelf: Boolean
  $changeMessage: String
  $transitionPlan: String
  $artifacts: [ArtifactFieldInput!]
  $metadata: JSON
  $dependencies: [DependencyInput!]
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      summary: $summary
      isRoot: $isRoot
      name: $name
      cadRev: $cadRev
      isOffTheShelf: $isOffTheShelf
      transitionPlan: $transitionPlan
      changeMessage: $changeMessage
      artifacts: $artifacts
      metadata: $metadata
      dependencies: $dependencies
    }
  }]) {
    partNumber
  }
}
`

type IncludedPart = Pick<Part, 'partNumber' | 'version' | 'name' | 'isRoot' | 'isOffTheShelf'>

type PartChangeProps = {
  changeOrderComplete: boolean
  distributors: Distributor[]
  headPart?: PartChangePart | null
  incomingPart: PartChangePart
  partDiff: PartDiffSummary
  edit?: {
    changeOrder: {
      number: number
      includedParts: IncludedPart[]
    }
  }
  hideVersion?: boolean
  changeOrderContext?: ChangeOrderContextInfo,
}
export const PartChange: React.FC<PartChangeProps> = ({
  changeOrderComplete,
  edit,
  distributors,
  headPart,
  incomingPart,
  hideVersion,
  partDiff,
  changeOrderContext,
}) => {
  const [isExpanded, setExpanded] = useState(!headPart)
  const onToggleExpand = () => {
    setExpanded(!isExpanded)
  }

  const childEdit = edit ? {
    partNumber: incomingPart.partNumber,
    changeOrderNumber: edit.changeOrder.number
  } : undefined

  const toggleContent = isExpanded ?
    <>
      <span className=''>Show Changes Only</span><ChevronUpIcon className='w-4' />
    </> :
    <>Show All Fields <ChevronUpDownIcon className='w-4' /></>

  const versionSection = () => {
    if (hideVersion) return null
    return <VersionChanges
      headPart={headPart}
      incomingPart={incomingPart}
      changeOrderComplete={changeOrderComplete}
      edit={childEdit}
    />
  }

  return <div>
    <div className='px-8 pb-5 flex flex-col pt-6'>
      {/* Version */}
      {versionSection()}
      <div className='flex flex-col gap-4'>
        <div className='flex'>
          {headPart && <>
            <div className='flex-1'></div>
            <div className='border-l border-gray-200 mx-10'></div>
          </>
          }
          <div className='flex pt-2 flex-1 flex-col justify-between gap-4'>
            <OverviewField
              editMode={changeOrderComplete ? undefined : 'edit'}
              fieldName='changeMessage'
              label='Change Message'
              inputType={'textarea'}
              nextVersion={incomingPart.version}
              empty={`No change message written`}
              edit={childEdit}
              value={incomingPart.changeMessage} />
            {headPart && (isExpanded || incomingPart.transitionPlan) && <OverviewField
              editMode={changeOrderComplete ? undefined : 'edit'}
              fieldName='transitionPlan'
              label='Transition Plan'
              inputType={'textarea'}
              nextVersion={incomingPart.version}
              empty={`No transition plan`}
              edit={childEdit}
              value={incomingPart.transitionPlan} />}
          </div>
        </div>
        <div className='flex -mx-1'>
          {headPart && <button
            onClick={onToggleExpand}
            className='flex flex-1 rounded-md justify-center my-4 bg-gray-100 p-2 text-xs hover:bg-gray-200'>
            <div className='flex items-center gap-1'>{toggleContent}</div>
          </button>}
        </div>
        {/* Overview */}
        {
          <OverviewChanges
            isExpanded={isExpanded}
            changeOrderComplete={changeOrderComplete}
            partDiff={partDiff}
            edit={edit ? {
              partNumber: partDiff.incomingPart.partNumber,
              changeOrderNumber: edit.changeOrder.number
            }: undefined} />
        }
        {/* Artifacts */}
        <ArtifactChanges
          isExpanded={isExpanded}
          partDiff={partDiff}
          changeOrderNumber={edit?.changeOrder.number}
          changeOrderComplete={changeOrderComplete} />
        {
          <SourceChanges
            isExpanded={isExpanded}
            distributors={distributors}
            changeOrderComplete={changeOrderComplete}
            changeOrderNumber={edit?.changeOrder.number}
            partDiff={partDiff}
          />
        }
        {/* BOM */}
        <DependencyChanges
          isExpanded={isExpanded}
          edit={edit && { changeOrder: edit.changeOrder }}
          partDiff={partDiff}
          changeOrderComplete={changeOrderComplete}
          changeOrderContext={changeOrderContext}
        />
      </div>
    </div>
  </div>
}

const EDIT_LIFECYCLE_MUTATION = gql`
mutation EditLifeCycleMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $lifeCycle: String!
) {
  addPartDeltasReturnChangeOrder(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      lifeCycle: $lifeCycle
    }
  }]) {
    ...ChangeOrderReviewersFragment
  }
}
`

type StatusProps = {
  changeOrderNumber?: number
  part: PartChangePart
  className?: string
  disabled: boolean
}
export const LifecycleStatus: React.FC<StatusProps> = ({ changeOrderNumber, part, disabled, className }) => {
  const stages = useLifeCycleStages()

  const [updateLifeCycle, { loading, error }] = useMutation<EditLifeCycleMutation, EditLifeCycleMutationVariables>(EDIT_LIFECYCLE_MUTATION)

  const handleLifeCycleChange = async (newKey: string) => {
    if (typeof changeOrderNumber !== 'number') {
      throw new Error('Cannot change lifecycle without change order')
    }
    if (newKey === part.lifeCycle) return

    const variables: EditLifeCycleMutationVariables = {
      changeOrderNumber,
      partNumber: part.partNumber,
      version: part.version,
      lifeCycle: newKey
    }

    const { errors } = await updateLifeCycle({
      variables,
      refetchQueries: [
        { query: QUERY, variables: { orderNumber: changeOrderNumber } },
      ],
      awaitRefetchQueries: true,
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part lifeCycle with ${newKey}`
      })
    }
  }

  const Display = ({ lifeCycle }: { lifeCycle: string | null | undefined }) =>
    <div className='flex items-center gap-2 text-sm'>
      <StatusDot size='sm' lifeCycle={lifeCycle} />
      <div>{resolveLifeCycle(stages, lifeCycle).name}</div>
    </div>

  if (disabled) return <div className={`'mr-2 ${className ?? ''}`}> <Display lifeCycle={part.lifeCycle} /> </div>

  return <ListBox.ListBox disabled={disabled} defaultValue={part.lifeCycle} onChange={handleLifeCycleChange} >
    {({ open }) =>
      <div className={`relative h-full text-xs ${className}`}>
        <ListBox.UncontrolledButton size='sm' displayFunction={s => <Display lifeCycle={s.value} />} />
        <ListBox.Options open={open} align='right'>
          {stages?.map(s =>
            <ListBox.Option key={s.key} className='py-3' value={s.key}
              display={<Display lifeCycle={s.key} />}
            />
          )}
        </ListBox.Options>
      </div>
    }
  </ListBox.ListBox>
}

export const EDIT_PART_VERSION_MUTATION = gql`
mutation EditPartVersionMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Version
    partNumber: $partNumber
    version: $version
  }]) {
    partNumber
  }
}
`

type VersionChangesProps = {
  edit?: {
    changeOrderNumber: number
  }
  changeOrderComplete: boolean

  headPart?: PartChangePart | null
  incomingPart: PartChangePart
}
const VersionChanges: React.FC<VersionChangesProps> = ({
  headPart,
  incomingPart,
  edit,
  changeOrderComplete,
}) => {
  const headVersion = headPart?.version
  const { partNumber, version: incomingVersion } = incomingPart

  const [editing, setEditing] = useState(false)

  const [editVersionMutation, { loading, error }] = useMutation<EditPartVersionMutation, EditPartVersionMutationVariables>(EDIT_PART_VERSION_MUTATION)

  type VersionForm = { version: string }
  const onVersionEditSubmit = async ({ version: incomingVersion }: VersionForm) => {
    if (!edit) return
    const { changeOrderNumber } = edit
    const version = incomingVersion.toLowerCase();
    const variables: EditPartMutationVariables = {
      version,
      changeOrderNumber,
      partNumber
    }
    const { data, errors } = await editVersionMutation({
      variables,
      refetchQueries: [{ query: QUERY, variables: { orderNumber: changeOrderNumber } }],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part version with value ${variables.version}`
      })
    }

    if (!errors) {
      setEditing(false);
    }
  }

  const renderVersion = (part: PartChangePart, showEdit: boolean) => {
    return <div className='flex flex-1 gap-2 text-md text-gray-900 font-medium mb-6'>
      {editing && showEdit ?
        <>
          <Form.Form<VersionForm> className='flex flex-col gap-2' onSubmit={onVersionEditSubmit}>
            <Form.TextField name="version" autoFocus autoComplete='off'
              compact leadingAddOn='Version' inputClassName='pl-20 !text-lg'
              placeholder={incomingVersion}
              required
              validation={{
                required: true,
                validate: {
                  isVersion: (newVersion) => {
                    const parts = uiVersionParts(newVersion)
                    if (!parts) {
                      return 'Version must be dot seprated alpha numeric characters'// or equal to later
                    }
                    if (parts.segments.length !== 3 && parts.segments.length !== 2) {
                      return 'Version must have 2 or 3 segments'// or equal to later
                    }
                  },
                  isIncrement: (newVersion) => {
                    if (headVersion) {
                      const valid = isVersionIncrement(headVersion, newVersion)
                      if (!valid) {
                        return 'Version must be higher than previous version'// or equal to later
                      }
                    }
                  }
                }
              }}
            />
            <Form.BasicFormError error={error} />
            <Form.FieldError name='version' chill />
            <div className='flex gap-2'>
              <Button type='button' onClick={() => setEditing(false)}>Cancel</Button>
              <Button type='submit' variant='primary'>Save</Button>
            </div>
          </Form.Form>
          <div className='-mr-1'>
            <LifecycleStatus disabled={!showEdit} changeOrderNumber={edit?.changeOrderNumber} part={part} className='ml-auto' />
          </div>
        </>
        :
        <div className='flex flex-1 items-center text-sm'>
          <div className='flex-1 flex items-center gap-2 font-semibold'>
            <span>Version {part.version}</span>
            {showEdit && <EditButton size={4} onClick={() => setEditing(true)} testId='version' />}
          </div>
          <div className={showEdit ? '-mr-1' : ''}>
            <LifecycleStatus disabled={!showEdit} changeOrderNumber={edit?.changeOrderNumber} part={part} />
          </div>
        </div>
      }
    </div>
  }

  if (!headVersion) return renderVersion(incomingPart, !changeOrderComplete)
  return <div className='flex'>
    {renderVersion(headPart, false)}
    <div className='border-l border-gray-200 mx-10'></div>
    {renderVersion(incomingPart, !changeOrderComplete)}
  </div>
}
