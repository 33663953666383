
type LogoProps = {
  className?: string
}
const Logo: React.FC<LogoProps> = ({ className }) => <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg2" width="40" height="40" viewBox="0 0 1100 700" className={className}>
  <defs id="defs6"/>
  <g id="g100" transform="scale(0.9)" style={{ 'transformOrigin': '-18720px -28651px' }}>
      <path style={{ fill: 'currentColor', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="m 3148.13,3490.69 c -15.98,30.26 -37.1,53.66 -63.36,70.21 -26.25,17.13 -55.37,28.54 -87.33,35.96 v 5.14 c 27.4,7.41 52.51,19.4 75.91,35.38 22.84,15.99 41.67,37.68 55.94,65.08 14.27,27.39 21.12,62.78 21.12,105.59 0,42.81 -11.42,80.48 -34.24,113.59 -23.41,32.54 -54.23,58.22 -91.9,76.49 -38.24,18.26 -80.49,27.96 -126.72,27.96 h -208.34 v -194.63 h 95.33 c 18.26,0 34.24,-6.86 47.94,-19.99 13.13,-13.69 19.98,-29.67 19.98,-48.51 0,-18.27 -2.86,-33.11 -9.13,-45.09 -5.71,-11.99 -13.13,-21.12 -22.83,-27.97 -10.28,-6.28 -22.26,-9.7 -35.96,-9.7 h -95.33 v -182.66 h 116.44 c 13.13,0 25.12,-3.42 35.39,-9.13 10.28,-6.28 18.27,-14.27 23.98,-25.12 5.71,-10.84 8.56,-22.83 8.56,-35.38 0,-27.4 -6.28,-48.52 -18.84,-62.22 -12.55,-13.7 -28.53,-20.55 -49.09,-20.55 h -116.44 v -206.06 h 216.33 c 49.66,0 94.75,9.71 134.71,29.69 39.96,19.4 71.92,47.37 95.89,82.19 23.98,35.39 35.96,76.49 35.96,123.86 0,47.38 -7.99,86.2 -23.97,115.87" id="path14"/>
      <path d="m 2287.38,3329.16 h 194.64 v -194.64 h -194.64 v 194.64" style={{ fill: 'currentColor', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} id="path28"/>
      <path d="m 2543.19,3677.34 v -194.63 h -317 v 194.63 h 317" style={{ fill: 'currentColor', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} id="path30"/>
      <path d="m 2543.19,4026.09 v -194.63 h -317 v 194.63 h 317" style={{ fill: 'currentColor', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} id="path32"/>
      <path d="m 2543.19,3329.16 v -194.64 h -317 v 194.64 h 317" style={{ fill: 'currentColor', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} id="path34"/>
    </g>
</svg>

export default Logo
