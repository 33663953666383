import React, { ReactNode } from 'react'

export default (nodes: ReactNode[], oxfordComma = true): ReactNode => {
  return nodes.map((n, idx) =>
    <>
      {idx === 0 ? undefined :
      (idx + 1) === nodes.length ? `${(oxfordComma && nodes.length > 2) ? ',' : ''} and ` : ', '}
      {n}
    </>
  )
}
