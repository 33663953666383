import * as Tooltip from 'src/components/ToolTip'
import { ArrowPathIcon, MapPinIcon } from '@heroicons/react/20/solid'

export const AlwaysUpdateIcon: React.FC = () => {
  return <Tooltip.Container>
    <Tooltip.Message>
      Always Update
    </Tooltip.Message>
    <ArrowPathIcon className='w-3 text-gray-600' />
  </Tooltip.Container>
}

export const PinnedIcon: React.FC<{ version: string }> = ({ version }) => {
  return <Tooltip.Container>
    <Tooltip.Message>
      Pinned to v{version}
    </Tooltip.Message>
    <MapPinIcon className='w-3 text-gray-600 -mt-0.5' />
  </Tooltip.Container>
}
