export const wrapError = (prepend: string, error: unknown) => {
  if (error && typeof error === 'object' && 'message' in error) {
    // Add context to the error message
    (error as { message: string }).message = `${prepend}: ${error.message}`;
  }
  return error
}

export const getErrorMessage = (error: unknown) => {
  if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    return error.message
  }
  return 'Unknown Error'
}
