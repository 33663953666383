import type { ChangeOrderSelectQuery, ChangeOrderState } from 'types/graphql'
import * as ListBox from '../ListBox'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

export const QUERY = gql`
  query ChangeOrderSelectQuery {
    changeOrders {
      _id
      number
      name
      state
    }
  }
`

export type SelectedChangeOrder = {
  number: number
  name: string
  state: ChangeOrderState
}
type CellProps = {
  onSelect: (value: SelectedChangeOrder) => void
  placeholder: string
  className?: string
  omit?: number[]
  disabledChangeOrders?: number[]
  loading?: boolean
  onCreate?: () => void
  disabled?: boolean
  optionsOnly?: boolean
  objectValue?: boolean
  testId?: string
  mode?: 'select' | 'action'
}

export const Loading = ({ ...props }: CellProps) => {
  return <ChangeOrderSelect {...props} changeOrders={[]} loading />
}

import { FailureErrorBar } from '../Failure/Failure'
import { useState } from 'react'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading change orders' {...props}>
    <ChangeOrderSelect {...props} changeOrders={[]} />
  </FailureErrorBar>
}

export const Success = ({ ...props }: CellProps & CellSuccessProps<ChangeOrderSelectQuery>) => {
  return <ChangeOrderSelect {...props} />
}

export const ChangeOrderSelect = ({
  changeOrders,
  onSelect,
  disabledChangeOrders,
  placeholder,
  className,
  omit,
  mode,
  onCreate,
  disabled,
  optionsOnly,
  objectValue,
  testId
}: CellProps & CellSuccessProps<ChangeOrderSelectQuery>) => {
  mode = mode || 'action'

  const buttonTestId = testId ? `${testId}:button` : undefined
  const optionTestId = (key: string) => (testId ? `${testId}:option:${key}` : undefined)

  const containerClass = 'relative text-xs' + className ?? ''
  const [selected, setSelected] = useState<string | null>(null)

  if (disabled) {
    return <ListBox.ListBox value='' disabled={true}>
      <div className={containerClass}>
        <ListBox.Button className='min-w-full' displayValue={placeholder} disabled={disabled} />
      </div>
    </ListBox.ListBox>
  }

  const values = changeOrders.filter(changeOrder => {
    if (omit && omit.includes(changeOrder.number)) return false
    if (changeOrder.state === 'Complete') return false
    if (changeOrder.state === 'Cancelled') return false
    return true
  })
  const handleSelect = (value: string) => {
    setSelected(value)
    if (value === 'new') {
      onCreate!()
      return
    }
    const changeOrderNumber = Number(value)
    const changeOrder = changeOrders.find(changeOrder => changeOrder.number === changeOrderNumber)!
    onSelect({
      number: changeOrder.number,
      name: changeOrder.name,
      state: changeOrder.state,
    })
  }

  const optionClass = 'py-3 text-right'
  const dividerClass = values.length > 0 ? 'border-t border-gray-200' : ''
  const createNew = onCreate ?
    <div className={dividerClass}>
      <ListBox.Option
        testId={optionTestId('new')}
        key={'new'}
        className={optionClass}
        value={'new'}
        display='Create Change Order' />
    </div> : null

  const options = <>
    {values.map((changeOrder) => (
      <ListBox.Option
        testId={optionTestId(String(changeOrder.number))}
        disabled={disabledChangeOrders?.includes(changeOrder.number)}
        key={changeOrder.number}
        className={optionClass}
        value={objectValue ? changeOrder : String(changeOrder.number)}
        display={`#${changeOrder.number} ${changeOrder.name}`} />
    ))}
    {createNew}
  </>

  if (optionsOnly) {
    return options
  }

  const value = mode === 'action' ? '' : selected

  const selectedChangeOrder = selected ? changeOrders.find(c => c.number === Number(selected)) : null
  const changeOrderName = selected === 'new' ? 'Create Change Order' : selectedChangeOrder?.name
  const displayValue = mode === 'action' ? placeholder : (changeOrderName || placeholder)

  return <ListBox.ListBox onChange={handleSelect} value={value} disabled={!onCreate && values.length === 0}>
    {({ open, disabled }) => (
      <>
        <div className={containerClass}>
          <ListBox.Button
            data-testid={buttonTestId}
            className='min-w-full'
            displayValue={displayValue}
            disabled={disabled} />
          <ListBox.Options className='min-w-full' open={open}>
            {options}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}
