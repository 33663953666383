import React, { useEffect } from 'react'

import Sentry from 'src/lib/sentry'
import unsignedJwt from 'src/lib/unsignedJwt'

import { createAuthentication } from "@redwoodjs/auth"
import { ClerkProvider, useUser } from '@clerk/clerk-react'

import { createAuth } from '@redwoodjs/auth-clerk-web'
import { navigate } from '@redwoodjs/router'

import { isTest } from './lib/mode'

function createTestAuth() {
  //@ts-ignore
  window.superLogin = function() {
    //@ts-ignore
    window.anyLogin({
      email: 'any@person.com',
      isSuperUser: true
    })
  }
  //@ts-ignore
  window.anyLogin = function({ email, isSuperUser }: { email: string, isSuperUser: boolean }) {
    const token = unsignedJwt({
      email,
      roles: isSuperUser ? ['superuser'] : [],
    });
    localStorage.setItem('test_token', token);
  }
  function testAuthImpl() {
    return {
      type: 'test_auth',
      login: async () => undefined,
      logout: async () => undefined,
      signup: async () => undefined,
      getToken: async () => localStorage.getItem("test_token"),
      getUserMetadata: async () => ({}),
    }
  }
  return createAuthentication(testAuthImpl())
}

function createClerkAuth() {
  const { AuthProvider: ClerkRwAuthProvider, useAuth: useClerkAuth } = createAuth()

  const ClerkStatusUpdater = () => {
    const { isSignedIn, user, isLoaded } = useUser()
    const { reauthenticate } = useClerkAuth()

    if (user?.primaryEmailAddress?.emailAddress) {
      Sentry.setUser({
        email: user.primaryEmailAddress.emailAddress
      })
    }

    useEffect(() => {
      if (isLoaded) {
        reauthenticate()
      }
    }, [isSignedIn, user, reauthenticate, isLoaded])

    return null
  }

  type ClerkOptions =
    | { publishableKey: string; frontendApi?: never }
    | { publishableKey?: never; frontendApi: string }

  interface Props {
    children: React.ReactNode
  }

  const ClerkProviderWrapper = ({
    children,
    clerkOptions,
  }: Props & { clerkOptions: ClerkOptions }) => {
    const { reauthenticate } = useClerkAuth()

    return (
      <ClerkProvider
        {...clerkOptions}
        navigate={(to) => reauthenticate().then(() => navigate(to))}
      >
        {children}
        <ClerkStatusUpdater />
      </ClerkProvider>
    )
  }

  const ClerkAuthProvider = ({ children }: Props) => {
    const publishableKey = process.env.CLERK_PUBLISHABLE_KEY
    const frontendApi =
      process.env.CLERK_FRONTEND_API_URL || process.env.CLERK_FRONTEND_API

    const clerkOptions: ClerkOptions = publishableKey
      ? { publishableKey }
      : { frontendApi }

    return (
      <ClerkRwAuthProvider>
        <ClerkProviderWrapper clerkOptions={clerkOptions}>
          {children}
        </ClerkProviderWrapper>
      </ClerkRwAuthProvider>
    )
  }

  return {
    AuthProvider: ClerkAuthProvider,
    useAuth: useClerkAuth
  }
}


export const { AuthProvider, useAuth } = isTest ?
  createTestAuth() : createClerkAuth()
