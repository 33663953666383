type LogoProps = {
  className?: string
}

export type Logo = React.FC<LogoProps>

export const CreoLogo: Logo = ({ className }) => {
  return <img className={(className || '')} src='/creo_logo.svg' />
}

export const SolidworksLogo: Logo = ({ className }) => {
  return <img className={(className || '')} src='/solidworks_logo.svg' />
}

export const AltiumLogo: Logo = ({ className }) => {
  return <img className={(className || '')} src='/altium_logo.svg' />
}
