import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from '@redwoodjs/router'
import { useAuth } from '../auth'

import { isTest } from 'src/lib/mode'

import { ApolloLink, useApolloClient } from "@apollo/client"
import { RedwoodApolloProvider, GraphQLClientConfigProp } from '@redwoodjs/web/apollo'
import { createNetworkStatusNotifier, NetworkStatus } from 'react-apollo-network-status'
import possibleTypes from 'src/graphql/possibleTypes'

let notifierLink: ApolloLink, useApolloNetworkStatus: () => NetworkStatus;
let networkStatus: NetworkStatus | undefined = undefined
let useNetworkIdle: () => 'idle' | 'loading'
if (isTest) {
  const notifier = createNetworkStatusNotifier()
  notifierLink = notifier.link;
  useApolloNetworkStatus = notifier.useApolloNetworkStatus;

  const currentlyIdle = (status: NetworkStatus) => status.numPendingQueries === 0 && status.numPendingMutations === 0

  let wasIdle = true;
  const networkIdle = () => {
    const isIdle = !networkStatus || currentlyIdle(networkStatus)
    const restingIdle = wasIdle && isIdle

    wasIdle = isIdle
    return restingIdle
  }
  //@ts-ignore
  window.networkIdle = networkIdle
  useNetworkIdle = () => {
    const [idleCount, setIdleCount] = useState(0)
    const status = useApolloNetworkStatus()

    useEffect(() => {
      if (currentlyIdle(status) && idleCount < 1) {
        setIdleCount(1)
      }
      else {
        setIdleCount(0)
      }
    }, [currentlyIdle(status)])

    return idleCount > 0 ? 'idle' : 'loading'
  }
}

type ProviderProps = {
  children: React.ReactNode
}

const ApolloProviderWithOrg: React.FC<ProviderProps> = ({ children }) => {
  const upperClient = useApolloClient()
  const orgId = useParams().orgId;
  const clientConfig: GraphQLClientConfigProp = {
    cache: upperClient.cache,
    cacheConfig: {
      possibleTypes: possibleTypes.possibleTypes,
    },
    link: (links) => {
      const orgContextLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'bomello-org-id': orgId,
          }
        }));
        return forward(operation)
      });

      return ApolloLink.from([orgContextLink, ...(notifierLink ? [notifierLink] : []), ...links.map(({ link }) => link)])
    }
  }
  return <>
    <RedwoodApolloProvider graphQLClientConfig={clientConfig} useAuth={useAuth}>
      <NetworkReader>
        { children }
      </NetworkReader>
    </RedwoodApolloProvider>
  </>
}

const ScreenCover = () =>
  createPortal(
    <div className='relative !fixed inset-0 bg-white z-[1000]'></div>,
    document.body
  )


export default ApolloProviderWithOrg;

const NetworkReader = (props: any) => {
  if (isTest) {
    networkStatus = useApolloNetworkStatus();
    const idleStatus = useNetworkIdle()
    return idleStatus === 'loading' ? <>
      <div key='test-body' data-testid={`status-${idleStatus}`} className={idleStatus === 'loading' ? 'hidden' : ''}>
        {props.children}
        <ScreenCover/>
      </div>
      <div className='h-[10000px]'/>
    </> :
    <div key='test-body' data-testid={`status-${idleStatus}`}>
      {props.children}
    </div>
  }
  return props.children
}
