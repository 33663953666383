import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const plantMapper: MapperConfig = {
  orgIds: ['carbonquest', 'carbonquest-sandbox'],
  name: 'Plant',
  rootCategoryId: 'plant',
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: ['size', 'spec', 'replenishment-class']
  },
  fileToRows: {
    xlsx: {
      enable: true,
    }
  },
  // validateBom: {
  //   validators: [{
  //     type: 'duplicate',
  //     message: 'Duplicate part found',
  //     check: '{{project.rootPartNumber}}--{{row.skid_no}}--{{row.line_number}}--{{row.part_number}}'
  //   }]
  // },
  rowsToBom: [{
    type: 'MergeRows',
    config: {
      mergeOn: '{{project.rootPartNumber}}--{{row.skid_no}}--{{row.line_number}}--{{row.part_number}}',
      output: {
        p_id_ref_no: '${rows.map(r => r.p_id_ref_no).join(", ")}',
        // if quantity of each row is not 1, that's a mistake
        total: '${rows.reduce((t, r) => t + (isNaN(Number(r.total)) ? 1 : Number(r.total)), 0)}'
      },
    }
  }, {
    type: 'NormalizeRows',
    config: {
      normalizeOn: '${row.part_number}',
      normalizeColumns: ['description']
    }
  }, {
    type: 'SubassemblyExplode',
    config: [{
      explodeIdentifier: 'skid',
      subassemblyKeyColumn: 'skid_no',
    }, {
      explodeIdentifier: 'line',
      subassemblyKeyColumn: 'line_number',
    }]
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        size: {
          value: '{{row.size}}',
          type: 'simple'
        },
        spec: {
          type: 'simple',
          value: '{{row.spec}}',
        },
        'replenishment-class': {
          type: 'simple',
          value: '{{row.replenishment_class}}',
        }
      },
      referenceDesignator: {
        type: 'simple',
        value: '{{row.p_id_ref_no}}',
      },
      hierarchy: {
        value: '{{row.__hierarchy}}',
        type: 'simple'
      },
      categoryId: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'skid'
        }],
        onMatch: {
          type: 'simple',
          value: 'skid'
        },
      }, {
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'line'
        }],
        onMatch: {
          type: 'simple',
          value: '150'
        },
      }, {
        default: true,
        onMatch: {
          type: 'split',
          input: '{{row.part_number}}',
          splitOn: '-',
          value: '{{parts.0}}',
        }
      }],
      isOffTheShelf: [{
        conditions: [{
          type: 'empty',
          column: '__explode_identifier',
        }],
        onMatch: {
          type: 'simple',
          value: 'yes'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'no'
        }
      }],
      partNumber: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'skid'
        }],
        onMatch: {
          type: 'simple',
          value: '{{project.rootPartNumber}}-{{row.skid_no}}'
        },
      }, {
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'line'
        }],
        onMatch: {
          type: 'generateByKey',
          schemaKey: 'smart',
          partNumberKey: '{{project.rootPartNumber}}--{{row.skid_no}}--{{row.line_number}}',
          categoryId: '150',
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.part_number}}',
        },
      }],
      name: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'skid'
        }],
        onMatch: {
          type: 'simple',
          value: '{{project.name}} - Skid {{row.skid_no}}',
        },
      }, {
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'line'
        }],
        onMatch: {
          type: 'simple',
          value: '{{project.name}} - Skid {{row.skid_no}}, line {{row.line_number}}',
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.description}}'
        }
      }],
      units: [{
        conditions: [{
          type: 'startsWith',
          column: 'part_number',
          value: '800-'
        }, {
          type: 'startsWith',
          column: 'part_number',
          value: '801-'
        }, {
          type: 'startsWith',
          column: 'part_number',
          value: '802-'
        }, {
          type: 'startsWith',
          column: 'part_number',
          value: '803-'
        }, {
          type: 'startsWith',
          column: 'part_number',
          value: '804-'
        }, {
          type: 'startsWith',
          column: 'part_number',
          value: '805-'
        }],
        onMatch: {
          type: 'simple',
          value: 'in'
        },
      }, {
        conditions: [{
          type: 'startsWith',
          column: 'part_number',
          value: '720-'
        }],
        onMatch: {
          type: 'simple',
          value: 'oz'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'each'
        }
      }],
      quantity: [{
        conditions: [{
          type: 'empty',
          column: 'total',
        }],
        onMatch: {
          type: 'simple',
          value: '1'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.total}}'
        }
      }]
    }
  }
}


export const partsImport: MapperConfig = {
  orgIds: ['carbonquest', 'carbonquest-sandbox'],
  name: 'All Parts Import',
  rootCategoryId: 'plant',
  superUserOnly: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: ['size', 'spec', 'replenishment-class']
  },
  fileToRows: {
    xlsx: {
      enable: true,
    }
  },
  // validateBom: {
  //   validators: [{
  //     type: 'duplicate',
  //     message: 'Duplicate part found',
  //     check: '{{project.rootPartNumber}}--{{row.skid_no}}--{{row.line_number}}--{{row.part_number}}'
  //   }]
  // },
  standardizeBom: {
    columns: {
      sources: [{
        distributorName: [{
          conditions: [{
            column: 'vendor_id',
            type: 'eq',
            value: '-',
          }],
          onMatch: {
            type: 'empty',
          }
        }, {
          default: true,
          onMatch: {
            type: 'simple',
            value: '${row.vendor_id}'
          }
        }],
        distributorSku: {
          type: 'simple',
          value: '${row.vendor_item_number}'
        },
      }],
      summary: {
        type: 'ignore'
      },
      metadata: {
        size: {
          value: '{{row.size}}',
          type: 'simple'
        },
        spec: {
          type: 'simple',
          value: '{{row.spec}}',
        },
        'replenishment-class': {
          type: 'simple',
          value: '{{row.replenishment_class}}',
        }
      },
      partNumber: {
        type: 'simple',
        value: '{{row.inventory_id}}',
      },
      hierarchy: {
        value: '1.{{rowIndex}}',
        type: 'simple'
      },
      quantity: {
        value: '1',
        type: 'simple'
      },
      units: {
        value: 'each',
        type: 'quantity'
      },
      categoryId: {
        type: 'split',
        input: '{{row.inventory_id}}',
        splitOn: '-',
        value: '{{parts.0}}',
      },
      isOffTheShelf: {
        type: 'simple',
        value: 'true'
      },
      name: {
          type: 'simple',
          value: '{{row.description}}'
        },
    }
  }
}
