import { useParams } from '@redwoodjs/router'

import CreateChangeOrderCell from 'src/components/CreateChangeOrderCell'
import CreateChangeOrderWithMultiplePartsCell from 'src/components/CreateChangeOrderWithMultiplePartsCell'
import CreateChangeOrder from 'src/components/CreateChangeOrderCell/CreateChangeOrder'

export const CREATE_CHANGE_ORDER = gql`
  mutation CreateChangeOrderMutation ($name: String!, $description: String!) {
    createChangeOrder(input: { name: $name, description: $description }) {
      name
      number
    }
  }
`

export const ADD_TO_NEW_CHANGE_ORDER_MUTATION = gql`
mutation AddToNewChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: $input) {
    partNumber
  }
}
`

const CreateChangeOrderPage = () => {
  const { withPartNumber, withPartNumbers } = useParams();
  if (withPartNumber) {
    return <CreateChangeOrderCell partNumber={withPartNumber} />
  }
  else if (withPartNumbers) {
    const partNumbers = withPartNumbers.split(',').map(atob)
    return <CreateChangeOrderWithMultiplePartsCell partNumbers={partNumbers} />
  }
  return <CreateChangeOrder />
}

export default CreateChangeOrderPage
