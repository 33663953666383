import type { AllUsersQuery } from 'types/graphql'
import * as ListBox from '../ListBox'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

export const QUERY = gql`
  query AllUsersQuery {
    users {
      id
      name
      email
    }
  }
`

export type SelectedUser = {
  id: string
  name: string
  email: string
}
type CellProps = {
  onSelect: (value: SelectedUser) => void
  displayValue: string
  className?: string
  omit?: string[]
  loading?: boolean
};

export const Loading = ({ ...props }: CellProps) => {
  return <UserSelect {...props} users={[]} loading />
}

export const Success = ({ ...props }: CellProps & CellSuccessProps<AllUsersQuery>) => {
    return <UserSelect {...props} />
}

import { FailureErrorBar } from '../Failure/Failure'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading users' {...props}>
    <UserSelect {...props} users={[]} />
  </FailureErrorBar>
}

export const UserSelect = ({ users, onSelect, displayValue, className, omit }: CellProps & CellSuccessProps<AllUsersQuery>) => {
  const containerClass = 'relative ' + className ?? ''
  const values = users.filter(user => {
    if (omit && omit.includes(user.id)) return false
    return true
  })
  const handleSelect = (userId: string) => {
    const user = users.find(user => user.id === userId)!
    onSelect({
      id: user.id,
      name: user.name,
      email: user.email,
    })
  }

  // Empty value, because we are not using the checked value function
  return <ListBox.ListBox onChange={handleSelect} value=''>
    {({ open }) => (
      <>
        <div className={containerClass}>
          <ListBox.Button className='min-w-full' displayValue={displayValue} />
          <ListBox.Options className='min-w-full' open={open}>
            {values.map((user) => (
              <ListBox.Option
                key={user.id}
                className='py-3'
                value={user.id}
                display={`${user.name}`} />
            ))}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}
