import React from 'react';
import classNames from 'classnames';
import * as HeadlessForm from '@redwoodjs/forms'
import { SubmitButton } from './Button'
import { twMerge } from 'tailwind-merge'
import {
  FormTextField,
  FormNumberField,
  FormCheckboxField,
  FormDatetimeField,
  FormUrlField,
} from './Input'
import * as ListBox from './ListBox'
import { codes as currencyCodes } from 'currency-codes'

export const Form = HeadlessForm.Form

type LabelProps = {
  children: React.ReactNode
  className?: string
} & React.HTMLProps<HTMLLabelElement>

export const Label: React.FC<LabelProps> = (props) => {
  const className = twMerge(
    'block text-sm font-medium leading-5 text-gray-900',
    props.className
  )
  return <label {...props} className={className}>
    {props.children}
  </label>
}

type TextAreaProps = React.ComponentProps<typeof HeadlessForm.TextAreaField>

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const className = twMerge(
    'px-3 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-500 text-sm leading-6',
    props.className
  )
  return <HeadlessForm.TextAreaField
    {...props}
    className={className}
    />
}

export const Submit = SubmitButton

export const TextField = FormTextField
export const NumberField = FormNumberField
export const CheckboxField = FormCheckboxField
export const DatetimeField = FormDatetimeField
export const UrlField = FormUrlField

export const FormError = HeadlessForm.FormError
export const BasicFormError = (props: Parameters<typeof HeadlessForm.FormError>[0]) => {
  const { wrapperClassName: passed, ...rest } = props;
  const wrapperClassName = classNames(
    'text-white bg-red-500 w-full p-2 rounded',
    passed
  )
  return <HeadlessForm.FormError {...rest} wrapperClassName={wrapperClassName}/>
}
export const FieldError = (props: HeadlessForm.FieldErrorProps & {chill?: boolean}) => {
  const { className: passedClassNames, chill, ...rest } = props;
  const className = classNames(
    'text-white bg-red-500 w-full p-4 rounded',
    chill && '!p-2 !bg-rose-400',
    passedClassNames,
  )
  return <HeadlessForm.FieldError {...rest} className={className}/>
}
//export const RawFieldError = HeadlessForm.FieldError

export const RadioField = HeadlessForm.RadioField
export const useErrorStyles = HeadlessForm.useErrorStyles

type PriceFieldProps = {
  className?: string
  name: string
  defaultValue: string
  defaultUnit: string
  required?: boolean
  placeholder?: string
  size?: 'sm' | 'base'
}
export const PriceField: React.FC<PriceFieldProps> = (props) => {
  const { required } = props
  const size = props.size || 'base'
  const numberSizeClass = classNames({
    'text-xs pl-2 py-1.5 w-16': size === 'sm'
  })
  const currencySizeClass = classNames({
    'text-xs leading-none py-1.5': size === 'sm'
  })
  return <div className='flex ring-1 ring-inset ring-gray-300 rounded-md items-center'>
    <HeadlessForm.NumberField
      name={`${props.name}.value`}
      placeholder={props.placeholder || '-'}
      className={`bg-transparent arrow-hide ${numberSizeClass}`}
      validation={{ required, valueAsNumber: true }}
      defaultValue={props.defaultValue}
      emptyAs="undefined"
      min='0'
      step='any' />
    <ListBox.HookedListBox
      name={`${props.name}.unit`}
      defaultValue={props.defaultUnit}
    >
        <ListBox.UncontrolledButton className={`!bg-transparent ${currencySizeClass} pl-1 pr-6`} hideRing />
        <ListBox.Options>
          {currencyCodes().map(t => (
            <ListBox.Option key={t} className='py-3' value={t} display={t} />
          ))}
        </ListBox.Options>
    </ListBox.HookedListBox>
  </div>
}
