import { useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import PartCompareCell from "src/components/PartCompareCell"


const PartComparePage = () => {
  const { basePartNumber, baseVersion, incomingPartNumber, incomingVersion } = useParams()
  return (
    <>
      <MetaTags title={"#" + basePartNumber} description="Part compare page" />
      <PartCompareCell
        basePartNumber={basePartNumber!}
        baseVersion={baseVersion!}
        incomingPartNumber={incomingPartNumber!}
        incomingVersion={incomingVersion!} />
    </>
  )
}

export default PartComparePage
