import OrgSettingsDistributorsCell from 'src/components/OrgSettingsDistributorsCell'
import { MetaTags } from '@redwoodjs/web'

const OrgSettingsDistributorsPage = () => {
  return (
    <>
      <MetaTags
        title="Distributors Settings"
        description="Company Distributors Settings"
      />
      <OrgSettingsDistributorsCell/>
    </>
  )
}

export default OrgSettingsDistributorsPage
