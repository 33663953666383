import { useState, useReducer, useMemo, useRef } from 'react';
import { registerFragment } from '@redwoodjs/web/apollo'
import { produce } from 'immer';

import type {
  PartQuery,
  PartQueryVariables,
  Project,
  User,
  ChangeOrderLog,
  AddToChangeOrderMutation,
  AddToChangeOrderMutationVariables,
} from 'types/graphql'
import { DocumentIcon, TagIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import { ArrowTopRightOnSquareIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import type { ChangeOrderLogPayload } from 'shared/types'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import type { ExchangeRates } from 'api/src/lib/exchangeRates';

import { addVersionWarnings } from '../PartHierarchy/PartHierarchy';
import { Sources } from '../Sources';
import { reportMutationError } from 'src/lib/reportError'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import ChangeOrderSelectCell from 'src/components/ChangeOrderSelectCell'
import { routes, navigate, useParams, Link } from '@redwoodjs/router';
import { prettyDate } from 'src/lib/formatters'
import * as ListBox from '../ListBox';
import Button from 'src/components/Button'
import { Overview } from '../PartOverview/PartOverview';
import { Artifacts } from '../Artifacts/Artifacts'
import { getSelection as getHierarchySelection, useHierarchyControllerFactory, HierarchyContainer } from '../PartHierarchy/PartHierarchy';
import PartHierarchyCell from '../PartHierarchyCell'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import PartSettingsDialog from './PartSettings'
import { useMetadataSchema } from 'src/lib/metadata'
import { ConditionalModal } from '../Modal';
import CreatePartCell from '../CreatePartCell';
import { buildHierarchyTree, SumAggregator, MaxAggregator } from './aggregation';
import { ExportDialog, ExportMapperContext } from './ExportDialog';
import { getMapper } from 'src/lib/mapping'

import { StatusDot } from '../LifecycleStatus/LifecycleStatus';
import * as Tooltip from "src/components/ToolTip"
import FullScreenHierarchy, { EditableAggregator, FullScreenHierarchyView } from './FullScreenHierarchy'
import GenericFailure from '../Failure/Failure'
import ChangeOrderSelectDialog from '../ChangeOrderSelectDialog/ChangeOrderSelectDialog';

export type QueryPartHierarchy = NonNullable<PartQuery['partProto']>['instance']['hierarchy']

export const aggregationConfigFragment = registerFragment(gql`
  fragment AggregationConfigsFragment on Organization {
    aggregationConfigs {
      name
      reducer
      targetType {
        type
        unit
      }
      metadata {
        key
        multiplyByQuantity
      }
      sources {
        key
        multiplyByQuantity
      }
    }
  }
`)

export const QUERY = gql`
  query PartQuery($partNumber: String!, $version: String, $branch: Int) {
    currentOrg {
      id
      distributors {
        name
      }
      metadataSchema
      manualExportMapper
      exchangeRates
      ...AggregationConfigsFragment
    }
    partProto(partNumber: $partNumber) {
      project {
        id
        name
        link
        defaultMapper
      }
      category {
        id
        name
        label
      }
      inChangeOrders {
        number
        name
        state
        log {
          user {
            name
          }
          id
          action
          createdAt
          payload
        }
      }
      partNumber
      currentVersion {
        version
        publishId
        lifeCycle
      }
      owner {
        id
        name
      }
      categoryId
      protoUpdatedBy {
        name
      }
      instance(version: $version, branch: $branch) {
        name
        cadRev
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          stock
        }
        summary
        version
        lifeCycle
        isRoot
        isOffTheShelf
        partNumber
        hierarchy {
          hierarchy
          part {
            name
            cadRev
            version
            lifeCycle
            summary
            partNumber
            metadata
            sources {
              id
              url
              comment
              distributorId
              distributor {
                id
                name
              }
              priority
              distributorSku
              price
              priceCurrency
              leadTimeDays
              stock
            }
            artifacts {
              filename
              file {
                url
                inlineUrl
                contentType
                size
              }
            }
            proto {
              currentVersionString
              category {
                id
                name
              }
            }
          }
          dependency {
            quantity
            units
            toVersionRange
            referenceDesignator
            groupId
          }
        }
        metadata
        integrationType
        documentType
        externalDocRef
        artifacts {
          id
          filename
          fileId
          file {
            confirmed
            path
            url
            inlineUrl
            contentType
            size
          }
        }
        dependencies {
          section
          to {
            proto {
              partNumber
              currentVersionString
            }
            branch
            partNumber
            name
            version
          }
          toVersionRange
          quantity
          units
          referenceDesignator
        }
      }
      forkFrom {
        partNumber
        version
      }
      instances {
        publishId
        version
        branch
        inChangeOrder {
          name
        }
      }
    }
  }
`

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

export const Empty = () => <div>Empty</div>

export const Failure = GenericFailure

export const Success = ({ currentOrg, ...props }: CellSuccessProps<PartQuery, PartQueryVariables>) => {
  return <ExportMapperContext.Provider value={currentOrg.manualExportMapper}>
    <Part {...props} aggregationConfigs={currentOrg.aggregationConfigs} exchangeRates={currentOrg.exchangeRates} />
  </ExportMapperContext.Provider>
}

const Part = ({
  partProto,
  aggregationConfigs,
  exchangeRates,
}: Omit<CellSuccessProps<PartQuery, PartQueryVariables>, 'currentOrg'> &
  Pick<CellSuccessProps<PartQuery, PartQueryVariables>['currentOrg'], 'aggregationConfigs' | 'exchangeRates'>
) => {
  if (!partProto) throw new Error('No part found')
  const { partNumber, instances, instance, inChangeOrders, owner, currentVersion } = partProto;
  // for part hierarchy input
  const instanceWithProto = { ...instance, proto: partProto }

  const params = useParams()
  const orgId = params.orgId!
  const materialsParam = new URLSearchParams(location.search).has('materials')
  const [_, rerender] = useReducer(x => x + 1, 0)

  const setMaterialsParam = (v: string | null) => {
    const searchParams = new URLSearchParams(location.search)
    if (v) { searchParams.set('materials', v) }
    else { searchParams.delete('materials') }
    history.replaceState(null, "", searchParams.size ? `${location.pathname}?${searchParams.toString()}` : location.pathname)
    rerender()
  }

  const metadataSchema = useMetadataSchema()!

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [partSettingsOpen, setPartSettingsOpen] = useState(false)
  const [forkPartOpen, setForkPartOpen] = useState(false)
  const handleMoreSelect = (option: 'PartSettings' | 'PartCompare' | 'Fork') => {
    if (option === 'PartSettings') {
      setPartSettingsOpen(true)
      setForkPartOpen(false)
    }
    else if (option === 'PartCompare') {
      navigate(
        routes.partCompare({
          basePartNumber: partNumber,
          incomingPartNumber: partNumber,
          baseVersion: instance.version,
          orgId,
          incomingVersion: currentVersion!.version!
        })
      )
    }
    else if (option === 'Fork') {
      setPartSettingsOpen(false)
      setForkPartOpen(true)
    }
  }

  const forkPrefix = '__fork__' as const;
  const forkSeparator = '~~~' as const;
  const handleVersionSelection = (newVersion: string) => {
    if (newVersion === '__full__') {
      navigate(routes.partHistory({ partNumber, orgId }))
    }
    else if (newVersion.startsWith(forkPrefix)) {
      const [partNumber, version] = newVersion.substring(forkPrefix.length).split(forkSeparator) as [string, string]
      navigate(routes.partWithVersion({ partNumber, version, orgId }))
    }
    else if (newVersion === instance.version) {
      navigate(routes.part({ partNumber, orgId }))
    }
    else {
      navigate(routes.partWithVersion({ partNumber, version: newVersion, orgId }))
    }
  }

  const liveInstances = instances.filter(p => {
    // latest version, not sure what happens when added to a change order? maybe new version
    // if (!p.inChangeOrder) return true

    // main branch
    return p.branch === -1
  })
  liveInstances.sort((a, b) => {
    // those without publish ID will be filtered anyway
    return b.publishId! - a.publishId!
  })

  const parentDownloads = instance.artifacts.map(a => {
    return {
      source: a.file.url,
      destination: `${partNumber}v${instance.version} - ${instance.name}/${a.filename}`
    }
  })
  const descendentDownloads = instance.hierarchy
    .reduce((deduped, h, i) => {
      const exists = deduped.some(e => {
        return h.part.partNumber === e.part.partNumber &&
          h.part.version === e.part.version
      })
      if (!exists) {
        return [...deduped, h]
      }
      return deduped
    }, [] as typeof instance.hierarchy)
    .flatMap(h => {
      return h.part.artifacts.map(a => {
        return {
          source: a.file.url,
          destination: `${h.part.partNumber}v${h.part.version} - ${h.part.name}/${a.filename}`
        }
      })
    })

  const appliedChangeOrders = inChangeOrders.filter(c => c.state === 'Complete')
  type LastPublishLog = Pick<ChangeOrderLog, 'createdAt'> & {
    user: Pick<User, 'name'>
  }
  type LastPublishInfo = {
    log?: LastPublishLog
    orderNumber: number
    orderName: string
  }
  const lastPublish = appliedChangeOrders.reduce((latestApply, c) => {
    return c.log.reduce((latestApply, l) => {
      if (l.action !== 'ChangeState') return latestApply
      const payload = l.payload as ChangeOrderLogPayload['ChangeState']
      if (payload.state !== 'Complete') return latestApply
      if (!latestApply.log || l.createdAt > latestApply.log.createdAt) {
        return {
          log: l,
          orderNumber: c.number,
          orderName: c.name
        }
      }
      return latestApply
    }, latestApply)
  }, {} as LastPublishInfo)

  const lastPublishMessage = () => {
    if (!lastPublish.log?.createdAt) {
      return <>Part not published since initial import</>
    }

    const date = prettyDate(new Date(lastPublish.log?.createdAt))
    return <>{date} in <a className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: lastPublish.orderNumber, tab: 'changes' })}>
      #{lastPublish.orderNumber} {lastPublish.orderName}
    </a></>
  }

  const openChangeOrders = inChangeOrders.filter(c => c.state !== 'Complete' && c.state !== 'Cancelled')
  const changeOrdersList = openChangeOrders.length === 0 ?
    <div className='text-sm text-gray-800'>This part is not in any change orders</div> :
    <>{openChangeOrders.map(c => {
      return <a key={c.number} className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}>
        #{c.number} {c.name}
      </a>
    })}</>

  const [addToChangeOrderMutation] = useMutation<AddToChangeOrderMutation, AddToChangeOrderMutationVariables>(ADD_TO_CHANGE_ORDER_MUTATION)

  interface ChangeOrderWithNumber {
    number: number
  }
  const addToChangeOrder = async (c: ChangeOrderWithNumber, input: AddToChangeOrderMutationVariables['input']) => {
    const variables: AddToChangeOrderMutationVariables = {
      changeOrderNumber: c.number,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }
  const handleAddToChangeOrder = async (c: ChangeOrderWithNumber) => {
    await addToChangeOrder(c, [{
      type: 'Push',
      partNumber,
      version: calculateNextVersion(currentVersion!.version!),
    }])

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}#part-change-${partNumber}`)
  }

  const handleAddToChangeOrderWithSelection = async (c: ChangeOrderWithNumber) => {
    //TODO: maybe add modal here to confrim, or else it is a bit of a hot wire

    const filtedHierarchy = getHierarchySelection(instanceWithProto.hierarchy, selectedNodes)

    const input = [{
      type: 'Push' as const,
      partNumber,
      version: calculateNextVersion(currentVersion!.version!),
    }]

    input.push(...filtedHierarchy.map(n => ({
      type: 'Push' as const,
      partNumber: n.part.partNumber,
      version: calculateNextVersion(n.part.proto.currentVersionString!),
    }))
    )

    await addToChangeOrder(c, input)

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}#part-change-${partNumber}`)
  }

  const handleCreateChangeOrder = () => {
    navigate(routes.changeOrderCreate({ orgId, withPartNumber: partNumber }))
  }

  const handleCreateChangeOrderWithSelection = () => {
    const filtedHierarchy = getHierarchySelection(instanceWithProto.hierarchy, selectedNodes)

    const opaquePartNumbers = btoa(partNumber) + ',' + filtedHierarchy.map(n => btoa(n.part.partNumber)).join(',')

    navigate(routes.changeOrderCreate({ orgId, withPartNumbers: opaquePartNumbers }))
  }

  const [selectNodesMode, setSelectNodesMode] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState<string[]>([])

  const handleSetSelectNodesMode = (flag: boolean) => {
    if (!flag) {
      setSelectedNodes([])
      setSelectNodesMode(false)
    }
    else {
      setSelectedNodes(instanceWithProto.hierarchy.map(h => h.hierarchy))
      setSelectNodesMode(true)
    }
  }

  const selectedHierarchy = getHierarchySelection(instance.hierarchy, selectNodesMode ?
    selectedNodes : instanceWithProto.hierarchy.map(h => h.hierarchy))

  const hierarchyTree = buildHierarchyTree(instance, selectedHierarchy)

  const savedAggregators = useMemo(() =>
    aggregationConfigs?.map(c => {
      const reducerInput = {
        metadataSchema,
        name: c.name,
        exchangeRates: exchangeRates as ExchangeRates,
        targetType: {
          type: c.targetType.type,
          unit: c.targetType.unit
        },
        metadata: c.metadata,
        sources: c.sources
      }
      if (c.reducer === 'Sum') {
        return SumAggregator(reducerInput)
      }
      if (c.reducer === 'Max') {
        return MaxAggregator(reducerInput)
      }
    }
    ) ?? [],
    [aggregationConfigs]
  )

  const [aggregators, setAggregators] = useState<EditableAggregator[]>(
    (savedAggregators ?? []).map((a, index) => ({ ...a, index, savedIndex: index, isActive: materialsParam }))
  )

  const subjectParts = addVersionWarnings(instanceWithProto)

  const hierarchyController = useHierarchyControllerFactory({
    rootPart: instanceWithProto,
    hierarchyTree,
    subjectParts,

    aggregators,
    setAggregators,

    selectable: selectNodesMode, //probably wrong
    setSelectable: handleSetSelectNodesMode,
    selectedNodes,
    setSelectedNodes,

    initiallyExpandedNodes: materialsParam ? 'all' : ['1'],

    stickyName: materialsParam
  })

  const handleSetMaterialsParam = (flag: boolean) => {
    setAggregators(produce(d => {
      d.forEach(a => a.isActive = flag)
    }))
    setMaterialsParam(flag ? "true" : null)
  }

  const { getActiveAggregators } = hierarchyController
  const activeAggregators = getActiveAggregators()

  const initialMaterialsView = useRef<FullScreenHierarchyView | null>(null)
  const [showAddToChangeOrderDialog, setShowAddToChangeOrderDialog] = useState(false)

  type AggOptionsSelection = 'configureAggs' | 'exportSelection' | 'addToChangeOrder'
  const handleAggOptionsSelect = (value: AggOptionsSelection) => {
    switch (value) {
      case 'configureAggs':
        initialMaterialsView.current = 'agg'
        handleSetMaterialsParam(true)
        break;
      case 'exportSelection':
        setExportDialogOpen(true)
        break;
      case 'addToChangeOrder':
        setShowAddToChangeOrderDialog(true)
        break;
    }
  }

  if (materialsParam) {
    return <>
      <ExportDialog open={exportDialogOpen} setOpen={setExportDialogOpen} hierarchy={selectedHierarchy} tree={hierarchyTree} part={instance} downloads={[...parentDownloads, ...descendentDownloads]} />
      <FullScreenHierarchy
        hierarchyController={hierarchyController}
        initialMode={initialMaterialsView}
        savedAggregators={savedAggregators}
        exchangeRates={exchangeRates as ExchangeRates}
        openChangeOrders={openChangeOrders}
        onCreateChangeOrderWithSelection={handleCreateChangeOrderWithSelection}
        onSelectChangeOrderWithSelection={handleAddToChangeOrderWithSelection}
        setExportDialogOpen={setExportDialogOpen}
        onClose={() => handleSetMaterialsParam(false)}
      />
    </>
  }
  const aggregationSelect = (
    <ListBox.ListBox multiple value={activeAggregators.map(a => a.name)} disabled={aggregators.length === 0}

      onChange={names => setAggregators(aggregators.map(a => ({
        ...a,
        isActive: names.includes(a.name)
      })))}>
      {({ open }) => (<>
        <div className="relative">
          <ListBox.Button size='sm' displayValue={'View Aggregation'} disabled={aggregators.length === 0} />
          <ListBox.Options open={open} className='z-50 text-xs'>
            {
              aggregators.map(a =>
                <ListBox.Option key={a.name} value={a.name} display={a.name} />
              )
            }
          </ListBox.Options>
        </div>
      </>
      )}
    </ListBox.ListBox>
  )

  return (<>
    <ExportDialog open={exportDialogOpen} setOpen={setExportDialogOpen} hierarchy={selectedHierarchy} tree={hierarchyTree} part={instance} downloads={[...parentDownloads, ...descendentDownloads]} />
    {forkPartOpen &&
      <ConditionalModal className='w-fit mt-10' onClose={() => setForkPartOpen(false)}>
        <CreatePartCell variant='fork' forkPart={partProto} onComplete={() => setForkPartOpen(false)} />
      </ConditionalModal>
    }
    {showAddToChangeOrderDialog &&
      <ChangeOrderSelectDialog
        onSelect={(selected) => {
          if (selected.createChangeOrder) {
            handleCreateChangeOrderWithSelection()
          }
          if (selected.changeOrderNumber !== null) {
            handleAddToChangeOrderWithSelection({ number: selected.changeOrderNumber })
          }
          setShowAddToChangeOrderDialog(false)
        }}
        onCancel={() => setShowAddToChangeOrderDialog(false)} />
    }
    {partSettingsOpen &&
      <PartSettingsDialog open={partSettingsOpen} onClose={() => setPartSettingsOpen(false)} partProto={partProto} refetchQueries={[QUERY]} />
    }
    <div className='flex flex-col gap-2 pt-16' data-testid='part'>
      <div className='rounded-lg border border-gray-300 flex flex-col'>
        {/* Header */}
        <div className={`bg-brandBlue-400 pl-10 py-6 pr-6 rounded-t-lg flex flex-col gap-6 text-sm`}>
          <div className='flex items-center mb-4 gap-3'>
            <div className='grow'>
              <div className='flex flex-row gap-2 items-center'>
                <TagIcon className='h-3 w-3' /><div>{partProto.category.name}</div>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <div>
                  <StatusDot lifeCycle={instance.lifeCycle} />
                </div>
                <div className='text-xl flex-1 text-gray-900'><span className='font-semibold'>#{partNumber}</span> {instance.name}</div>
              </div>
            </div>
            <ListBox.ListBox value={instance.version} onChange={handleVersionSelection}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button className='' displayValue={`Version ${instance.version}`} />
                  <ListBox.Options open={open}>
                    <div className='border-b border-gray-200'>
                      {liveInstances.map(i =>
                        <ListBox.Option value={i.version} display={`Version ${i.version}`} key={i.version} />
                      )}
                    </div>
                    <ListBox.Option value={'__full__'} display={`View Full History`} key={'__full__'} />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
            <ChangeOrderSelectCell
              disabledChangeOrders={inChangeOrders.map(o => o.number)}
              className=''
              placeholder='Add to Change Order'
              onCreate={handleCreateChangeOrder}
              onSelect={handleAddToChangeOrder} />
            <ListBox.ListBox value='' onChange={handleMoreSelect}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button displayValue={`More`} />
                  <ListBox.Options open={open}>
                    <ListBox.Option value='PartSettings' display={`Part Settings`} key={'PartSettings'} />
                    <ListBox.Option value='PartCompare' display={`Compare`} key={'PartCompare'} />
                    <ListBox.Option value='Fork' display={`Fork`} key={'Fork'} />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
          </div>
          <div className='flex gap-6'>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Owner
              </div>
              <div className='text-sm text-gray-800'>
                {owner.name}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Last Published
              </div>
              <div className='text-sm text-gray-800'>
                {lastPublishMessage()}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Change Orders
              </div>
              {changeOrdersList}
            </div>
            {partProto.forkFrom &&
              <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
                <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                  Forked From
                </div>
                <div className='text-sm text-gray-800'>
                  <Link to={routes.partWithVersion({ partNumber: partProto.forkFrom.partNumber, version: partProto.forkFrom.version, orgId })} className='underline text-blue-700 font-sans'>
                    #{partProto.forkFrom.partNumber} v{partProto.forkFrom.version}
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
        {/* Body */}
        <div className='flex flex-col gap-10 px-10 pt-6 pb-10'>
          <div>
            <div
              className='text-md text-gray-900 font-medium h-10'>Overview</div>
            <Overview part={instance} />
          </div>
          {partProto.project ? <ProjectSection orgId={orgId} project={partProto.project} /> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>Artifacts</div>
            <Artifacts partNumber={partNumber} part={instance} />
          </div>
          {
            instance.dependencies.length ?
              <div className='flex flex-col gap-6'>
                <div className='flex gap-2 items-center'>
                  <div className='flex-1'>Materials</div>
                      <Button size='xs' className='flex items-center gap-2 px-2 pl-1' type='button'
                        data-testid={'select-parts'}
                        onClick={() => handleSetSelectNodesMode(!selectNodesMode)}
                      >
                        <input
                          type="checkbox"
                          checked={hierarchyController.selectable}
                          aria-describedby="select-children"
                          className="h-3.5 w-3.5 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
                        /><div>Select Parts</div>
                      </Button>
                      {aggregators.length ? aggregationSelect :
                        <Tooltip.Container>
                          <div className='group' tabIndex={0}>
                            {aggregationSelect}
                            <Tooltip.Message className="group-focus:visible group-focus:z-50 bottom-11 w-48">
                              No aggregations available, configure aggregations to add one.
                            </Tooltip.Message>
                          </div>
                        </Tooltip.Container>
                      }
                      <ListBox.ListBox value='' onChange={handleAggOptionsSelect}>
                        {({ open }) => (<>
                          <div className="relative inline-block">
                            <ListBox.Button noChevron data-testid={'materials-more'} wrapperClassName='' size='sm' className='px-1 text-center block !leading-[0px]' displayValue={<EllipsisVerticalIcon className='w-4 inline' />} />
                            <ListBox.Options open={open} className='max-h-[70vh] text-xs'>
                              <ListBox.Option value='configureAggs' display={`Configure Aggregations`} />
                              <ListBox.Option testId={'materials-more-export'} value='exportSelection' display={`Export` + (hierarchyController.selectable ? ' Selection' : '')} />
                              <ListBox.Option testId={'materials-more-change-order'} value='addToChangeOrder' display={`Add${hierarchyController.selectable ? ' Selection' : ''} to Change Order`} />
                            </ListBox.Options>
                          </div>
                        </>
                        )}
                      </ListBox.ListBox>
                      <Button size='xs' type='button'
                        onClick={() => handleSetMaterialsParam(true)}
                      >
                        <ArrowsPointingOutIcon className='w-4' />
                      </Button>
                </div>
                <div className='flex items-center gap-2 text-md text-gray-900 font-medium justify-between -mt-3'>
                  <div className='ml-auto'>

                  </div>
                </div>
                <div className='overflow-x-auto'>
                  <HierarchyContainer alignX='children' headerConfig={{ hierarchyController }} controllers={hierarchyController} />
                </div>
              </div>
              : null}
          {instance.sources.length ? <Sources sources={instance.sources} /> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>
              Where Used
            </div>
            <div className='mb-4 -mx-1'>
              <PartHierarchyCell highlightedPartNumber={instance.partNumber} />
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}

type ProjectProps = {
  project: Pick<Project, 'id' | 'name' | 'link' | 'defaultMapper'>
  orgId: string
}
const ProjectSection: React.FC<ProjectProps> = ({
  project,
  orgId
}) => {
  const mapper = getMapper(orgId, project.defaultMapper)
  const Logo = mapper?.Logo || DocumentIcon
  const fileBox = <div className='-ml-1 relative border border-gray-300 rounded-xl items-center px-8 py-6 inline-flex gap-4 flex-col'>
    <div className='w-28 h-12 flex items-center justify-center'>
      <Logo className='max-h-12 max-w-28' />
    </div>
    <div className='text-gray-700 flex gap-3 items-center'>
      {project.name}
    </div>
    {project.link ?
      <ArrowTopRightOnSquareIcon className='absolute right-2 top-2 h-5 w-5' /> : null
    }
  </div>
  const fileButton = project.link ?
    <a target='_blank' href={project.link}>{fileBox}</a> :
    <div>{fileBox}</div>
  return <div className='flex-1'>
    <div className='text-md text-gray-900 font-medium mb-4'>Project</div>
    <div className='text-sm text-gray-700 mb-4'>
      This part is a project root assembly
    </div>
    {fileButton}
  </div>
}
