import { Dispatch, HTMLAttributes, SetStateAction } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { routes, useParams } from '@redwoodjs/router'

import { XCircleIcon, ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/20/solid'
import { ScaleIcon } from '@heroicons/react/16/solid'
import { groupBy } from 'src/lib/util'

import { HierarchyTreeRootNode, AggregationRootNode, AggregationNode, Aggregator, NumberTarget, buildAggregationTree } from 'src/components/PartCell/aggregation';

import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { useRequiredContext } from 'src/lib/appContext'

import type {
  Part,
  PartHierarchy,
  Dependency,
  PartCategory
} from 'types/graphql'

import { validVersion } from 'src/lib/version'

import { AppLink } from 'src/lib/routing'
import { ReactNode, useState, createContext, useContext } from 'react'
import { SwatchIcon } from '@heroicons/react/24/outline';
import padEnd from 'lodash.padend'
import * as Tooltip from "src/components/ToolTip"
import { letF } from 'api/src/shared/functional'
import { AlwaysUpdateIcon, PinnedIcon } from '../VersionRange/VersionRange'
import { EditableAggregator } from '../PartCell/FullScreenHierarchy'

export type ActivatableAggregator = Aggregator & { isActive?: boolean }

export interface HierarchyController {
  rootPart: RequiredPart
  subjectParts: SubjectPart[]

  hierarchyTree?: HierarchyTreeRootNode,

  showAll?: boolean
  aggregators: ActivatableAggregator[]
  setAggregators?: Dispatch<SetStateAction<ActivatableAggregator[]>>
  getActiveAggregators(): ActivatableAggregator[]
  activeAggregators: ActivatableAggregator[]

  targetCurrency?: NumberTarget
  setTargetCurrency: Dispatch<SetStateAction<NumberTarget | undefined>>

  aggregations?: AggregationRootNode | undefined

  stickyName?: boolean
  columns: PartHierarchyHeaderColumn[]
  setColumns: (columns: PartHierarchyHeaderColumn[]) => void

  selectable?: boolean
  setSelectable?: (flag: boolean) => void
  selectedNodes: string[]
  setSelectedNodes: (nodes: string[]) => void

  initiallyExpandedNodes: string[] | 'all'
  //expandedNodes?: string[]
  //setExpandedNodes: (nodes: string[]) => void
}

const baseColumns: PartHierarchyHeaderColumn[] = [{
  name: 'Quantity',
  key: 'quantity',
  type: 'field',
  width: 50,
  align: 'center'
}, {
  name: 'Designator',
  key: 'referenceDesignator',
  type: 'field',
  width: 100,
}, {
  name: 'Version',
  key: 'version',
  type: 'field',
  width: 80,
  align: 'left'
}, {
  name: 'Category',
  key: 'category',
  type: 'field',
  width: 150,
}]

const mergeIntoBaseColumns = (passedColumns?: PartHierarchyHeaderColumn[]): PartHierarchyHeaderColumn[] => {
  return baseColumns.map(c => {
    if (!passedColumns) return c
    const conf = passedColumns.find(conf => conf.type === c.type && conf.key === c.key)
    if (!conf) return c
    return {
      ...c,
      ...conf
    }
  })
}

const voidF = () => undefined
type OptionalInputs<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type HierarchyControllerFactoryParams = Omit<
  OptionalInputs<HierarchyController, 'columns' | 'setColumns' | 'selectedNodes' | 'setSelectedNodes' | 'subjectParts' | 'aggregators'>, // 'setExpandedNodes' |
  'getActiveAggregators' | 'activeAggregators'
>

const makeGetActiveAggregators = (properties: HierarchyControllerFactoryParams) => () =>
  properties.aggregators?.filter(a => a.isActive) ?? []
export const hierarchyControllerFactory = (properties: HierarchyControllerFactoryParams): HierarchyController => {
  const getActiveAggregators = makeGetActiveAggregators(properties)
  const activeAggregators = getActiveAggregators()

  const usedAggs = properties.showAll ? (properties.aggregators ?? []) : activeAggregators

  const aggregations = properties.aggregations || properties.hierarchyTree && activeAggregators &&
    buildAggregationTree(properties.hierarchyTree, usedAggs, { targetOverride: properties.targetCurrency })

  return Object.seal({
    ...properties,
    subjectParts: properties.subjectParts ?? [],
    columns: mergeIntoBaseColumns(properties.columns) ?? baseColumns,
    setColumns: properties.setColumns ?? voidF,
    selectedNodes: properties.selectedNodes ?? [],
    setSelectedNodes: properties.setSelectedNodes ?? voidF,
    aggregators: properties.aggregators ?? [],
    getActiveAggregators,
    activeAggregators,
    setTargetCurrency: properties.setTargetCurrency ?? voidF,
    aggregations
  })
}

export const useHierarchyControllerFactory = (properties: HierarchyControllerFactoryParams): HierarchyController => {
  //do always, but only use sometimes. Initialize empty when unused for memory performance
  const [hookedColumns, setHookedColumns] = useState(properties.columns ? [] : baseColumns)

  const columns = properties.columns ? mergeIntoBaseColumns(properties.columns) : hookedColumns
  const setColumns = (properties.columns && properties.setColumns) ? properties.setColumns
    : !properties.columns ? setHookedColumns : voidF

  const getActiveAggregators = makeGetActiveAggregators(properties)
  const activeAggregators = getActiveAggregators()

  const [targetCurrency, setTargetCurrency] = useState<NumberTarget | undefined>()

  const usedAggs = properties.showAll ? (properties.aggregators ?? []) : activeAggregators

  const aggregations = properties.aggregations || properties.hierarchyTree && activeAggregators &&
    buildAggregationTree(properties.hierarchyTree, usedAggs, { targetOverride: targetCurrency })

  const hierarchy = properties.rootPart.hierarchy.map(node => {
    if (!node.dependency) return node

    // only show this icon when the version range changes
    const alwaysUpdateIcon = !node.previousVersionRange || node.previousVersionRange === node.dependency.toVersionRange ? null : <AlwaysUpdateIcon />

    const versionIcon = node.dependency.toVersionRange === '*' ?
      alwaysUpdateIcon : <PinnedIcon version={node.part.version as string} />

    const versionChange = () => {
      if (!node.previousVersion) return
      if (!node.previousVersionRange) return
      if (!node.dependency) throw Error('Dependency should be there')
      if (node.previousVersion === node.part.version &&
        node.previousVersionRange === node.dependency.toVersionRange) return

      const versionIcon = node.previousVersionRange === '*' ?
        alwaysUpdateIcon : <PinnedIcon version={node.previousVersion} />

      return <>
        <div>{node.previousVersion}</div>
        {versionIcon}
        <div>→</div>
      </>
    }

    return {
      ...node,
      part: {
        ...node.part,
        version: <div className='flex items-center gap-1'>
          {versionChange()}
          <div>{node.part.version}</div>
          {versionIcon}
        </div>
      }
    }
  })

  const rootNode = { ...properties.rootPart }
  const previousRootVersion = rootNode.previousVersion ? <>
    <div>{rootNode.previousVersion}</div>
    <div>→</div>
  </> : null
  rootNode.version = <div className='flex items-center gap-1'>
    {previousRootVersion}
    <div>{rootNode.version}</div>
  </div>
  rootNode.hierarchy = hierarchy

  return Object.seal({
    ...properties,
    rootPart: rootNode,
    subjectParts: properties.subjectParts ?? [],
    columns,
    setColumns,
    selectedNodes: properties.selectedNodes ?? [],
    setSelectedNodes: properties.setSelectedNodes ?? voidF,
    aggregators: properties.aggregators ?? [],
    getActiveAggregators,
    activeAggregators,
    targetCurrency,
    setTargetCurrency,
    aggregations,
  })
}

export const WithHierarchyController = <T extends ((c: HierarchyController) => ReactNode)>({ factoryParams, render }: { factoryParams: HierarchyControllerFactoryParams, render: T }) => {
  const controller = useHierarchyControllerFactory(factoryParams)
  return render(controller)
}

const controllerComplete = (i: HierarchyControllerFactoryParams | HierarchyController): i is HierarchyController =>
  Object.isSealed(i)

export const HierarchyControllerContext = createContext<HierarchyController | null>(null)

export type RequiredPartHierarchy = Pick<PartHierarchy, 'hierarchy'> & {
  part: Pick<Part, 'partNumber' | 'name' | 'lifeCycle'> & {
    version: React.ReactNode
    proto: {
      currentVersionString?: string | undefined | null
      category: Pick<PartCategory, 'name'>
    }
  },
  previousVersion?: string
  previousVersionRange?: string
  dependency?: Pick<Dependency, 'quantity' | 'units' | 'referenceDesignator' | 'groupId' | 'toVersionRange'>
}

type RequiredPart = Pick<Part, 'partNumber' | 'name' | 'lifeCycle'> & {
  version: React.ReactNode
  hierarchy: RequiredPartHierarchy[]
  proto: {
    category: Pick<PartCategory, 'name'>
  }
  previousVersion?: string
}

export const getSelection = (hierarchy: RequiredPartHierarchy[], selectedNodes: string[]): RequiredPartHierarchy[] => {
  const filteredHierarchy: RequiredPartHierarchy[] = []

  let scanIdx = 0;
  while (scanIdx < hierarchy.length) {
    const element = hierarchy[scanIdx]!
    if (selectedNodes.includes(element.hierarchy)) {
      filteredHierarchy.push(element)
      scanIdx += 1;
    }
    else {
      const childSkipper = element.hierarchy + '.'
      const lastSkippedChild = hierarchy.findLastIndex(e => e.hierarchy.startsWith(childSkipper))
      if (lastSkippedChild === -1) {
        scanIdx += 1;
      }
      else {
        scanIdx = lastSkippedChild + 1;
      }
    }
  }
  return filteredHierarchy;
}

export type SubjectPart = {
  hierarchy: string
  className?: string
  message?: ReactNode
  hideChildren?: boolean
}

type PartHierarchyExplorerProps = {
  hierarchyController: HierarchyController
  withCreateAggSpacer?: boolean
  hasHorizontalScroll?: boolean
}

const findChildren = (fullHierarchy: RequiredPartHierarchy[], hierarchyLevel: string) => {
  const hierarchy = hierarchyLevel.split('.');
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  return fullHierarchy
    .filter(p => childFinder.test(p.hierarchy))
}

export type PartHierarchyHeaderColumn = {
  name: string
  width: number
  key: string
  type: 'field' | 'metadata' | 'aggregation'
  align?: 'center' | 'right' | 'left'
}

const BranchDisabledContext = createContext<boolean>(false)

type PartHierarchyContainerProps = {
  headerConfig?: Parameters<typeof PartHierarchyHeader>[0] | 'none'
  alignX?: 'header' | 'children'
  controllers: HierarchyControllerFactoryParams | HierarchyControllerFactoryParams[]
}
export const HierarchyContainer = ({
  alignX = 'header',
  headerConfig,
  controllers,
}: PartHierarchyContainerProps) => {
  const factoryInputs = Array.isArray(controllers) ? controllers : [controllers]
  return (
    <div className='flex flex-col gap-4'>
      {headerConfig !== 'none' &&
        <div className={`${alignX === 'children' ? '' : ''}`}>
          <PartHierarchyHeader {...headerConfig} />
        </div>
      }
      <div className={`${alignX === 'header' ? '-mx-1' : ''} flex flex-col`}>
        {factoryInputs.map((params, idx) =>
          controllerComplete(params) ?
            <PartHierarchy key={idx} hierarchyController={params} /> :
            <WithHierarchyController key={idx} factoryParams={params} render={
              controller => <PartHierarchy hierarchyController={controller} />
            } />
        )}
      </div>
    </div>
  )
}

const nameHeaderWidth = '350px' as const
const nameHeaderWidthTailwind = 'min-w-[350px]' as const
const stickyNameStyles = {
  minWidth: nameHeaderWidth,
}
const leftSticky = {
  position: 'sticky',
  left: 0,
  zIndex: 22,
  overflow: 'visible',
  height: '35px',
  alignItems: 'center',
  ...stickyNameStyles
} as const

const PartHierarchy: React.FC<PartHierarchyExplorerProps> = ({
  hierarchyController,
  withCreateAggSpacer,
  hasHorizontalScroll,
}) => {
  const { rootPart } = hierarchyController;
  const node: RequiredPartHierarchy = {
    hierarchy: '1',
    part: {
      version: rootPart.version,
      partNumber: rootPart.partNumber,
      lifeCycle: rootPart.lifeCycle,
      name: rootPart.name,
      proto: {
        category: {
          name: rootPart.proto.category.name
        }
      }
    },
  }

  return <HierarchyControllerContext.Provider value={hierarchyController}>
    <PartHierarchyNode node={node} depth={0} withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll} />
  </HierarchyControllerContext.Provider>
}

type PartHierarchyHeaderProps = {
  selectedAggIndex?: number
  selectedStyle?: string
  onAggregationHeaderClicked?: (agg: Aggregator) => void
  onCreateLocalAggregator?: () => void
  hasHorizontalScroll?: boolean
} & ({
  hierarchyController: HierarchyController
  columns?: undefined
  activeAggregators?: undefined
  aggregators?: undefined
  showAll?: undefined
} | {
  hierarchyController?: undefined
  columns: HierarchyController['columns']
  activeAggregators?: HierarchyController['activeAggregators']
  aggregators?: HierarchyController['aggregators']
  showAll?: boolean
} | {
  hierarchyController?: undefined
  columns?: undefined
  activeAggregators?: undefined
  aggregators?: undefined
  showAll?: undefined
})

export const PartHierarchyHeader: React.FC<PartHierarchyHeaderProps> = ({
  selectedAggIndex, selectedStyle = '', onAggregationHeaderClicked, onCreateLocalAggregator, hasHorizontalScroll, ...rest
}) => {
  const { columns, activeAggregators, showAll, aggregators } = rest.hierarchyController ?? rest
  const stickyName = rest.hierarchyController?.stickyName
  const mergedColumns = mergeIntoBaseColumns(columns)
  const shownAggs = showAll ? aggregators : activeAggregators

  const getTargetType = (a: Aggregator) => rest.hierarchyController?.aggregations?.opts.targetOverride ?? a.targetType

  console.log({ selectedStyle })
  const aggClassName = (agg: EditableAggregator) =>
    twMerge(
      classNames(`
          group shrink-0 p-2 -my-2 ml-[9px] -mr-[9px] border border-t-0 border-white w-[150px] text-center
          whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible
          hover:z-10 hover:bg-white
        `,
        {
          [selectedStyle]: agg.index === selectedAggIndex,
          'italic': agg.isDirty
        })
    )

  const AggTab = onAggregationHeaderClicked ? 'button' : 'div'

  const paddingRight = onCreateLocalAggregator ? 'pr-2' : 'pr-8'
  const rightBorder = hasHorizontalScroll ? 'border-r-2' : ''
  const stickyClass = stickyName ? `h-[45px] -mt-[17px] border-t-[1px] ${rightBorder}` : ''

  return <div className={`flex text-xs ${paddingRight}`}>
    <div className='flex-1' style={{
      ...leftSticky,
      height: '20px',
    }}>
      <div className={`pl-10 ${nameHeaderWidthTailwind} bg-white flex items-center ${stickyClass}`}>
        Part Number / Name
      </div>
    </div>
    <div className='flex gap-3'>
      {mergedColumns.map(column => {
        return <div
          title={column.name}
          className='shrink-0 overflow-hidden text-ellipsis whitespace-nowrap h-[17px]'
          style={{
            width: column.width, textAlign: column.align || 'left',
          }}
          key={`${column.type}.${column.key}`}>{column.name}</div>
      })}
      {shownAggs && shownAggs.length > 0 &&
        shownAggs.map((a, idx) => letF(getTargetType(a), targetType => {
          const agg = a as EditableAggregator

          return <AggTab
            key={idx}
            className={aggClassName(agg)}
            type={onAggregationHeaderClicked ? 'button' : undefined}
            onClick={() => onAggregationHeaderClicked?.(a)}
          >
            <span className={`p-2 -m-2 bg-inherit`}>
              <ScaleIcon className='w-4 inline -mt-1' />{' '}
              {a.name} {targetType.type === "Number" ? null : `(${targetType.unit})`} {agg.isDirty && '*'}
            </span>
          </AggTab>
      }))
      }
      {onCreateLocalAggregator &&
        <Tooltip.Container>
          <Tooltip.Message position='leftHorizontal' className='right-8'>
            Add New Aggregation
          </Tooltip.Message>
          <button className='flex w-5 h-5 justify-center items-center ml-3 mr-4 hover:bg-gray-200 rounded' type='button' onClick={onCreateLocalAggregator}>
            <PlusIcon className='w-4 h-4' />
          </button>
        </Tooltip.Container>
      }
    </div>
  </div>
}

type Padding = {
  partNumber: number
}

const quantityAndUnit = (quantity?: number, units?: string) => {
  if (typeof quantity === 'undefined') return '-'
  units = units || 'each'
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}

type PartHierarchyNodeProps = {
  padding?: Padding
  node: RequiredPartHierarchy | { groupId: string, children: RequiredPartHierarchy[], hierarchy: string }
  parentKey?: string
  depth: number
  withCreateAggSpacer?: boolean
  hasHorizontalScroll?: boolean
}
const PartHierarchyNode: React.FC<PartHierarchyNodeProps> = ({
  node,
  padding,
  parentKey,
  depth,
  withCreateAggSpacer,
  hasHorizontalScroll,
}) => {
  const {
    showAll,
    aggregators,
    activeAggregators,
    rootPart,
    subjectParts,
    selectedNodes,
    setSelectedNodes,
    selectable,
    aggregations,
    columns,
    initiallyExpandedNodes,
    stickyName
  } = useRequiredContext(HierarchyControllerContext)

  const shownAggs = showAll ? aggregators : activeAggregators

  const branchDisabled = useContext(BranchDisabledContext)

  const orgId = useParams().orgId!
  const isGroup = 'groupId' in node
  const hasAggregations = (shownAggs?.length || 0) > 0
  //const [expanded, setExpanded] = useState(true) //TODO: remove this

  const [expanded, setExpanded] = useState(
    initiallyExpandedNodes === 'all' ?
      true :
      initiallyExpandedNodes.includes(isGroup ? node.groupId : node.hierarchy)
  )

  const isRoot = node.hierarchy === '1'

  // sort by part number by default
  const children = findChildren(rootPart.hierarchy, node.hierarchy).sort((childA, childB) => {
    if (childA.part.partNumber < childB.part.partNumber) { return -1; }
    if (childA.part.partNumber > childB.part.partNumber) { return 1; }
    return 0
  })

  const nodeProps = subjectParts?.find(p => p.hierarchy === node.hierarchy)

  const renderChildren = !nodeProps?.hideChildren
  const expandable = (isGroup || Boolean(renderChildren && children.length))
  const iconClass = 'h-5 w-5 mx-1'

  const toggleClass = classNames(
    twMerge(
      'h-[35px] flex items-center bg-white box-content',
      !(isRoot && stickyName) && 'border-t',
      nodeProps?.className,
    ),
    {
      'cursor-pointer': expandable
    }
  )

  const toggleExpand = () => {
    if (expandable) setExpanded(!expanded)
  }

  const expandButton = () => {
    const icon = expanded ?
      <ChevronDownIcon data-testid='collapse-hierarchy' className={iconClass} /> :
      <ChevronRightIcon data-testid='expand-hierarchy' className={iconClass} />

    return <div className=' text-gray-500'>
      {expandable ? icon : <div className={iconClass} />}
    </div>
  }

  const checked = (isGroup && node.children.some(c => selectedNodes.includes(c.hierarchy))) || selectedNodes.includes(node.hierarchy)
  const disableChildren = isGroup ? (branchDisabled) : (branchDisabled || (!isRoot && !checked));

  const selectBox = () => {
    if (!selectable || isRoot) return null
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        id="hierarchy-node-toggle"
        data-testid={`hierarchy-node-checkbox-${node.part?.partNumber || node.groupId}`}
        name="hierarchy-node"
        type="checkbox"
        onChange={e => {
          let newNodes: typeof selectedNodes

          if (isGroup) {
            const children = node.children.map(c => c.hierarchy)
            const decendantFilter = children.map(c => c + '.')
            const allDecendants = rootPart.hierarchy
              .filter(h => decendantFilter.some(d => h.hierarchy.startsWith(d)))
              .map(d => d.hierarchy)

            if (e.currentTarget.checked) {
              newNodes = Array.from(new Set([...selectedNodes, ...children, ...allDecendants]))
            }
            else {
              const dSet = new Set(allDecendants)
              newNodes = selectedNodes.filter(n => !children.includes(n) && !dSet.has(n))
            }
          }
          else {
            if (e.currentTarget.checked) {
              if (!expanded) {
                //when node is collapsed, include children automatically
                const allDecendants = rootPart.hierarchy
                  .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                  .map(d => d.hierarchy)
                newNodes = Array.from(new Set([...selectedNodes, node.hierarchy, ...allDecendants]))
              }
              else {
                newNodes = [...selectedNodes, node.hierarchy]
              }
            }
            else {
              const dSet = new Set(rootPart.hierarchy
                .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                .map(d => d.hierarchy))
              newNodes = selectedNodes.filter(n => n !== node.hierarchy && !dSet.has(n))
            }
          }

          setSelectedNodes(newNodes)
        }}
        checked={checked}
        disabled={branchDisabled}
        aria-describedby="hierarchy-node-checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const ControlBox = () => {
    const branchDisabled = useContext(BranchDisabledContext)
    const allDecendants = rootPart.hierarchy
      .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
      .map(d => d.hierarchy)
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        name="dependencyControl"
        type="checkbox"
        onChange={e => {
          setSelectedNodes(
            e.target.checked ?
              Array.from(new Set([...selectedNodes, ...allDecendants])) :
              selectedNodes.filter(n => !allDecendants.includes(n))
          )
        }}
        checked={allDecendants.every(d => selectedNodes.includes(d))}
        disabled={branchDisabled}
        aria-describedby="select-all-children"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const AggNode = ({ agg, values }: { agg: Aggregator, values?: AggregationNode['values'] }) => {
    const formatter = (n: number) => agg.targetType.type === 'Price' ?
      n.toLocaleString("en", { minimumFractionDigits: 2 }) : n

    const inner = () => {
      if (!values?.[agg.name]) return '-'
      const [min, max, note] = values[agg.name]!
      return <div className='text-right ' style={{ width: 150, textAlign: 'right' }}>
        {note?.type === 'failed' ?
          <Tooltip.Container className='relative'>
            <XCircleIcon className='w-4 inline' />
            <Tooltip.Message className="bottom-10" position='left'>
              There was an error calulating this value
            </Tooltip.Message>
          </Tooltip.Container> :
          note?.type === 'success' ?
            <Tooltip.Container className='relative'>
              {min === max ?
                formatter(min) : `${formatter(min)} - ${formatter(max)}`
              }*
              <Tooltip.Message className="bottom-10 z-50" position='left'>
                This value includes conversions from the following units: [{Array.from(note.from).join(", ")}]
              </Tooltip.Message>
            </Tooltip.Container> :
            min === max ?
              formatter(min) : `${formatter(min)} - ${formatter(max)}`
        }
      </div>
    }
    return <div className='text-right ' style={{ width: 150, textAlign: 'right' }}>
      {inner()}
    </div>
  }

  const indentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${depth * 18}px`
  }
  const thickIndentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${38 + (depth * 18)}px`
  }

  const rightBorder = hasHorizontalScroll ? 'border-r-2' : ''

  if (isGroup) {
    const aggValues = aggregations?.getGroupNode(parentKey!, node.groupId)?.values
    const childPadding = node.children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })
    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <div className={`gap-3 flex bg-inherit grow pr-2 ${stickyName ? rightBorder : ''}`} style={{
          ...(leftSticky),
          ...indentationLevel,
          ...(stickyName ? stickyNameStyles : undefined),
        }}>
          {expandButton()}
          {selectBox()}
          <div className='flex gap-2'>
            <SwatchIcon className='w-4' />
            <div className='flex-1'>
              <div>{node.groupId}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-3'>
          {(columns).map(f => <div style={{ width: f.width }} className='shrink-0' key={f.name}></div>)}
          {(hasAggregations && aggValues) && (shownAggs!).map((a, idx) =>
            <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={withCreateAggSpacer} />
        </div>
        {/* Alignment for status dot */}
        <div className='flex items-center justify-center w-8  '></div>
      </div>
      {expanded && <div className='flex'>
        <div className='border-gray-100 mt-1 mb-4'></div>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {node.children.map(c =>
              <PartHierarchyNode depth={depth + 1} key={c.hierarchy} node={c} padding={childPadding}
                withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll} />
            )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      }
    </div>
  }
  else {
    const SPACE = '\u00A0'

    const fields = {
      quantity: quantityAndUnit(node.dependency?.quantity, node.dependency?.units), //String(node.dependency?.quantity ?? '-'),
      referenceDesignator: String(node.dependency?.referenceDesignator || '-'),
      version: node.part.version,
      category: node.part.proto.category.name
    }
    const columnValues = columns.map(c => {
      if (c.type === 'field') {
        return {
          ...c,
          value: fields[c.key as keyof typeof fields]
        }
      }
    })

    const childPadding = children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })

    const childrenWithGroups =
      [
        ...children.filter(c => !c.dependency?.groupId) as (RequiredPartHierarchy & { groupId?: undefined })[],
        ...Object.entries(
          groupBy(
            children.filter(c => c.dependency?.groupId), c => c.dependency?.groupId
          )
        ).map(([groupId, group]) => ({
          groupId,
          children: group!,
          hierarchy: ''
        })),
      ]

    const subParts = (expanded && renderChildren && childrenWithGroups.length) ?
      <div className='flex'>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {selectable &&
              <div className='w-full border-t flex'>
                <div className={`h-[35px] px-3 flex border-gray-200 items-center gap-3 bg-white ${stickyName ? `grow ${rightBorder} ${nameHeaderWidthTailwind}` : ''}`} style={{
                  ...leftSticky,
                  ...thickIndentationLevel,
                  height: '35px', //not sure why this is necessary to get the rows to line up
                }} >
                  <div className='w-4 -mx-1' />
                  <ControlBox />
                  Select All Children
                </div>
                <div className='bg-green-20 shrink-0 flex gap-3' /*make row the correct width*/ >
                  {(columns).map(f =>
                    <div style={{ width: f!.width }} className='shrink-0' key={f.name} />)}
                  {hasAggregations && shownAggs!.map((_, idx) =>
                    <div className='shrink-0 w-[150px]' key={idx} />)}
                  <CreateAggSpacer show={'full'} />
                </div>
              </div>
            }
            {childrenWithGroups
              .map(c =>
                <PartHierarchyNode depth={depth + 1} key={c.groupId ?? c.hierarchy} node={c} padding={childPadding}
                  withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll}
                  parentKey={node.hierarchy} />
              )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      : null

    const partNumberPadding = padding?.partNumber ? padding.partNumber + 1 : undefined
    const partNumber = padEnd(`#${node.part.partNumber}`, partNumberPadding, SPACE)

    const message = nodeProps?.message || null

    const aggValues = aggregations?.getPartNode(node.hierarchy)?.values

    // Hack because the version summary component changes the version string
    // to illustrate a version change
    const versionLink = validVersion(node.part.version as string) ?
      routes.partWithVersion({ orgId, partNumber: node.part.partNumber, version: node.part.version as string }) :
      routes.part({ orgId, partNumber: node.part.partNumber })

    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <div className={`flex-1 gap-3 flex bg-inherit font-mono pr-2 ${stickyName ? rightBorder : ''}`} style={{
          ...leftSticky,
          ...indentationLevel,
          ...(stickyName ? stickyNameStyles : undefined),
        }}>
          {expandButton()}
          {selectBox()}
          <div className='font-medium'>{partNumber}</div>
          <div className='relative flex-1 bg-inherit flex items-center'>
            <div className='absolute left-0 right-0 bg-inherit h-8 flex items-center overflow-hidden text-ellipsis whitespace-nowrap backdrop-brightness-100 hover:pr-2 hover:overflow-visible hover:whitespace-normal hover:z-20 hover:w-max'>
              <AppLink className='underline text-blue-700 font-sans' to={versionLink}>{node.part.name}</AppLink>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-3'>
          {
            (columnValues)
              .map(f => {
                return <div
                  style={{ width: f!.width, textAlign: f?.align || 'left' }}
                  key={`${f!.type}.${f!.key}`}
                  className='group shrink-0 text-right whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible hover:z-10'>
                  <div className='group-hover:bg-inherit px-2 -mx-2 flex items-center'>
                    <div>{f!.value}</div>{f?.key === 'version' && message}
                  </div>
                </div>
              })
          }
          {(hasAggregations) && (shownAggs!).map((a, idx) => <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={withCreateAggSpacer} />
        </div>

        <div className='flex items-center justify-center w-8 shrink-0'>
          <StatusDot lifeCycle={node.part.lifeCycle} size='sm' alignTooltip='leftHorizontal' />
        </div>
      </div>
      {subParts}
    </div>
  }
}

export default PartHierarchy


export const addVersionWarnings = (partWithHierarchy: RequiredPart, existingSubjectParts?: SubjectPart[]) => {
  const outOfDateRefsByLevel = partWithHierarchy.hierarchy.reduce((byLevel, n) => {
    if (!validVersion(n.part.version as string)) return byLevel
    if (!validVersion(n.part.proto.currentVersionString)) return byLevel
    if (n.part.version === n.part.proto.currentVersionString) return byLevel

    return {
      ...byLevel,
      [n.hierarchy]: true as const
    }
  }, {} as Record<string, true>)

  const outOfDateParentsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((parentsByLevel, level) => {
      const levelParts = level.split(/\./g)
      return {
        ...parentsByLevel,
        [levelParts.slice(0, -1).join('.')]: true as const
      }
    }, {} as Record<string, true>)

  const outOfDateAncestorsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((ancestorsByLevel, level) => {
      const levelParts = level.split(/\./g)
      const nextAncestors = levelParts.reduce(({ levelAcc, ancestorsByLevel }, level) => {
        const nextLevel = [...levelAcc, level]
        return {
          ancestorsByLevel: {
            ...ancestorsByLevel,
            [nextLevel.join('.')]: true as const
          },
          levelAcc: nextLevel
        }
      }, { ancestorsByLevel, levelAcc: [] as string[] })
      return nextAncestors.ancestorsByLevel
    }, {} as Record<string, true>)

  const subjectParts = [...partWithHierarchy.hierarchy, { hierarchy: '1', part: false as const }].map(n => {
    const existingSubject = (existingSubjectParts?.find(s => s.hierarchy === n.hierarchy))

    const partRefOutOfDate = outOfDateRefsByLevel[n.hierarchy]
    if (partRefOutOfDate && n.part) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Newer version v{n.part.proto.currentVersionString} available
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const childrenOutOfDate = outOfDateParentsByLevel[n.hierarchy]
    if (childrenOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Uses out of date parts
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const descendentsOutOfDate = outOfDateAncestorsByLevel[n.hierarchy]
    if (descendentsOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Parts in this tree are out of date
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }
    return existingSubject
  }).filter(Boolean) as SubjectPart[]
  return subjectParts
}

const CreateAggSpacer = ({ show }: { show?: boolean | 'full' }) =>
  show === 'full' ? <div className='inline-block w-14' />
    : show ? <div className='inline-block w-6' />
      : null
