import OrgSettingsCell from "src/components/OrgSettingsCell"
import { MetaTags } from '@redwoodjs/web'

const OrgSettingsPage = () => {
  return (
    <>
      <MetaTags title="Settings" description="Company Settings" />
      <OrgSettingsCell/>
    </>
  )
}

export default OrgSettingsPage
