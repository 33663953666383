import { MetaTags } from '@redwoodjs/web'

import PartSearchCell from 'src/components/PartsSearchCell'

const PartsPage = () => {
  return (
    <>
      <MetaTags title="Parts" description="Parts page" />
      <PartSearchCell/>
    </>
  )
}

export default PartsPage
