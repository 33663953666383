import {
  ChangeOrder,
  Part,
  PartHierarchy as PartHierarchyType,
  Dependency,
  Maybe,
  PartCategory,
  PartProto
} from 'types/graphql'

import { SubjectPart, HierarchyContainer, HierarchyControllerFactoryParams } from '../PartHierarchy/PartHierarchy'

type OrphanPartHierarchyDependency = Pick<Dependency, 'quantity' | 'section' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'partNumber' | 'version'>
}
type OrphanPartHierarchyPart = Pick<Part, 'partNumber' | 'version' | 'publishId'> & {
  dependencies: OrphanPartHierarchyDependency[]
  proto: Pick<PartProto, 'currentVersionString'> & {
    category: Pick<PartCategory, 'name'>
    currentVersion?: {
      dependencies: Pick<Dependency, 'toPartNumber'>[]
    }
  }
}
type OrphanPartHierarchy = Pick<PartHierarchyType, 'hierarchy'> & {
  part: OrphanPartHierarchyPart
  dependency?: Pick<Dependency, 'quantity' | 'section' | 'units' | 'referenceDesignator' | 'groupId' | 'toVersionRange'>
}
type OrphanPart = Pick<Part, 'partNumber' | 'version' | 'publishId'> & {
  partNumber: string
  proto: Pick<PartProto, 'currentVersionString'> & {
    category: Pick<PartCategory, 'name'>
    currentVersion?: {
      dependencies: Pick<Dependency, 'toPartNumber'>[]
    }
  }
  dependencies: OrphanPartHierarchyDependency[]
  hierarchy: OrphanPartHierarchy[]
}

type IncludedPartDependency = Pick<Dependency, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId' | 'toVersionRange'> & {
  to: Pick<Part, 'partNumber' | 'version'> & {
    proto: Pick<PartProto, 'currentVersionString'> & {
      category: Pick<PartCategory, 'name'>
      currentVersion?: {
        dependencies: Pick<Dependency, 'toPartNumber'>[]
      }
    }
    dependencies: OrphanPartHierarchyDependency[]
  }
}

type IncludedPartHead = Pick<Part, 'partNumber' | 'version'> & {
  dependencies: IncludedPartDependency[]
}
type IncludedPart = IncludedPartHead & {
  updateTo?: Maybe<IncludedPartHead>
}
type VersionChangeSummaryProps = {
  changeOrder: Pick<ChangeOrder, 'number'> & {
    baseOrphans: OrphanPart[]
    includedParts: IncludedPart[]
  }
}

const VersionChangeSummary: React.FC<VersionChangeSummaryProps> = React.memo(({ changeOrder }) => {
  const { includedParts } = changeOrder

  const includedPartsByNumber = includedParts.reduce((memo, p) => {
    return {
      ...memo,
      [p.partNumber]: p
    }
  }, {} as Record<string, typeof includedParts[number]>)
  const columns = [{
    name: 'Version',
    key: 'version',
    type: 'field',
    width: 150,
    align: 'left'
  }]

  const hierarchies = changeOrder.baseOrphans.map((orphan): HierarchyControllerFactoryParams => {
    const rootNode: OrphanPartHierarchy = {
      part: { ...orphan },
      hierarchy: '1',
    }
    const rootInCo = includedPartsByNumber[rootNode.part.partNumber]!

    type NodeInfo = {
      dereferenced: boolean,
      updated: boolean,
      isNew: boolean,
      isNewReference: boolean,
      previousVersion?: string
      previousVersionRange?: string
      node: OrphanPartHierarchy,
      parent?: OrphanPartHierarchy
    }

    const rootInfo: NodeInfo = {
      node: rootNode,
      dereferenced: false,
      updated: Boolean(rootInCo?.updateTo),
      isNew: !rootInCo?.updateTo,
      isNewReference: false,
      previousVersion: rootInCo?.updateTo?.version
    }


    // from the root, check each child for changes and add to diffTree
    // - if a version update in the change order, then add and add children recursively
    // - if a version update outside of the change order, or a newly pinned version then
    //   show the update but don't show it's children
    // - for removed references, show in red but no children
    // - for added references, show in green and add children
    let diffTree: NodeInfo[] = [rootInfo]

    const addUpdatedChildren = (parent: OrphanPartHierarchy) => {
      const parentInChangeOrder = includedPartsByNumber[parent.part.partNumber]!
      const previousParent = parentInChangeOrder.updateTo

      const previousDependenciesByPartNumber: Record<string, Pick<Dependency, 'toVersionRange'> & {
        to: Pick<Part, "partNumber" | "version">
      }> = {}
      for (const child of (previousParent?.dependencies || [])) {
        previousDependenciesByPartNumber[child.to.partNumber] = child
      }

      const children = findChildren(orphan.hierarchy, parent.hierarchy)

      // add updated and new parts
      for (const child of children) {
        const childInCo = includedPartsByNumber[child.part.partNumber]

        const previousDep = previousDependenciesByPartNumber[child.part.partNumber]

        const info = {
          node: child,
          dereferenced: false,
          updated: Boolean(previousDep && (previousDep.to.version !== child.part.version || previousDep.toVersionRange !== child.dependency?.toVersionRange)),
          isNew: Boolean(childInCo && !childInCo.updateTo),
          isNewReference: !previousDep,
          previousVersion: previousDep?.to.version,
          previousVersionRange: previousDep?.toVersionRange
        }

        if (info.isNew || info.isNewReference || info.updated) {
          diffTree = [...diffTree, info]
        }

        // only parts in change order have their children rendered
        if (childInCo && childInCo.version === child.part.version) {
          addUpdatedChildren(child)
        }
      }

      // add dereferenced parts
      const childrenByPartNumber: Record<string, true> = {}
      for (const child of children) {
        childrenByPartNumber[child.part.partNumber] = true as const
      }

      // children are indexed starting at 1
      let deletedChildIndex = children.length + 2

      for (const dep of previousParent?.dependencies || []) {
        if (!childrenByPartNumber[dep.to.partNumber]) {
          diffTree = [...diffTree, {
            node: {
              dependency: {
                ...dep
              },
              part: {
                ...dep.to,
              },
              hierarchy: `${parent.hierarchy}.${deletedChildIndex}`
            },
            dereferenced: true,
            updated: false,
            isNew: false,
            isNewReference: false,
            previousVersion: dep.to.version
          }]
          deletedChildIndex++
        }
      }
    }

    addUpdatedChildren(rootNode)

    const subjectParts = diffTree.map(nodeInfo => {
      const color = () => {
        if (nodeInfo.dereferenced) return 'bg-red-100'
        if (nodeInfo.isNew) return 'bg-green-100'
        if (nodeInfo.isNewReference) return 'bg-green-100'
        if (nodeInfo.updated) return 'bg-yellow-100'
        return ''
      }

      return {
        hierarchy: nodeInfo.node.hierarchy,
        className: color(),
      } as SubjectPart
    })

    // expand everything
    const expandedNodes = diffTree.map(info => info.node.hierarchy)


    const hierarchy = diffTree.map(info => {
      const { node } = info
      const withVersion = {
        ...node,
        part: {
          ...node.part,
          version: info.node.part.version
        },
        previousVersion: info.previousVersion,
        previousVersionRange: info.previousVersionRange
      }

      // do not render groups in the change hierarchy
      if (node.dependency) {
        withVersion.dependency = {
          ...node.dependency,
          groupId: undefined
        }
      }

      return withVersion
    })

    const rootWithUpdates = {
      ...orphan,
      version: rootInfo.node.part.version,
      hierarchy,
      previousVersion: rootInfo.previousVersion,
    }

    const expandedWithoutRoot = expandedNodes.filter(h => h !== '1')

    return {
      rootPart: rootWithUpdates,
      subjectParts,
      initiallyExpandedNodes: expandedWithoutRoot,
      columns
    }
  })

  return hierarchies.length > 0 ?
    <HierarchyContainer controllers={hierarchies} headerConfig={{ columns }} /> :
    <div className='text-center italic text-gray-600 bg-gray-100 p-5 rounded-md'>
      No parts in the change order are referenced by parent assemblies
    </div>
})

export default VersionChangeSummary

type FindAncestorHierarchy = {
  hierarchy: string
}

const findChildren = <T extends FindAncestorHierarchy[]>(fullHierarchy: T, parentLevel: string) => {
  const hierarchy = parentLevel.split('.');
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  const children = fullHierarchy
    .filter(p => childFinder.test(p.hierarchy))
  return children as T
}
