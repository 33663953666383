import React, { useContext, useState } from 'react'
import { Controller } from '@redwoodjs/forms'
import type { PartCategory } from 'types/graphql'
import * as ListBox from '../ListBox'
import AppContext from 'src/lib/appContext'

export type Category = Pick<PartCategory, 'id' | 'name' | 'label'>

type UncontrolledProps = {
  categories: Category[]
  showOnly: string[]
  value: string
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  size?: 'sm' | 'md'
  keepInternalState?: boolean
  onCategoryIdChange: (c: string) => void
}

export const ControlledCategorySelect: React.FC<UncontrolledProps> = ({ size, disabled, value, categories, className, showOnly, onCategoryIdChange, keepInternalState }) => {
  const [lastValue, setLastValue] = useState(value)
  const val = keepInternalState ? lastValue : value
  const selectedCategory = categories.find(c => c.id === val)
  return <ListBox.ListBox onChange={(c) => {onCategoryIdChange(c); setLastValue(c) }} as='div'>
  <ListBox.Button
    size={size}
    disabled={disabled}
    displayValue={selectedCategory!.name}
    className={className} />
  <ListBox.Options align='left'>
    {categories.filter(c => !showOnly || showOnly.includes(c.id)).map((category) => (
      <ListBox.Option key={category.id} value={category.id} display={category.name} />
    ))}
  </ListBox.Options>
</ListBox.ListBox>
}

type Props = {
  name: string
  displayValue: string
  defaultValue?: string
  required: boolean
  disabled?: boolean
  className?: string
  autoFocus?: boolean
};

const PartCategoriesSelect: React.FC<Props> = ({ autoFocus, name, disabled, defaultValue, displayValue, required, className }) => {
  const appContext = useContext(AppContext)
  if (!appContext) return null
  const { partCategories } = appContext
  return <Controller
        name={name}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field, fieldState }) => (
          <ListBox.ListBox {...field} as='div' className='text-xs'>
            <ListBox.UncontrolledButton
              autoFocus={autoFocus}
              disabled={disabled}
              className={className + (fieldState.invalid && ' bg-red-200' || '')} displayFunction={v => {
              return partCategories.find(c => c.id === v.value)?.name || displayValue
            }} />
            <ListBox.Options align='left'>
              {partCategories.map((category) => (
                <ListBox.Option key={category.id} value={category.id} display={category.name} />
              ))}
            </ListBox.Options>
          </ListBox.ListBox>
        )}
      />
}

export default PartCategoriesSelect
