import { MetaTags } from '@redwoodjs/web'

import NotificationsCell from "src/components/NotificationsCell"

const PartPage = () => {
  return (
    <>
      <MetaTags title={"Notifications"} description="Notification page" />
      <NotificationsCell />
    </>
  )
}

export default PartPage
