import { useState, useRef, PropsWithChildren, useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import { createPortal } from 'react-dom';
import { produce, Draft } from 'immer';

import type {
  Source,
  Maybe,
  MetadataType,
  SetAggregationConfigs,
  SetAggregationConfigsVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import AutosizeInput from 'react-input-autosize'
import { ArrowsPointingInIcon, TrashIcon } from '@heroicons/react/24/outline'
import { CheckIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { FormError } from 'src/components/Form'
import PartHierarchy, { HierarchyController, PartHierarchyHeader, ActivatableAggregator } from '../PartHierarchy/PartHierarchy';
import { codes as currencyCodes } from 'currency-codes'
import { letF } from 'api/src/shared/functional';
import { SumAggregator, Aggregator, SumFieldConfig, NumberTarget, MaxAggregator } from './aggregation';
import * as ListBox from '../ListBox';
import Button from 'src/components/Button'
import { ConditionalModal } from '../Modal';
import { useMetadataSchema } from 'src/lib/metadata'
import { QUERY } from './PartCell';
import { ExchangeRates } from 'src/lib/exchangeRates'
import * as Tooltip from 'src/components/ToolTip'
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import ChangeOrderSelectDialog from '../ChangeOrderSelectDialog/ChangeOrderSelectDialog';

export type EditableAggregator = ActivatableAggregator & { isDirty?: boolean, isNew?: boolean, index: number, savedIndex?: number }

const SET_AGGREGATION_CONFIGS = gql`
  mutation SetAggregationConfigs($input: SetAggregationConfigsInput!) {
    setAggregationConfigs(input: $input) {
      id
      aggregationConfigs {
        name
        reducer
        targetType {
          type
          unit
        }
        metadata {
          key
          multiplyByQuantity
        }
        sources {
          key
          multiplyByQuantity
        }
      }
    }
  }
`

const ThemePortal = ({ children }: PropsWithChildren) => {
  const appPortal = useRef<Element>()
  if (!appPortal.current) {
    appPortal.current = document.querySelector('#mainPortal')!
  }
  return appPortal.current && createPortal(children, appPortal.current)
}

interface ChangeOrderWithNumber {
  number: number
}

export type FullScreenHierarchyView = 'agg' | null
type FullScreenHierarchyProps = {
  initialMode?: React.MutableRefObject<FullScreenHierarchyView>
  onCreateChangeOrderWithSelection: () => void
  onSelectChangeOrderWithSelection: (c: ChangeOrderWithNumber) => void
  setExportDialogOpen: (open: boolean) => void
  onClose: () => void
} & Pick<AggregationControlPanelProps, 'hierarchyController' | 'savedAggregators' | 'exchangeRates'>

export default ({ onCreateChangeOrderWithSelection, onSelectChangeOrderWithSelection, onClose, setExportDialogOpen, initialMode, ...rest }: FullScreenHierarchyProps) => {
  const { hierarchyController, exchangeRates } = rest
  const [deleteCandidate, setDeleteCandidate] = useState<Maybe<EditableAggregator>>(null)
  const [showViewConfig, setShowViewConfig] = useState<boolean>(false)
  const [showAddToChangeOrderDialog, setShowAddToChangeOrderDialog] = useState<boolean>(false)

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (deleteCandidate || showViewConfig || showAddToChangeOrderDialog) return;

    const { key } = event;
    if (key === 'Escape') {
      onClose()
    }
  }, [deleteCandidate, showViewConfig])
  useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape]);

  useEffect(() => {
    window.scrollTo(0, 0), []
    if (initialMode?.current) {
      initialMode.current = null
    }
  }, [])

  const [hasHorizontalScroll, hasVerticalScroll] = useScrollListener()
  const scrollBarWidth = hasVerticalScroll ? getScrollbarWidth() : 0

  const aggregators: EditableAggregator[] = hierarchyController.aggregators as EditableAggregator[]

  const [mode, setMode] = useState<FullScreenHierarchyView>(initialMode?.current ?? null)
  const [selectedAggIdx, setSelectedAggIdx] = useState<number | null>(initialMode?.current ? (aggregators.length ? 0 : null) : null)


  useEffect(() => {
    if (mode === 'agg' && aggregators.length === 0) {
      createLocalAggregator()
    }
  }, [mode, aggregators])

  const metadataSchema = useMetadataSchema()!
  const nameSeed = useRef<number>(0)
  const createLocalAggregator = () => {
    const newAgg: EditableAggregator = {
      ...SumAggregator({
        metadataSchema,
        name: `New Aggregator ${++nameSeed.current}`,
        exchangeRates,
        targetType: {
          type: 'Price',
          unit: 'USD'
        },
        metadata: [],
        sources: []
      }),
      isDirty: true,
      isNew: true,
      isActive: true,
      index: aggregators.length,
    }

    setMode('agg')
    hierarchyController.setAggregators?.(aggregators.concat(newAgg))
    setSelectedAggIdx(aggregators.length)
  }

  const getSelectedAgg = () => selectedAggIdx === null ? null
    : aggregators[selectedAggIdx] as EditableAggregator
  const selectedAgg = getSelectedAgg()

  const setSelectedIdxForAgg = (agg: Maybe<EditableAggregator> | undefined) => {
    const idx = agg?.index ?? null
    setSelectedAggIdx(idx)
  }

  const handleAggHeaderClicked = (agg: Maybe<EditableAggregator> | undefined) => {
    setMode('agg')
    setSelectedIdxForAgg(agg)
  }

  type Selection = 'configureAggs' | 'viewSettings' | 'exportSelection' | 'addToChangeOrder'
  const handleSelect = (value: Selection) => {
    switch (value) {
      case 'configureAggs':
        if (mode === 'agg') {
          setMode(null)
          setSelectedAggIdx(null)
        }
        else {
          setMode('agg')
          if (aggregators.length) {
            setSelectedAggIdx(0)
          }
        }
        break;
      case 'viewSettings':
        setShowViewConfig(true)
        break;
      case 'exportSelection':
        setExportDialogOpen(true)
        break;
      case 'addToChangeOrder':
        setShowAddToChangeOrderDialog(true)
        break;
    }
  }

  return (
    <ThemePortal>
      {showViewConfig &&
        <ConditionalModal className='w-fit mt-10' onClose={() => setShowViewConfig(false)}>
          <div className='p-2 flex flex-col gap-8'>
            <div className='flex flex-col gap-2'>
              <div className='text-xs'>Display the aggregations in this currency (without updating the configurations)</div>
              <div className='flex items-center h-4 gap-2'>
                Target Currency
                <ListBox.ListBox
                  defaultValue={hierarchyController.targetCurrency?.unit ?? 'Default'}
                  onChange={unit => {
                    hierarchyController.setTargetCurrency(unit === 'Default' ? undefined : {
                      type: 'Price',
                      unit
                    })
                  }} >
                  <div className="relative">
                    <ListBox.UncontrolledButton size='sm' className='w-[81px]' wrapperClassName='' />
                    <div className='absolute z-50 right-0'>
                      <ListBox.Options className='text-xs'>
                        <ListBox.Option key={'__'} className='py-3' value={'Default'} display={'Default'} />
                        {currencyCodes().map(t => (
                          <ListBox.Option key={t} className='py-3' value={t} display={t} />
                        ))}
                      </ListBox.Options>
                    </div>
                  </div>
                </ListBox.ListBox>
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <div className='text-xs'>Choose which aggregators are visible</div>
              <ListBox.ListBox multiple value={hierarchyController.getActiveAggregators().map(a => a.name)} disabled={aggregators.length === 0}
                onChange={names => {
                  hierarchyController.setAggregators?.(aggregators.map(a => ({
                    ...a,
                    isActive: names.includes(a.name)
                  })))
                }}
              >
                {({ open }) => (<>
                  <div className="relative w-full">
                    <ListBox.Button displayValue={'Aggregations'} disabled={aggregators.length === 0} className='w-full' />
                    <ListBox.Options open={open} className='z-50 text-sm' align='left'>
                      {
                        aggregators.map(a =>
                          <ListBox.Option key={a.name} value={a.name} display={a.name} />
                        )
                      }
                    </ListBox.Options>
                  </div>
                </>
                )}
              </ListBox.ListBox>
            </div>
          </div>
        </ConditionalModal>
      }
      {showAddToChangeOrderDialog &&
        <ChangeOrderSelectDialog
          onSelect={(selected) => {
            if (selected.createChangeOrder) {
              onCreateChangeOrderWithSelection()
            }
            if (selected.changeOrderNumber !== null) {
              onSelectChangeOrderWithSelection({ number: selected.changeOrderNumber })
            }
            setShowAddToChangeOrderDialog(false)
          }}
          onCancel={() => setShowAddToChangeOrderDialog(false)} />
      }
      <div className='-mt-10 z-20 w-max'>
        <div className='bg-white -mt-2 pb-2 sticky top-[64px] z-[23] border-b-2'>
          <div className='flex flex-col sticky left-0 top-16 z-[23]' style={{
            width: `calc(100vw - ${scrollBarWidth}px)`,
          }}>
            <div className='pt-3 pb-3 inline-flex gap-2 leading-none bg-white justify-end pr-4'>
              <Button size='xs' className='flex items-center gap-2 px-2 pl-1' type='button'
                data-testid={'select-parts'}
                onClick={() => hierarchyController?.setSelectable?.(!hierarchyController.selectable)}
              >
                <input
                  type="checkbox"
                  checked={hierarchyController.selectable}
                  aria-describedby="select-children"
                  className="h-3.5 w-3.5 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
                /><div>Select Parts</div>
              </Button>
              <ListBox.ListBox value='' onChange={handleSelect}>
                {({ open }) => (<>
                  <div className="relative inline-block">
                    <ListBox.Button noChevron wrapperClassName='' className='h-6 w-6 p-0 text-center block !leading-[0px]' displayValue={<EllipsisVerticalIcon className='w-4 inline' />} />
                    <ListBox.Options open={open} className='max-h-[70vh] text-xs'>
                      <ListBox.Option value='configureAggs'
                        display={mode === 'agg' ? 'Hide Aggregation Configuration' : 'Configure Aggregations'} />
                      <ListBox.Option value='viewSettings' display={`View Settings`} />
                      <ListBox.Option value='exportSelection' display={`Export` + (hierarchyController.selectable ? ' Selection' : '')} />
                      <ListBox.Option value='addToChangeOrder' display={`Add${hierarchyController.selectable ? ' Selection' : ''} to Change Order`} />
                    </ListBox.Options>
                  </div>
                </>
                )}
              </ListBox.ListBox>
              <Button type='button' size='xs' onClick={onClose} className='h-6 w-6 p-0 justify-center'>
                <ArrowsPointingInIcon className='w-4 inline -mt-[1px]' />
              </Button>
            </div>
            {mode === 'agg' && (Boolean(hierarchyController.activeAggregators.length) &&
              <AggregationControlPanel {...rest} scrollBarWidth={scrollBarWidth}
                selectedAggIdx={selectedAggIdx}
                setMode={setMode}
                setSelectedAggIdx={setSelectedAggIdx}
                getSelectedAgg={getSelectedAgg}
                selectedAgg={selectedAgg}
                setSelectedIdxForAgg={setSelectedIdxForAgg}
                deleteCandidate={deleteCandidate}
                setDeleteCandidate={setDeleteCandidate}
              />
            )}
          </div>
          <div className='pt-4 z-[23] border-t'>
            <PartHierarchyHeader hierarchyController={hierarchyController}
              selectedAggIndex={selectedAgg?.index}
              onAggregationHeaderClicked={handleAggHeaderClicked}
              selectedStyle='!border-gray-200 rounded-b pt-[17px] -mt-[17px] -mb-[5px] bg-gray-100 w-auto hover:bg-gray-100'
              onCreateLocalAggregator={createLocalAggregator}
              hasHorizontalScroll={hasHorizontalScroll}
            />
          </div>
        </div>
        <div className=''>
          <div className='auto pb-4'>
            <PartHierarchy hierarchyController={hierarchyController} withCreateAggSpacer hasHorizontalScroll={hasHorizontalScroll} />
          </div>
        </div>
      </div>
    </ThemePortal>
  )
}

type AggregationConfigRowProps<T extends string> = Omit<SumFieldConfig<T>, 'key'> & {
  valueKey: SumFieldConfig<T>['key']
  zone: 'metadata' | 'sources'
  handleChange: (value: SumFieldConfig<any>) => void
  handleDelete: () => void
}
const AggregationConfigRow = <T extends string>
  ({ zone, valueKey, multiplyByQuantity, handleChange, handleDelete }: AggregationConfigRowProps<T>) => {
  return <div className='inline-flex leading-none items-center bg-indigo-600 text-white rounded-lg px-1 h-7 text-sm mr-2 gap-1'>
    <div className='px-2'>
      {zone === 'sources' ? 'sources.' : ''}{valueKey}
    </div>
    {/* <div className='border-l border-indigo-100 h-7'></div> */}
    {/* <div
      className='flex px-2 py-0.5'
      onClick={() => handleChange({
        multiplyByQuantity: !multiplyByQuantity,
        key: valueKey
      })}
      >
        {multiplyByQuantity ? 'by quantity' : 'once'}</div> */}
    <div className='border-l border-indigo-100 h-7'></div>
    <button className='rounded px-0.5 py-0.5 hover:bg-indigo-400 cursor-pointer' onClick={handleDelete}>
      <XMarkIcon className='w-4' />
    </button>
  </div>
}

type AggregationControlPanelProps = {
  hierarchyController: HierarchyController
  savedAggregators: Aggregator[]
  exchangeRates: ExchangeRates
  scrollBarWidth: number

  selectedAggIdx: number | null
  setSelectedAggIdx: Dispatch<SetStateAction<number | null>>
  getSelectedAgg: () => Maybe<EditableAggregator>
  selectedAgg: Maybe<EditableAggregator>
  setSelectedIdxForAgg: (agg: Maybe<EditableAggregator | undefined>) => void
  setMode: Dispatch<SetStateAction<FullScreenHierarchyView>>
  deleteCandidate: Maybe<EditableAggregator>
  setDeleteCandidate: Dispatch<SetStateAction<Maybe<EditableAggregator>>>

}
const AggregationControlPanel = ({ hierarchyController, savedAggregators, exchangeRates, scrollBarWidth, setMode, ...rest }: AggregationControlPanelProps) => {
  const { setAggregators = () => { } } = hierarchyController
  const aggregators: EditableAggregator[] = hierarchyController.aggregators as EditableAggregator[]

  const {
    setSelectedAggIdx,
    selectedAgg,
    deleteCandidate,
    setDeleteCandidate,
  } = rest

  const [updateAggregationConfigs, { loading, error }] =
    useMutation<SetAggregationConfigs, SetAggregationConfigsVariables>(SET_AGGREGATION_CONFIGS)

  const handleSave = async (agg: EditableAggregator, isDelete?: boolean) => {
    if (!agg.isDirty ||
      (agg.metadataConfig.length === 0 && agg.sourcesConfig.length === 0)) return
    const newAggs = [...savedAggregators]

    const savedIndex = agg.savedIndex

    if (agg.isNew) { newAggs.push(agg) }
    else if (isDelete) { newAggs.splice(savedIndex!, 1) }
    else { newAggs[savedIndex!] = agg }

    const variables: SetAggregationConfigsVariables = {
      input: {
        aggregationConfigs: newAggs.map(({ name, reducer, targetType, metadataConfig, sourcesConfig }) => ({
          name,
          reducer,
          targetType,
          metadata: metadataConfig ?? [],
          sources: sourcesConfig ?? [],
        }))
      }
    }
    const { errors } = await updateAggregationConfigs({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    });
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating aggregation config`
      })
    }
    else {
      if (isDelete) {
        modifyAggregatorState(agg, (idx, aggs) => {
          aggs.splice(idx, 1)
          aggs.forEach((a, newIdx) => {
            a.index = newIdx
            if (newIdx >= idx && a.savedIndex) {
              a.savedIndex -= 1
            }
          })
        })
      }
      else {
        modifyAggregatorState(agg, (idx, aggs) => {
          aggs[idx]!.isDirty = false
          aggs[idx]!.isNew = false

          if (agg.isNew) {
            aggs[idx]!.savedIndex = savedAggregators.length
          }
        })
      }
    }
  }

  const modifyAggregator = (agg: EditableAggregator, f: (idx: number, draft: Draft<EditableAggregator[]>) => void) =>
    produce(aggregators, d => void (f(agg.index, d)))

  const modifyAggregatorState = (...args: Parameters<typeof modifyAggregator>) => {
    const state = modifyAggregator(...args)
    setAggregators(state)
    return state
  }

  const metadataSchema = useMetadataSchema()!

  const editAggProp = <T extends keyof EditableAggregator>(agg: EditableAggregator, prop: T, value: EditableAggregator[T]) =>
    modifyAggregatorState(agg, (idx, aggs) => {
      aggs[idx]![prop] = value
      aggs[idx]!.isDirty = true
    })

  const editFieldCfg = <T extends 'metadataConfig' | 'sourcesConfig'>(agg: EditableAggregator, prop: T, cfgIndex: number) => (value: Aggregator[T][number]) =>
    modifyAggregatorState(agg, (idx, aggs) => {
      aggs[idx]![prop][cfgIndex] = value
      aggs[idx]!.isDirty = true
    })

  const deleteFieldCfg = <T extends 'metadataConfig' | 'sourcesConfig'>(agg: EditableAggregator, prop: T, cfgIndex: number) => () =>
    modifyAggregatorState(agg, (idx, aggs) => {
      const selectedAgg = aggs[idx]!
      selectedAgg[prop].splice(cfgIndex, 1)
      const totalFields = selectedAgg.metadataConfig.length + selectedAgg.sourcesConfig.length
      if (totalFields === 0) selectedAgg.targetType = { type: 'Number', unit: undefined as never }
      selectedAgg.isDirty = true
    })

  const discardChanges = (agg: EditableAggregator): void => {
    if (agg.isNew) {
      setAggregators(produce(aggregators, aggs => {
        aggs.splice(agg.index, 1)
        aggs.forEach((a, idx) => a.index = idx)
        if (aggs.length === 0) setMode(null)
      }))
      setSelectedAggIdx(aggregators.length ? 0 : null)
    }
    else {
      const original = savedAggregators[agg.savedIndex!]!
      setAggregators(produce(aggregators, aggs => {
        aggs[agg.index] = {
          ...original,
          index: agg.index,
          isActive: agg.isActive,
          savedIndex: agg.savedIndex,
        }
      }))
    }
  }

  useSaveShortcut(() => selectedAgg && handleSave(selectedAgg))

  type FieldZone = keyof Pick<Aggregator, 'metadataConfig' | 'sourcesConfig'>
  type SourceOption = keyof Pick<Source, 'stock' | 'price'>
  type OptionKey = `${keyof Pick<Aggregator, 'metadataConfig'>}.${string}` | `${keyof Pick<Aggregator, 'sourcesConfig'>}.${SourceOption}`
  type AllowedType = Extract<MetadataType, 'Price' | 'Number' | 'Mass'>
  type FieldOption = {
    type: AllowedType
    key: OptionKey
    display: string
    disabled: boolean
  }

  const getFieldOptions = () => {
    if (!selectedAgg) return []

    const totalFields = selectedAgg.metadataConfig.length + selectedAgg.sourcesConfig.length
    const mustBeType = totalFields > 0 ? selectedAgg.targetType : null

    const metadataByKey = keyBy(selectedAgg.metadataConfig, 'key')
    const metadataOptions: FieldOption[] = Object.entries(metadataSchema)
      .filter(e => (['Price', 'Number', 'Mass'] as AllowedType[]).includes(e[1].type as AllowedType))
      .map(([key, cfg]) => ({
        type: cfg.type as AllowedType,
        disabled: Boolean(metadataByKey[key] || (mustBeType && cfg.type !== mustBeType.type)),
        key: `metadataConfig.${key}`,
        display: cfg.displayName
      }))

    const sourcesByKey = keyBy(selectedAgg.sourcesConfig, 'key')

    const sourceOptions: FieldOption[] = [{
      disabled: Boolean(sourcesByKey['price'] || (mustBeType && mustBeType.type !== 'Price')),
      key: `sourcesConfig.price` as OptionKey,
      display: 'Source Price',
      type: 'Price' as AllowedType,
    },
    {
      disabled: Boolean(sourcesByKey['leadTimeDays'] || (mustBeType && mustBeType.type !== 'Number')),
      key: `sourcesConfig.leadTimeDays` as OptionKey,
      display: 'Source Lead Time',
      type: 'Number' as AllowedType,
    }
      // sources stock isn't useful in any way (yet?)
      // , {
      //   disabled: Boolean(selectedAgg.sourcesConfig.find(sCfg => sCfg.key === 'stock')),
      //   key: `sourcesConfig.stock` as OptionKey,
      //   display: 'Source Stock',
      //   type: 'Number' as AllowedType,
      // }
    ]
    return [...metadataOptions, ...sourceOptions]
  }


  const allFieldOptions = getFieldOptions()
  const fieldOptionsByType = Object.entries(groupBy(allFieldOptions, 'type'))

  return (
    <div className='flex gap-6 bg-gray-100 p-8 relative border-t border-gray-400'>
      {deleteCandidate &&
        <ConditionalModal className='w-96 mt-10' onClose={() => setDeleteCandidate(null)}>
          <div className='p-4 flex flex-col gap-4'>
            <div className='text-sm text-gray-700'>
              Are you sure you want to delete the aggregator named "{deleteCandidate.name}"?
            </div>
            <div className='flex'>
              <Button onClick={() => setDeleteCandidate(null)}>Cancel</Button>
              <Button variant='red' className='ml-auto' onClick={() => {
                setDeleteCandidate(null)
                setSelectedAggIdx(aggregators.length - 1 <= 0 ? null : 0)
                handleSave(deleteCandidate, true)
              }}>Delete</Button>
            </div>
          </div>
        </ConditionalModal>
      }
      <div className='grow'>
        {selectedAgg && letF(selectedAgg!, ({ name, metadataConfig: m, sourcesConfig: s }) =>
          <div className='flex gap-6'>
            <div className='border-r border-gray-300 flex gap-4 flex-col pr-5 py-2 min-h-24'>
              <div className='flex items-center gap-3'>
                <AutosizeInput
                  className='min-w-24'
                  inputClassName='bg-transparent'
                  value={selectedAgg.name}
                  placeholder='Aggregation Name'
                  onChange={e => {
                    editAggProp(selectedAgg, 'name', e.target.value || '')
                  }} />
                {!selectedAgg.isNew &&
                  <Tooltip.Container>
                    <Tooltip.Message className='text-nowrap'>
                      Delete Aggregation
                    </Tooltip.Message>
                    <button className='flex items-center gap-1' onClick={() => setDeleteCandidate(selectedAgg)}>
                      <TrashIcon className='h-4 w-4' />
                    </button>
                  </Tooltip.Container>
                }
              </div>
              {selectedAgg.isDirty && <div className='flex gap-1'>
                <Tooltip.Container>
                  <Tooltip.Message position='right' className='bottom-8 whitespace-nowrap'>Discard Changes</Tooltip.Message>
                  <Button size='xs' disabled={!selectedAgg.isDirty}
                    onClick={() => discardChanges(selectedAgg)}><XMarkIcon className='w-4 h-4' /></Button>
                </Tooltip.Container>
                <Tooltip.Container>
                  <Tooltip.Message position='right' className='bottom-8 whitespace-nowrap'>Save Changes</Tooltip.Message>
                  <Button size='xs'
                    disabled={
                      !selectedAgg.isDirty ||
                      (selectedAgg.metadataConfig.length === 0 && selectedAgg.sourcesConfig.length === 0) ||
                      loading}
                    onClick={() => handleSave(selectedAgg)}><CheckIcon className='w-4 h-4' /></Button>
                </Tooltip.Container>
                {false && <Button size='xs' disabled>Duplicate</Button>}
              </div>}
            </div>
            <div className='flex flex-col gap-2 items-end pt-2'>
              <ListBox.ListBox
                value={selectedAgg.reducer}
                onChange={reducerKey => {
                  modifyAggregatorState(selectedAgg, (idx, aggs) => {
                    const c = aggs[idx]!
                    const reducerInput = {
                      metadataSchema,
                      name: c.name,
                      exchangeRates: exchangeRates as ExchangeRates,
                      targetType: {
                        type: c.targetType.type,
                        unit: c.targetType.unit
                      },
                      metadata: c.metadataConfig,
                      sources: c.sourcesConfig
                    }
                    if (reducerKey === 'Sum') {
                      aggs[idx] = {
                        ...aggs[idx],
                        ...SumAggregator(reducerInput)
                      }
                    }
                    if (reducerKey === 'Max') {
                      aggs[idx] = {
                        ...aggs[idx],
                        ...MaxAggregator(reducerInput)
                      }
                    }
                    aggs[idx]!.isDirty = true
                  })
                }} >
                <div className="relative">
                  <ListBox.UncontrolledButton wrapperClassName='' className='whitespace-nowrap' displayFunction={(v) => v.value} />
                  <div className=''>
                    <ListBox.Options align='left' className='text-xs'>
                      <ListBox.Option key={'Sum'} value={'Sum'} display='Sum'/>
                        <ListBox.Option key={'Max'} value={'Max'} display='Max'/>
                    </ListBox.Options>
                  </div>
                </div>
              </ListBox.ListBox>
              <div className='w-60 text-xs text-gray-900 text-right'>
                {selectedAgg.reducer === 'Max' && `Take the max of the fields for each part, rolling up against it's parents max values`}
                {selectedAgg.reducer === 'Sum' && 'Sum the listed fields, multiplying by the node quantity and aggregating at each level of the BOM'}
              </div>
            </div>
            <div className='flex flex-col gap-1 w-full'>
              <div className='w-full py-2'>
                {(s.length + m.length) === 0 ?
                  <div className='italic text-xs bg-gray-50 flex items-center h-10 pl-3 w-full rounded border text-gray-400 border-gray-400'>
                    Add a field to get started
                  </div> :
                  <div className='bg-white p-1.5 w-full rounded border border-gray-400'>
                    {m?.map((fieldCfg, idx) =>
                      <AggregationConfigRow {...fieldCfg} valueKey={fieldCfg.key} zone='metadata'
                        handleChange={editFieldCfg(selectedAgg, 'metadataConfig', idx)}
                        handleDelete={deleteFieldCfg(selectedAgg, 'metadataConfig', idx)}
                      />
                    )}
                    {s?.map((fieldCfg, idx) =>
                      <AggregationConfigRow {...fieldCfg} valueKey={fieldCfg.key} zone='sources'
                        handleChange={editFieldCfg(selectedAgg, 'sourcesConfig', idx)}
                        handleDelete={deleteFieldCfg(selectedAgg, 'sourcesConfig', idx)}
                      />
                    )}
                  </div>
                }
              </div>
              <div className='flex justify-end'>
              <ListBox.ListBox
                value=''
                onChange={key => {
                  const field = allFieldOptions.find(o => o.key === key)!
                  const [zone, zoneKey] = key.split('.') as [FieldZone, string]

                  const getUnit = (aggType: NumberTarget['type']) => {
                    if (aggType === 'Number') return
                    if (aggType === 'Price') return 'USD'
                    if (aggType === 'Mass') return 'kg'
                  }

                  modifyAggregatorState(selectedAgg, (idx, aggs) => {
                    const agg = aggs[idx]!
                    if (agg.metadataConfig.length + agg.sourcesConfig.length === 0) {
                      const unit = getUnit(field.type as NumberTarget['type'])
                      agg.targetType = {
                        type: field.type,
                        unit
                      } as NumberTarget
                    }
                    if (zone === 'metadataConfig') {
                      agg.metadataConfig = [
                        ...agg.metadataConfig,
                        {
                          key: zoneKey,
                          multiplyByQuantity: true
                        }
                      ]
                    }
                    if (zone === 'sourcesConfig') {
                      agg.sourcesConfig = [
                        ...agg.sourcesConfig,
                        {
                          key: zoneKey as keyof Source,
                          multiplyByQuantity: true
                        }
                      ]
                    }
                    agg.isDirty = true
                  })
                }} >
                <div className="relative">
                  <ListBox.UncontrolledButton wrapperClassName='' className='whitespace-nowrap' displayFunction={() => 'Add Field'} />
                  <div className='absolute z-50 right-0'>
                    <ListBox.Options align='right' className='text-xs'>
                      {
                        fieldOptionsByType.length === 0 ? <div className='p-4 italic text-gray-600'>No Fields Available</div> : fieldOptionsByType.map(([fieldType, fields]) => {
                          return <div key={fieldType} className='mb-4 mt-2'>
                            <div className='border-b-2 border-gray-500 px-5 py-1 text-gray-700 font-bold'>{fieldType}</div>
                            {fields.map(f => {
                              return <ListBox.Option key={f.key} value={f.key} display={f.display} disabled={f.disabled} />
                            })}
                          </div>
                        })
                      }
                    </ListBox.Options>
                  </div>
                </div>
              </ListBox.ListBox>
              </div>
            </div>
          </div>
        )}
        <FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-2 my-2 rounded" />
      </div>
      <button className='absolute right-2 top-2' onClick={() => {
        setMode(null)
        setSelectedAggIdx(null)
      }}>
        <XMarkIcon className='w-4 h-4 text-gray-500' />
      </button>
    </div>
  )
}

let _scrollbarWidth: number = 0;
function getScrollbarWidth() {
  if (_scrollbarWidth) return _scrollbarWidth
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  //@ts-ignore
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  _scrollbarWidth = scrollbarWidth
  return scrollbarWidth;
}

const useAnimationFrame = (callback: () => void) => {
  const requestRef = React.useRef<number>();

  const animate = () => {
    callback()
    requestRef.current = requestAnimationFrame(animate);
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, []);
}

const useScrollListener = () => {
  const verticalTracker = useRef<boolean>(hasVerticalScroll())
  const [verticalScrollbarState, setVerticalScrollbarState] = useState(verticalTracker.current)

  const horizontalTracker = useRef<boolean>(hasHorizontalScroll())
  const [horizontalScrollbarState, setHorizontalScrollbarState] = useState(horizontalTracker.current)

  useAnimationFrame(() => {
    const hasVS = hasVerticalScroll()
    if (hasVS !== verticalTracker.current) {
      verticalTracker.current = hasVS
      setVerticalScrollbarState(hasVS)
    }
    const hasHS = hasHorizontalScroll()

    if (hasHS !== horizontalTracker.current) {
      horizontalTracker.current = hasHS
      setHorizontalScrollbarState(hasHS)
    }
  })

  return [horizontalScrollbarState, verticalScrollbarState]
}

const hasHorizontalScroll = () => {
  const root = document.documentElement;
  return root.scrollWidth > root.clientWidth;
}
const hasVerticalScroll = () => {
  const root = document.documentElement;
  return root.scrollHeight > root.clientHeight;
}

const useSaveShortcut = (onSave: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault()
        onSave()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onSave])
}
