import type { CategorySelectQuery, PartCategory } from 'types/graphql'
import * as ListBox from '../ListBox'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

export const QUERY = gql`
  query CategorySelectQuery {
    partCategories {
      id
      name
      label
    }
  }
`

export type SelectedCategory = Pick<PartCategory, 'id' | 'name' | 'label'>

type CellProps = {
  onSelect: (value: SelectedCategory) => void
  displayValue: string
  className?: string
  omit?: string[]
  loading?: boolean
};

export const Loading = ({ ...props }: CellProps) => {
  return <PartCategorySelect {...props} partCategories={[]} loading />
}

export const Success = ({ ...props }: CellProps & CellSuccessProps<CategorySelectQuery>) => {
    return <PartCategorySelect {...props} />
}

import { FailureErrorBar } from '../Failure/Failure'
export const Failure = (props: CellProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading categories' {...props}>
    <PartCategorySelect {...props} partCategories={[]} loading />
  </FailureErrorBar>
}


const PartCategorySelect = ({ partCategories, onSelect, displayValue, className, omit }: CellProps & CellSuccessProps<CategorySelectQuery>) => {
  const containerClass = 'relative ' + className ?? ''
  const values = partCategories.filter(category => {
    if (omit && omit.includes(category.id)) return false
    return true
  })
  const handleSelect = (categoryId: string) => {
    const category = partCategories.find(category => category.id === categoryId)!
    onSelect({
      id: category.id,
      name: category.name,
      label: category.label,
    })
  }

  // Empty value, because we are not using the checked value function
  return <ListBox.ListBox onChange={handleSelect} value=''>
    {({ open }) => (
      <>
        <div className={containerClass}>
          <ListBox.Button className='min-w-full' displayValue={displayValue} />
          <ListBox.Options className='min-w-full' open={open}>
            {values
              .sort((a, b) => a.name < b.name ? -1 : 1)
              .map((category) => (
              <ListBox.Option
                key={category.id}
                className='py-3'
                value={category.id}
                display={`${category.name}`} />
            ))}
          </ListBox.Options>
        </div>
      </>
    )}
  </ListBox.ListBox>
}
