const csv = `
Line #,Name,Description,Designator,Quantity,Manufacturer 1,Manufacturer Part Number 1,PPN,Manufacturer Lifecycle 1,Supplier 1,Supplier Part Number 1,Supplier Unit Price 1,Supplier Subtotal 1
,QBLP595-IG,"Chip LED 0402, Green, 0.02 A, 3.1 to 3.7 V, -40 to 80 degC, 2-Pin SMD, RoHS, Tape and Reel",,0,QT-Brightek,QBLP595-IG,,Unknown,,,,
,Capacitor 33µF +/-20% 25V 2917,Aluminum Organic Polymer Capacitors 33uF 25volt SP-Cap AEC-Q200,"C1, C2, C3, C4",4,Panasonic,EEF-CX1E330R,,Not Recommended for New Design,Mouser,667-EEF-CX1E330R,1.79,7.16
,Capacitor 100nF +/-20% 50V 0402,"Chip Capacitor, 100nF +/-20%, 50V, 0402, Thickness 0.6 mm","C5, C8, C9, C10",4,Yageo,CC0402KRX5R9BB104,,Volume Production,Mouser,603-CC402KRX5R9BB104,0.023,0.23
,Capacitor 22pF +/-10% 50V 0402,"Chip Capacitor, 22pF +/-10%, 50V, 0402, Thickness 0.6 mm",C6,1,Kyocera AVX,04025A220JAT2A,,Volume Production,Mouser,581-04025A220J,0.1,0.1
,Capacitor 100nF +/-20% 25V 0402,"Chip Capacitor, 100nF +/-20%, 25V, 0402, Thickness 0.6 mm",C7,1,Samsung,CL05A104KA5NNNC,,Volume Production,Mouser,187-CL05A104KA5NNNC,0.1,0.1
,91911-31325LF,"Header, Pitch 1 mm, 25 Position, Height 5 mm, -55 to 130 degC","CN1, CN2",2,Amphenol ICC / FCI,91911-31325LF,,Unknown,,,,
,CN-1P-2M-R3,AMASS 500V 15A   0.8 mOhm,"CP1, CP2, CP3, CP4",4,Murata,RCER72A105K2M1H03A,,Volume Production,Mouser,81-RCER72A105K2M1H3A,0.85,3.4
,ESDA24P140-1U3M,"TVS diode, 4500W, 22V standoff, 29.5V clamp",F1,1,STMicroelectronics,ESDA24P140-1U3M,,Volume Production,Mouser,511-ESDA24P140-1U3M,0.55,0.55
,KISS_ESC_2-6S_32A,KISS ESC 2-6S 32A (45A limit) - 32bit brushless Motor Ctrl,"M1, M2, M3, M4",4,Panasonic,AXK5F34547YG,,Volume Production,,,,
,PSMN1R0-30YLDX,"N-channel Logic Level MOSFET, 30 V, 300 A, -55 to 175 degC, 5-Pin SOT669, RoHS, Tape and Reel","Q1, Q2",2,Nexperia,PSMN1R0-30YLDX,,Volume Production,Mouser,771-PSMN1R0-30YLDX,1.44,2.88
,RZM002P02T2L,"Pch Small Signal MOSFET, 20 V, 200 mA, 3-Pin SOT723, RoHS, Tape and Reel",Q3,1,Rohm,RZM002P02T2L,,Volume Production,Mouser,755-RZM002P02T2L,0.31,0.31
,Resistor 953K +/-1 % 0402 63 mW,"Chip Resistor, 953 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R1,1,Panasonic,ERJ-2RKF9533X,,Volume Production,Mouser,667-ERJ-2RKF9533X,0.1,0.1
,Resistor 232K +/-1 % 0402 63 mW,"Chip Resistor, 232 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R2,1,Bourns,CR0402-FX-2323GLF,,Volume Production,Mouser,652-CR0402-FX2323GLF,0.1,0.1
,Resistor 2K8  +/-1 % 0402 63 mW,"Chip Resistor, 2,8 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R3,1,Yageo,RC0402FR-072K8L,,Volume Production,Mouser,603-RC0402FR-072K8L,0.1,0.1
,Resistor 82K  +/-1 % 0402 63 mW,"Chip Resistor, 82 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402","R4, R5",2,Bourns,CR0402-FX-8202GLF,,Volume Production,Mouser,652-CR0402FX-8202GLF,0.007,0.07
,Resistor 100K +/-1 % 0402 63 mW,"Chip Resistor, 100 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402","R6, R7",2,Yageo,RC0402FR-07100KL,,Volume Production,Mouser,603-RC0402FR-07100KL,0.009,0.09
,Resistor 1K6 +/-5 % 0402 63 mW,"Chip Resistor, 1,6 kOhms, +/-5 %, 63 mW, -55 to 155 degC, 0402",R8,1,Panasonic,ERJ-2GEJ162X,,Volume Production,Mouser,667-ERJ-2GEJ162X,0.1,0.1
,Resistor 200K +/-1 % 0402 63 mW,"Chip Resistor, 200 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R9,1,Yageo,RC0402FR-07200KL,,Volume Production,Mouser,603-RC0402FR-07200KL,0.1,0.1
,Jumper 0402,Jumper 0402,"R10, R11, R12, R13",4,Bourns,CR0402-J/-000GLF,,Volume Production,Mouser,652-CR0402-J/-000GLF,0.002,0.02
,Resistor 120K +/-1 % 0402 63 mW,"Chip Resistor, 120 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R14,1,Yageo,RC0402FR-07120KL,,Volume Production,Digi-Key,311-120KLRCT-ND,0.1,0.1
,Resistor 3K +/-1 % 0402 63 mW,"Chip Resistor, 3 kOhms, +/-1 %, 63 mW, -55 to 155 degC, 0402",R15,1,Panasonic,ERJ-2RKF3001X,,Volume Production,Mouser,667-ERJ-2RKF3001X,0.11,0.11
,9774050243R,"WA-SMSI SMT Steel Spacer, M2 Thread Internal, Tin, RoHS, Tape and Reel","ST1, ST2, ST3, ST4",4,Wurth Electronics,9774050243R,,Unknown,Mouser,710-9774050243R,0.82,3.28
,LM5060MM,"High-Side Protection Controller with Low Quiescent Current, 5.5 to 65 V, -40 to 125 degC, 10-Pin VSSOP, RoHS, Tape and Reel",U1,1,TI National Semiconductor,LM5060MM/NOPB,,Volume Production,Mouser,926-LM5060MM/NOPB,2.85,2.85
,INA230AIRGT,"High or Low Side Measurement, 2.7 to 5.5 V, -40 to 125 degC, 16-Pin QFN, RoHS, Tape and Reel",U2,1,Texas Instruments,INA230AIRGTR,,Volume Production,Mouser,595-INA230AIRGTR,1.93,1.93
,ACS781LLRTR-100U-T,"High-Precision Linear Hall-Effect-Based Current Sensor IC with 200 u? Current Conductor, 3 to 3.6 V, -40 to 150 degC, 7-Pin PSOF, RoHS, Tape and Reel",U3,1,Allegro MicroSystems,ACS781LLRTR-100U-T,,Volume Production,Mouser,250-781LLRTR100UT,6.53,6.53
`

export default csv
