import OrgSettingsCategoriesCell from "src/components/OrgSettingsCategoriesCell"
import { MetaTags } from '@redwoodjs/web'

const OrgSettingsCategoriesPage = () => {
  return (
    <>
      <MetaTags title="Categories Settings" description="Company Categories Settings" />
      <OrgSettingsCategoriesCell/>
    </>
  )
}

export default OrgSettingsCategoriesPage
