import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

const mapperConfig: MapperConfig = {
  orgIds: ['charbroil-sandbox', 'charbroil'],
  name: 'Creo',
  rootCategoryId: 'top-level',
  Logo: DocumentIcon,
  rootIsTopLevel: true,
  fileToRows: {
    parsers: [{
      fileType: 'txt',
      parserId: 'creo'
    }, {
      fileType: 'bom',
      parserId: 'creo'
    }, {
      fileType: 'json',
      parserId: 'creo'
    }]
  },
  importOutputFields: {
    metadata: [
      'material',
      'finish',
      'artwork-number',
      'sheet-metal-thickness',
      'factory-drawing-number',
      'factory-part-number',
      'alt-language-description',
    ]
  },
  // rowsToBom: [{
  //   type: 'FilterRemove',
  //   config: {
  //     conditions: [{
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G702'
  //     }, {
  //       type: 'contains',
  //       column: 'part_number',
  //       value: 'G329'
  //     }]
  //   }
  // }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        material: {
          type: 'simple',
          value: '{{row.material}}'
        },
        finish: {
          type: 'simple',
          value: '{{row.finish}}'
        },
        'artwork-number': {
          type: 'simple',
          value: '{{row.art_number}}'
        },
        'sheet-metal-thickness': {
          type: 'simple',
          value: '{{row.smt_thickness}}'
        },
        'factory-drawing-number': {
          type: 'simple',
          value: '{{row.factory_drawing_number}}'
        },
        'factory-part-number': {
          type: 'simple',
          value: '{{row.factory_part_number}}'
        },
        'alt-language-description': {
          type: 'simple',
          value: '{{row.alternate_desc}}'
        }
      },
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.name}}',
        type: 'simple'
      },
      cadRev: {
        type: 'empty'
      },
      categoryId: {
        type: 'simple',
        value: 'mechanical-part'
      },
      isOffTheShelf: {
        value: 'false',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each',
        type: 'quantity'
      },
    }
  }
}

export default mapperConfig
