import { useState, Fragment, createContext, useContext } from 'react';
import type {
  PartQuery,
} from 'types/graphql'
import { QueryPartHierarchy } from './PartCell'
import { Dialog, Transition } from '@headlessui/react'
import { ZipIcon } from '../Icons';
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { DocumentTextIcon, ArrowDownTrayIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { displayUnits } from 'src/components/Dependencies'
import { useLifeCycleStages, resolveLifeCycle, ResolvedLifeCycleStage } from "src/lib/lifecycle"
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useMetadataSchema, useMetadataTabularizer } from 'src/lib/metadata'
import Button from 'src/components/Button'

import { getInstanceQuantity, HierarchyTreeRootNode } from './aggregation';

type QueryInstance = NonNullable<PartQuery['partProto']>['instance']
type ExportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
} & ExportBodyProps

type ExportBodyProps = {
  hierarchy: QueryPartHierarchy
  tree: HierarchyTreeRootNode,
  part: QueryInstance
  downloads: {
    source: string
    destination: string
  }[]
}
export type ExportMapper = PartQuery['currentOrg']['manualExportMapper']
export const ExportMapperContext = createContext<ExportMapper>(null)

export const ExportDialog: React.FC<ExportDialogProps> = ({ open, setOpen, hierarchy, tree, part, downloads }) =>
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-8 py-6 text-left shadow-xl transition-all min-w-[28rem]">
              <div>
                <div className="">
                  <Dialog.Title className="font-medium mb-5 text-lg text-gray-900">
                    Export
                  </Dialog.Title>
                  {hierarchy.length === 0 ?
                    <div>Selection was empty. Please select items to export or turn off selection mode.</div> :
                    <ExportBody hierarchy={hierarchy} tree={tree} part={part} downloads={downloads} />
                  }
                </div>
              </div>
              <div className="mt-5 flex flex-col items-end -mr-1">
                <Button
                  onClick={() => setOpen(false)}
                >
                  Done
                </Button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>

const ExportBody = ({ hierarchy, tree, part, downloads }: ExportBodyProps) => {
  const tabularizeMetadata = useMetadataTabularizer()
  const exportMapper = useContext(ExportMapperContext)
  const stages = useLifeCycleStages()

  const sourcesHeaderPrefixes =
    Array(hierarchy.reduce((count, p) => Math.max(count, p.part.sources.length), 0)).fill(null)
      .map((_, i) => `Source-${i + 1}-`)

  type Sources = (typeof hierarchy)[number]['part']['sources']
  const tabularizeSources = (sources: Sources) =>
    sourcesHeaderPrefixes.reduce((acc, prefix, idx) => {
      const source = sources[idx];
      return {
        ...acc,
        [prefix + 'Distributor']: source?.distributor?.name ?? '',
        [prefix + 'SKU']: source?.distributorSku ?? '',
        [prefix + 'Link']: source?.url ?? '',
        [prefix + 'Comment']: source?.comment ?? '',
        [prefix + 'Price']: source?.price ?? '',
        [prefix + 'Currency']: source?.priceCurrency ?? '',
        [prefix + 'Lead_Time_Days']: source?.leadTimeDays ?? '',
        [prefix + 'Stock']: source?.stock ?? '',
      }
    }, {} as Record<string, any>)

  const hierarchyCsvBody = hierarchy.map(h => {
    return {
      Hierarchy: h.hierarchy,
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      'CAD Revision': h.part.cadRev,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      ['Option-Name']: h.dependency.groupId ?? '',
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: h.dependency.quantity,
      ['Quantity-Units']: displayUnits(h.dependency.units),
      ['Reference-Designator']: h.dependency.referenceDesignator ?? '',
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
  })

  const flatCsvBody = hierarchy.reduce((rolledUp, h) => {
    const matchingIndex = rolledUp.findIndex(e => {
      return h.part.partNumber === e.part.partNumber &&
        h.part.version === e.part.version
    })
    if (matchingIndex === -1) {
      return [...rolledUp, {
        ...h,
      }]
    }
    return rolledUp.map((e, i) => {
      if (matchingIndex !== i) {
        return e
      }

      return {
        ...e,
        dependency: {
          ...e.dependency,
          quantity: e.dependency.quantity + h.dependency.quantity,
          units: displayUnits(h.dependency.units) || displayUnits(e.dependency.units),
          designator: e.dependency.referenceDesignator
        },
      }
    })
  }, [] as ((typeof hierarchy)[number] & { quantityRange?: [number, number] })[]).map(h => {
    const q = getInstanceQuantity(tree, h.part.partNumber, h.part.version)
    return {
      'Part Number': h.part.partNumber,
      Version: h.part.version,
      Category: h.part.proto.category.name,
      Name: h.part.name,
      Lifecycle: stages?.length ? resolveLifeCycle(stages, h.part.lifeCycle).name : undefined,
      Summary: h.part.summary,
      Quantity: (q && q[1]) ?
        (q[0] === q[1] ? q[0] : `${q[0]}-${q[1]}`)
        : '',
      ['Quantity-Units']: displayUnits(h.dependency.units),
      ['Reference-Designator']: h.dependency.referenceDesignator ?? '',
      ...tabularizeMetadata(h.part.metadata),
      ...tabularizeSources(h.part.sources)
    }
  })

  type R = Record<string, any>
  const withMappedKeys = (objs: Object[]) => exportMapper ?
    objs.map(obj =>
      Object.entries(obj).reduce((mapped, [k, v]) => ({
        ...mapped,
        [(exportMapper as R)[k] ?? k]: v
      }), {} as R))
    :
    objs as R[];

  const mappedFlat = withMappedKeys(flatCsvBody);
  const mappedHierarchy = withMappedKeys(hierarchyCsvBody);

  const files = () => {
    if (flatCsvBody.length === 0) return downloads
    const csvConfig = mkConfig({ useKeysAsHeaders: true })
    const flatCsvContents = String(generateCsv(csvConfig)(mappedFlat))
    const hierarchyCsvContents = String(generateCsv(csvConfig)(mappedHierarchy))
    const filesWithCsvs = [...downloads, {
      sourceContent: flatCsvContents,
      destination: `${part.partNumber}-v${part.version} - Flat Bill of Materials.csv`
    }, {
      sourceContent: hierarchyCsvContents,
      destination: `${part.partNumber}-v${part.version} - Hierarchical Bill of Materials.csv`
    }]
    return filesWithCsvs
  }

  return (
    <div className='flex flex-col gap-3 my-68'>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Hierarchical bill of materials
        </div>
        <CsvLink body={mappedHierarchy} fileName={`${part.partNumber}-v${part.version}-hierarchical.csv`} />
      </div>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Flat bill of materials
        </div>
        <CsvLink body={mappedFlat} fileName={`${part.partNumber}-v${part.version}-flat.csv`} />
      </div>
      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Artifacts
        </div>
        <ArtifactsBundle files={files()} name={`${part.partNumber}-v${part.version} - ${part.name}.zip`} />
      </div>
    </div>
  )
}

type CsvLinkProps = {
  fileName: string
  body: Record<string, unknown>[]
}
export const csvConfig = mkConfig({ useKeysAsHeaders: true })
export const CsvLink: React.FC<CsvLinkProps> = ({ body, fileName }) => {
  const csv = generateCsv(csvConfig)(body)
  const csvContent = `data:text/csv;charset=utf-8,${csv}`;

  const encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
  return <a
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    href={encodedUri} download={fileName}>
    <DocumentTextIcon className='h-10 w-10 text-green-500' />
    <div className='flex-1'>{fileName}</div>
    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </a>
}


type ArtifactDownload = {
  source?: string
  sourceContent?: string
  destination: string
}

type ArtifactsBundleProps = {
  name: string
  files: ArtifactDownload[]
  align?: 'left' | 'center'
}

export const ArtifactsBundle: React.FC<ArtifactsBundleProps> = ({ name, files, align }) => {
  const jszip = new JSZip()
  const generateDownload = async () => {
    const filesWithContent = await Promise.all(files.map(async file => {
      if (file.sourceContent) return file
      const response = await fetch(file.source!)
      return {
        destination: file.destination,
        sourceContent: await response.blob()
      }
    }))
    for (let file of filesWithContent) {
      jszip.file(file.destination, file.sourceContent!);
    }
    jszip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, name);
    });
  }

  return <button
    className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
    onClick={generateDownload}>
    <ZipIcon className='h-10 w-10 text-gray-500' />
    <div className={`flex-1 ${align === 'left' ? 'text-left' : ''}`}>{name}</div>
    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
  </button>
}

