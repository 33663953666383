import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import type {
  LivePartProto,
  Part,
  PartCategory,
  User
} from 'types/graphql'
import { routes, useParams } from '@redwoodjs/router'

import { TagIcon } from '@heroicons/react/24/outline';
import { AppLink } from 'src/lib/routing'
import { StatusDot } from './LifecycleStatus/LifecycleStatus';

export type PartSummaryPart = Pick<LivePartProto, 'partNumber'> & {
  currentVersion: Pick<Part, 'version' | 'name' | 'summary' | 'lifeCycle'>
  owner: Pick<User, 'name'>
  category: Pick<PartCategory, 'name'>
}

type PartSummaryProps = {
  part: PartSummaryPart
  notInteractive?: boolean
}

const PartSummary: React.FC<PartSummaryProps> = ({ part, notInteractive }) => {
  const orgId = useParams().orgId!

  // don't show more menu until we implement it
  const showMoreMenu = false
  const partName = notInteractive ? <div>
    {part.currentVersion.name}
  </div> : <AppLink to={routes.part({ orgId, partNumber: part.partNumber })} className='underline text-blue-700'>{part.currentVersion.name ?? 'No Name'}</AppLink>
  return <div key={part.partNumber} className='h-44 border border-grey-200 rounded-md flex'>
    <div className="m-4 w-1/6 min-w-44 flex space-y-1 flex-col border-r pr-4 overflow-hidden grow-0 shrink-0">
      <div className='text-right'>
        <span className='text-xl mr-1 text-slate-400'>#</span>
        <span className='text-xl'>{part.partNumber}</span>
      </div>
      <div className='text-right'>
        <span className='text-sm mr-1 text-slate-500'>v</span>
        <span className='text-sm'>{part.currentVersion.version}</span>
      </div>
      <div className='text-right flex justify-end'>
        <span className='text-sm mr-1 text-slate-500'><TagIcon className='w-4 h-4 inline'/></span>
        <span
          title={part.category.name}
          className='text-sm text-ellipsis overflow-hidden inline-block whitespace-nowrap '>{part.category.name}</span>
      </div>
      <div className='text-right'>
        <span className='text-sm mr-1 text-slate-500'>owner</span>
        <span className='text-sm'>{part.owner.name}</span>
      </div>
    </div>
    <div className='px-1 py-4 flex flex-col gap-1 flex-grow'>
      <div className='text-lg break-all'>
        {partName}
      </div>
      <div className='flex-grow text-base mb-3 text-ellipsis overflow-hidden'>
        {part.currentVersion.summary}
      </div>
    </div>
    <div className='flex mr-6 mt-6'>
      <StatusDot lifeCycle={part.currentVersion.lifeCycle} />
    </div>
    {showMoreMenu ? <EllipsisVerticalIcon className='float-right h-4 w-4 my-3 mx-4' /> : null}
  </div>
}

export default PartSummary
