import { InformationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames';


type ContainerProps = {
  children: React.ReactNode
  className?: string
} & React.HTMLProps<HTMLDivElement>;
export const Container: React.FC<ContainerProps> = ({ className = '', ...props }) => {
  return <div {...props} className={'has-tooltip relative ' + className} />
}

const messageClass = `
delay-75 tooltip pointer-events-none
text-xs px-3 py-2
text-center
absolute bottom-7
max-w-xl
rounded-lg bg-gray-700 text-white
`

type MessageProps = {
  children: React.ReactNode
  className?: string
  position?: 'left' | 'center' | 'right' | 'leftHorizontal'
} & React.HTMLProps<HTMLDivElement>;
export const Message: React.FC<MessageProps> = ({ children, className, position }) => {
  position = position || 'center'

  let cName: string
  if (position === 'leftHorizontal') {
    cName = classNames(messageClass, {
      '!-bottom-3 right-6': true
    }, className)
  }
  else {
    cName = classNames(messageClass, {
      'left-1/2 transform -translate-x-1/2': position === 'center',
      '-right-5': position === 'left',
      '-left-5': position === 'right'
    }, className)
  }
  return <div className={cName}>{children}</div>
}

type InfoProps = {
  message: string
  size: number
  className?: string
  position?: 'left' | 'center' | 'right'
}
export const Info: React.FC<InfoProps> = ({ size, message, className, position }) => {
  return <Container className={className}>
    <Message position={position}>
      {message}
    </Message>
    <InformationCircleIcon className={`h-${size} w-${size} mr-1 text-gray-600`} />
  </Container>
}
