
import kebabCase from 'lodash.kebabcase'
import padStart from 'lodash.padstart'
import Mustache from 'mustache'
import { Brand } from '../util';

Mustache.escape = function(text) {return text;};

export const renderTemplate = (template: string, data: Record<string, unknown>) => {
  const output = new Function(
    'kebabCase',
    'padStart',
    ...Object.keys(data),
    `return \`${template}\`;`
  )(
    kebabCase,
    padStart,
    ...Object.values(data)
  )

  // for compatibility
  return Mustache.render(output, data)
}

export const parseJsObjectString = (jsString: string) => {
  return new Function(
    `return ${jsString};`
  )()
}

export type UnchangedField = Brand<string, "UnchangedField">
export const UNCHANGED_FIELD: UnchangedField = 'UNCHANGED' as UnchangedField
