import { useLocation, Redirect } from '@redwoodjs/router'

import { useAuth } from '../../auth'
import LoadingSpinner from 'src/components/Loading'
import { SignIn, SignUp, useClerk } from '@clerk/clerk-react'
import Button from 'src/components/Button'

const HomePage = () => {
  const { isAuthenticated, loading, error, logOut } = useAuth()
  const { loaded } = useClerk()
  const { pathname, search } = useLocation()
  const redirect = (new URLSearchParams(search)).get("redirectTo")

  const body = () => {
    if (loading || !loaded) return <LoadingSpinner />
    if (error) return <div className='flex gap-6 flex-col w-80 bg-white p-6 rounded-md'>
      <div className='text-lg font-medium text-gray-700'>Authentication Error</div>
      <div className='text-sm'>There was an error validating your user account, contact <a className='text-blue-600' href='mailto:support@bomello.com'>support@bomello.com</a> if the issue persists.</div>
      <Button onClick={() => logOut()}>Try Again</Button>
    </div>
    if (isAuthenticated) return <Redirect to={redirect || '/'} />
    return pathname === '/signup' ? <SignUp redirectUrl={redirect}/> : <SignIn redirectUrl={redirect}/>
  }

  return (
    <div className='flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 bg-brand-500'>
      {body()}
    </div>
  )
}

export default HomePage
