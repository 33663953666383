import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { CreoLogo, Logo, SolidworksLogo, AltiumLogo } from 'src/components/CadLogo'

const mapperConfig: MapperConfig = {
  name: 'Mechanical',
  orgIds: ['pattern-sandbox', 'pattern-demo', 'pattern'],
  rootCategoryId: 'uncategorized',
  rootIsTopLevel: true,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: ['cost', 'notes']
  },
  standardizeBom: {
    columns: {
      metadata: {
        cost: {
          type: 'simple',
          value: '${row.unit_cost || ""}'
        },
        notes: {
          type: 'simple',
          value: '${row.notes || ""}'
        }
      },
      summary: {
        value: '${row.description || ""}',
        type: 'simple'
      },
      sources: [{
        url: {
          type: 'simple',
          value: '${row.link_url || ""}'
        },
      }],
      hierarchy: {
        value: '1.{{rowIndex}}',
        type: 'simple'
      },
      partNumber: {
        type: 'alwaysGenerate',
        categoryId: 'uncategorized',
      },
      cadRev: {
        type: 'empty'
      },
      categoryId: {
        type: 'simple',
        value: 'uncategorized',
      },
      isOffTheShelf: {
        value: 'true',
        type: 'simple'
      },
      name: {
        value: '{{row.nickname}}',
        type: 'simple'
      },
      quantity: [{
        onMatch: {
          type: 'simple',
          value: '1'
        },
        conditions: [{
          type: 'eq',
          column: 'qty',
          value: '0'
        }]
      }, {
        default: true,
        onMatch: {
          value: '${row.qty}',
          type: 'simple',
        }
      }],
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export default mapperConfig
